import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";
import "./dialog.css";
import { Theme, useTheme } from "@mui/material";
import ErrorDisplay from "../../error-display";
import SubmitButtonsDialog from '../submit-buttons/submit-button-dialog';

export interface DeleteProps {
    showDialog: boolean;
    dialogTitle: string;
    dialogInfo: string | undefined;
    onClickSubmit: () => void;
    onClickCancel: () => void;
}

const InputErrorDialog = ({
    showDialog,
    onClickSubmit,
    dialogTitle,
    dialogInfo,
    onClickCancel,
}: DeleteProps) => {
    const theme: Theme = useTheme();

    return (
        <Dialog open={showDialog}>
            <DialogTitle
                sx={{ backgroundColor: theme.palette.primary.main, color: "white" }}
            >
                {dialogTitle}
            </DialogTitle>
            <DialogContent sx={{ marginTop: "1rem" }} className="DialogContent">
                <ErrorDisplay>
                    <DialogContentText>
                        {dialogInfo &&
                            dialogInfo !== undefined &&
                            !dialogInfo.includes("undefined")
                            ? dialogInfo
                            : "Es besteht ein Fehler im Formular, welcher das Abspeichern der Entität verhindert. Soll die Seite trotzdem verlassen werden?"}
                    </DialogContentText>
                </ErrorDisplay>
            </DialogContent>
            <DialogActions>
                <SubmitButtonsDialog
                    submitText="Ja"
                    cancelText="Nein"
                    onClickSubmit={onClickSubmit}
                    onClickCancel={onClickCancel}
                />
            </DialogActions>
        </Dialog>
    );
};

export default InputErrorDialog;
