import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    TextField,
} from "@mui/material";
import { useEffect, useState } from "react";

import "./reset-password.css";
import { Key } from "@mui/icons-material";
import ErrorDisplay from "../../../../shared/components/error-display";
import { useNavigate, useParams } from "react-router-dom";
import { resetPasswordRequest, validateResetPasswordToken } from "../../../../shared/requests/user.requests";
import { PasswordAPI } from "../../../../models/user";

function ResetPassword() {

    const [password, setPassword] = useState<string>("");
    const [passwordConfirm, setPasswordConfirm] = useState<string>("");
    const [savePushed, setSavePushed] = useState<boolean>(false);
    const [tokenValid, setTokenValid] = useState<boolean>(false);
    const { id } = useParams();
    const errorInput: boolean =
        password === "" || passwordConfirm === "" || password !== passwordConfirm || password.length < 8 || passwordConfirm.length < 8;

    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            validateResetPasswordToken(id).then(() => {
                setTokenValid(true);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigateToLogin = () => {
        navigate(`/login`);
    }

    const resetPassword = () => {
        setSavePushed(true);
        if (!errorInput && id) {
            const pass: PasswordAPI = {
                password: password
            }
            resetPasswordRequest(id, pass).then(() => {
                alert("Passwort erfolgreich zurückgesetzt!");
                navigateToLogin();
            });
        }
    };

    return (
        <>
            {(tokenValid) &&
                <Card className="login-bg">
                    <CardContent className="flex flex-column login-width content-padding">
                        <ErrorDisplay>
                            <div className="login-image-container">
                                <img className="login-image" src="/assets/img/AidPlus_Logo_transparent.png" alt="AidPlus Icon" />
                            </div>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                    marginBottom: "0.5rem",
                                }}
                            >
                                <Key sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                                <TextField
                                    sx={{ width: "100%" }}
                                    type="password"
                                    label="Passwort"
                                    variant="standard"
                                    onChange={(event) => setPassword(event.target.value)}
                                    error={savePushed && (password === "" || passwordConfirm !== password || password.length < 8)}
                                    helperText={
                                        savePushed && password === "" ? "Das Feld Passwort darf nicht leer sein!" :
                                            savePushed && passwordConfirm !== password ? "Die Passwörter müssen übereinstimmen!" :
                                                savePushed && password.length < 8 ? "Das Passwort muss mindestens 8 Zeiche lang sein" : ""
                                    }
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                    marginBottom: "0.5rem",
                                }}
                            >
                                <Key sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                                <TextField
                                    sx={{ width: "100%" }}
                                    type="password"
                                    label="Passwort bestätigen"
                                    variant="standard"
                                    onChange={(event) => setPasswordConfirm(event.target.value)}
                                    error={savePushed && (passwordConfirm === "" || passwordConfirm !== password || passwordConfirm.length < 8)}
                                    helperText={
                                        savePushed && passwordConfirm === "" ? "Das Feld Passwort bestästigen darf nicht leer sein!" :
                                            savePushed && passwordConfirm !== password ? "Die Passwörter müssen übereinstimmen!" :
                                                savePushed && passwordConfirm.length < 8 ? "Das Passwort muss mindestens 8 Zeiche lang sein" : ""
                                    }
                                />
                            </Box>
                            <a className="forgot-password" href="/login">Einloggen?</a>
                        </ErrorDisplay>
                    </CardContent>
                    <CardActions className="flex flex-end">
                        <Button variant="contained" onClick={resetPassword}>
                            Passwort zurücksetzen
                        </Button>
                    </CardActions>
                </Card>
            }
            {(!tokenValid) && <div>Der Link ist nicht mehr gültig!</div>}
        </>
    );
}

export default ResetPassword;
