import React from 'react'
import {
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Theme,
    useTheme,
} from "@mui/material";
import "./crud-table.css";
import { SortingDirection } from "../../../enums/sort-by";
import { Fragment, useEffect, useRef, useState } from "react";
import { AssistWalker, CheckCircleOutline, Edit, Female, FindInPage, Insights, Male, NotInterested, Person, Restore, Save, Transgender } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import {
    determineNewSort,
    getSortingIcon,
    sortAsc,
    sortDesc,
} from "../../sorting";
import { Sort } from "../../../hooks/useSort.hook"; import { PersonInNeedOfCareAPI } from '../../../models/person-in-need-of-care';
import { CaringPersonAPI } from '../../../models/caring-person';
import { InstitutionAPI } from '../../../models/institution';
import { PersonConnectionAPI } from '../../../models/person-connection';

/*
  This type defines the table colums. Where T is
  the data type of the table data and K is an attribute of T.
  The key 'header' can be used to specify any string which
  represents the column name for the column of the corresponding key.
*/
export type ColumnDefinitionType<T, K extends keyof T> = {
    key: K;
    header: string;
};

const paginationOptions: number[] = [15, 25, 50];

type TableProps<T, K extends keyof T> = {
    /*
      An array of data of type T, which is represented in the table.
    */
    tableData: Array<T>;
    /*
     * Array of column definitons.
     */
    columns: Array<ColumnDefinitionType<T, K>>;
    /*
      Sort type that is used for sorting the data.
    */
    sortBy: Sort<any>;
    /*
      Function to update table data.
    */
    fetchElements?: () => Promise<any>;
    /*
      Function to update table data with a given id.
    */
    fetchElementsWithId?: (value: string) => Promise<any>;
    /*
     The id to update the data with.
   */
    searchingId?: string;

    /*
     (Optional) Funtion to format data that is fetched by the fetchElements() function.
    */
    dataFormatter?: (...params: any[]) => T;

    /*
      (Optional) Function to initially filter fetched values.
    */
    filterData?: (...params: any[]) => boolean;
    /*
    (Optional) boolean to tell, if the searchBar should be shown. (When value is set to true search bar is hidden)
    */
    hideSearchbar?: boolean;

    /*
    (Optional) function, that is called at on Add Icon click (When no value the Add Icon is not shown)
    */
    callOnAddClick?: (...params: any[]) => void;
    /*
    (Optional) function, that is called at on FindInPage Icon click (When no value the FindInPage Icon is not shown)
    */
    callOnFindInPageClick?: (...params: any[]) => void;
    /*
    (Optional) function, that is called at on Edit Icon click (When no value the Edit Icon is not shown)
    */
    callOnEditClick?: (...params: any[]) => void;
    /*
    (Optional) function, that is called at on Save Icon click (When no value the Save Icon is not shown)
    */
    callOnSaveClick?: (...params: any[]) => void;
    /*
       (Optional) function, that is called at on Delete Icon click (When no value the Delete Icon is not shown)
       */
    callOnDeleteClick?: (...params: any[]) => void;
    /*
   (Optional) function, that is called at on Inactive/Active Icon click (When no value the Inactive/Active Icon is not shown)
   */
    callOnInactiveClick?: (...params: any[]) => void;
    /*
    (Optional) function, that is called at on Restore Icon click (When no value the Restore Icon is not shown)
    */
    callOnRestoreClick?: (...params: any[]) => void;
    /*
    (Optional) function, that is called at on CaringPerson Icon click (When no value the Restore Icon is not shown)
   */
    callOnCaringPersonClick?: (...params: any[]) => void;
    /*
    (Optional) function, that is called at on PersonInNeedOfCare Icon click (When no value the Restore Icon is not shown)
    */
    callOnPersonInNeedOfCareClick?: (...params: any[]) => void;
    /*
    (Optional) function, that is called at on CalculationBasis Icon click (When no value the Restore Icon is not shown)
    */
    callOnPersonConnectionClick?: (...params: any[]) => void;
    /*
      (Optional) A  state variable used to react to changes in table data from
      external components. External components must change this variable when
      changing the table data so that the data in the table is updated.
    */
    externalModified?: boolean;

    showGender?: boolean;

    /*
      (Optional) Setter for externalModified state variable.
    */
    setExternalModified?: React.Dispatch<React.SetStateAction<boolean>>;
};

/*
  The generic table currently only supports data types
  that have at least one attribute 'name'.
*/
const CrudTable = <T, K extends keyof T>({
    tableData,
    columns,
    sortBy,
    fetchElements,
    fetchElementsWithId,
    searchingId,
    dataFormatter,
    filterData,
    callOnAddClick,
    callOnFindInPageClick,
    callOnEditClick,
    callOnSaveClick,
    callOnDeleteClick,
    callOnInactiveClick,
    callOnRestoreClick,
    callOnCaringPersonClick,
    callOnPersonConnectionClick,
    callOnPersonInNeedOfCareClick,
    hideSearchbar,
    externalModified,
    setExternalModified,
    showGender
}: TableProps<T, K>): JSX.Element => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(paginationOptions[0]);
    const theme: Theme = useTheme();
    const [elements, setElements] = useState<T[]>(tableData);
    const [sortedElements, setSortedElements] = useState<T[]>([]);
    const [searchContent, setSearchContent] = useState<string>("");
    const [sortByState, setSortBy] = useState<Sort<any>>(sortBy);


    const containsFirstnameAndLastname = (firstname: string | undefined, lastname: string | undefined): boolean => {
        return firstname !== undefined && lastname !== undefined;
    };

    const containsPersonName = (personName: string | undefined): boolean => {
        return personName !== undefined;
    };

    const containsName = (name: string | undefined): boolean => {
        return name !== undefined;
    };

    const containsEffectiveDate = (effectiveDate: string | undefined): boolean => {
        return effectiveDate !== undefined;
    };

    const containsConceptOfOrder1 = (conceptOfOrder1: string | undefined): boolean => {
        return conceptOfOrder1 !== undefined;
    };

    const containsConceptOfOrder = (conceptOfOrder: string | undefined): boolean => {
        return conceptOfOrder !== undefined;
    };

    const containsAddressString = (addressString: string | undefined): boolean => {
        return addressString !== undefined;
    };

    const containsValidityString = (validityString: string | undefined): boolean => {
        return validityString !== undefined;
    };

    const containsInstitutionString = (institutionString: string | undefined): boolean => {
        return institutionString !== undefined;
    };

    const containsEmail = (email: string | undefined): boolean => {
        return email !== undefined;
    };

    const containsRoleString = (roleString: string | undefined): boolean => {
        return roleString !== undefined;
    };

    const containsActiveString = (activeString: string | undefined): boolean => {
        return activeString !== undefined;
    };
    const containsTypeString = (typeString: string | undefined): boolean => {
        return typeString !== undefined;
    };

    const containsSuccessorID = (successorID: string | undefined): boolean => {
        return successorID !== undefined;
    };
    const containsObjectType = (objectType: string | undefined): boolean => {
        return objectType !== undefined;
    };
    const containsCreateString = (createString: string | undefined): boolean => {
        return createString !== undefined;
    };
    const containsAmount = (amount: string | undefined): boolean => {
        return amount !== undefined;
    };
    const containsCaringPersonName = (caringPersonName: string | undefined): boolean => {
        return caringPersonName !== undefined;
    };
    const containsPersonInNeedOfCareName = (personInNeedOfCareName: string | undefined): boolean => {
        return personInNeedOfCareName !== undefined;
    };



    function filterElements(elements: any[], searchContent: string): T[] {
        const searchLowerCase = searchContent.toLowerCase();
        return elements.filter(
            ({
                name,
                personName,
                firstname,
                lastname,
                effectiveDate,
                conceptOfOrder1,
                conceptOfOrder,
                addressString,
                validityString,
                institutionString,
                email,
                roleString,
                activeString,
                typeString,
                successorID,
                objectType,
                createString,
                amount,
                caringPersonName,
                personInNeedOfCareName,
            }) => {
                var contains = false;
                if (containsPersonName(personName)) {
                    contains = contains || personName.toLowerCase().includes(searchLowerCase);
                }
                if (containsName(name)) {
                    contains = contains || name.toLowerCase().includes(searchLowerCase);
                }
                if (containsFirstnameAndLastname(firstname, lastname)) {
                    contains = contains || firstname.toLowerCase().includes(searchLowerCase) ||
                        lastname.toLowerCase().includes(searchLowerCase)
                }
                if (containsEffectiveDate(effectiveDate)) {
                    contains = contains || effectiveDate.toLowerCase().includes(searchLowerCase)
                }
                if (containsConceptOfOrder1(conceptOfOrder1)) {
                    contains = contains || conceptOfOrder1.toLowerCase().includes(searchLowerCase)
                }
                if (containsConceptOfOrder(conceptOfOrder)) {
                    contains = contains || conceptOfOrder.toLowerCase().includes(searchLowerCase)
                }
                if (containsAddressString(addressString)) {
                    contains = contains || addressString.toLowerCase().includes(searchLowerCase)
                }
                if (containsValidityString(validityString)) {
                    contains = contains || validityString.toLowerCase().includes(searchLowerCase)
                }
                if (containsInstitutionString(institutionString)) {
                    contains = contains || institutionString.toLowerCase().includes(searchLowerCase)
                }
                if (containsEmail(email)) {
                    contains = contains || email.toLowerCase().includes(searchLowerCase)
                }
                if (containsRoleString(roleString)) {
                    contains = contains || roleString.toLowerCase().includes(searchLowerCase)
                }
                if (containsActiveString(activeString)) {
                    contains = contains || activeString.toLowerCase().includes(searchLowerCase)
                }
                if (containsTypeString(typeString)) {
                    contains = contains || typeString.toLowerCase().includes(searchLowerCase)
                }
                if (containsSuccessorID(successorID)) {
                    contains = contains || successorID.toLowerCase().includes(searchLowerCase)
                }
                if (containsObjectType(objectType)) {
                    contains = contains || objectType.toLowerCase().includes(searchLowerCase)
                }
                if (containsCreateString(createString)) {
                    contains = contains || createString.toLowerCase().includes(searchLowerCase)
                }
                if (containsAmount(String(amount))) {
                    contains = contains || String(amount).toLowerCase().includes(searchLowerCase)
                }
                if (containsCaringPersonName(caringPersonName)) {
                    contains = contains || caringPersonName.toLowerCase().includes(searchLowerCase)
                }
                if (containsPersonInNeedOfCareName(personInNeedOfCareName)) {
                    contains = contains || personInNeedOfCareName.toLowerCase().includes(searchLowerCase)
                }
                return contains;
            }
        );
    }

    function sortElements(elements: any[], sortBy: Sort<Pick<any, any>>): T[] {
        const sortedElements = [...elements];
        if (!sortBy.key || sortBy.direction === SortingDirection.NoDirection) {
            return sortedElements.sort((a, b) =>
                sortDesc(getValue(a, "name"), getValue(b, "name"))
            );
        }

        const sortByColumn = sortBy.key;
        switch (sortBy.direction) {
            case SortingDirection.Ascending:
                sortedElements.sort((a, b) =>
                    sortAsc(a[sortByColumn], b[sortByColumn])
                );
                break;
            case SortingDirection.Descending:
                sortedElements.sort((a, b) =>
                    sortDesc(a[sortByColumn], b[sortByColumn])
                );
                break;
        }

        return sortedElements;
    }

    const getGenderValue = (index: number, key: string) => {
        const item = sortedElements[index];
        const pinoc = item as PersonInNeedOfCareAPI;
        if (pinoc != null && pinoc.person != null && key === "personName") {
            return getGenderIcon(pinoc.person.gender)
        }
        const cp = item as CaringPersonAPI;
        if (cp != null && cp.person != null && key === "personName") {
            return getGenderIcon(cp.person.gender)
        }
        const cb = item as PersonConnectionAPI;
        if (cb != null && cb.personInNeedOfCare != null && cb.personInNeedOfCare.person != null && key === "personInNeedOfCareName") {
            return getGenderIcon(cb.personInNeedOfCare.person.gender)
        } else if (cb != null && cb.caringPerson != null && cb.caringPerson.person != null && key === "caringPersonName") {
            return getGenderIcon(cb.caringPerson.person.gender)
        }
        return <div></div>
    }

    const getGenderIcon = (id: string) => {
        if (id === "GENDER_MALE") {
            return <Male></Male>
        } else if (id === "GENDER_FEMALE") {
            return <Female></Female>
        } else {
            return <Transgender></Transgender>
        }
    }


    const getActiveForInstitution = (index: number) => {
        const item = sortedElements[index];
        const institution: InstitutionAPI = item as InstitutionAPI;
        if (institution && institution.active !== undefined) {
            return institution.active;
        }
        return null;
    }

    function updateElements(
        elements: any[],
        sortBy: Sort<Pick<any, any>>,
        filterBy: string
    ): T[] {
        const filteredElements = [...filterElements(elements, filterBy)];
        return sortElements(filteredElements, sortBy);
    }

    const isInitRender = useRef(true);

    useEffect(() => {
        if (isInitRender.current) {
            if (fetchElements) {
                fetchElements().then((response) => {
                    let filteredResponse: T[] = response.data;
                    if (filterData) {
                        filteredResponse = filteredResponse.filter(filterData);
                    }
                    if (dataFormatter) {
                        setElements(filteredResponse.map(dataFormatter));
                    } else {
                        setElements(filteredResponse);
                    }
                });
            } else if (fetchElementsWithId && searchingId) {
                fetchElementsWithId(searchingId).then((response) => {
                    let filteredResponse: T[] = response.data;
                    if (filterData) {
                        filteredResponse = filteredResponse.filter(filterData);
                    }
                    if (dataFormatter) {
                        setElements(filteredResponse.map(dataFormatter));
                    } else {
                        setElements(filteredResponse);
                    }
                });
            }
            isInitRender.current = false;
        } else if (externalModified && setExternalModified) {
            if (fetchElements) {
                fetchElements().then((response) => {
                    let filteredResponse: T[] = response.data;
                    if (filterData) {
                        filteredResponse = filteredResponse.filter(filterData);
                    }
                    if (dataFormatter) {
                        setElements(filteredResponse.map(dataFormatter));
                    } else {
                        setElements(filteredResponse);
                    }
                    setSortedElements(updateElements(elements, sortByState, searchContent));
                    setExternalModified(false);
                });
            } else if (fetchElementsWithId && searchingId) {
                fetchElementsWithId(searchingId).then((response) => {
                    let filteredResponse: T[] = response.data;
                    if (filterData) {
                        filteredResponse = filteredResponse.filter(filterData);
                    }
                    if (dataFormatter) {
                        setElements(filteredResponse.map(dataFormatter));
                    } else {
                        setElements(filteredResponse);
                    }
                    setSortedElements(updateElements(elements, sortByState, searchContent));
                    setExternalModified(false);
                });
            }
        } else {
            setSortedElements(updateElements(elements, sortByState, searchContent));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elements, sortByState, searchContent, externalModified]);

    const handleChangePage = (
        _: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // ************** Header **************
    type TableHeaderProps<T, K extends keyof T> = {
        columns: Array<ColumnDefinitionType<T, K>>;
    };

    const TableHeader = <T, K extends keyof T>({
        columns,
    }: TableHeaderProps<T, K>): JSX.Element => {
        const getSearchBarAndAddIconElement = (): JSX.Element => {
            return (
                <Fragment key={`headCell-Fragment`}>
                    <TableCell>

                        {!hideSearchbar && <div className="searchbar">
                            <SearchIcon />
                            <TextField
                                variant="standard"
                                placeholder="Suche…"
                                value={searchContent}
                                focused
                                autoFocus
                                sx={{ width: "80%" }}
                                onChange={(e) => {
                                    setSearchContent(e.target.value);
                                    setPage(0);
                                }}
                            />
                        </div>
                        }
                    </TableCell>
                    <TableCell className="TableCellAddIcon">
                        {callOnAddClick ? (<IconButton
                            edge="end"
                            aria-label="show"
                            onClick={() => callOnAddClick()}
                            sx={{
                                color: theme.palette.primary.main,
                            }}
                        >
                            <AddIcon />
                        </IconButton>
                        ) : (<React.Fragment></React.Fragment>)}
                    </TableCell>
                </Fragment>
            );
        };

        const headers = columns.map((column, index) => {
            return (
                <TableCell key={`headCell-${index}`} align="left">
                    <Button
                        onClick={() => {
                            setSortBy(
                                determineNewSort<Pick<any, any>>(sortByState, column.key)
                            );
                        }}
                    >
                        {getSortingIcon(sortByState, column.key)}
                        {column.header}
                    </Button>
                </TableCell>
            );
        });
        headers.push(getSearchBarAndAddIconElement());

        return (
            <TableHead>
                <TableRow>{headers}</TableRow>
            </TableHead>
        );
    };

    // ************** Rows *************
    type TableRowsProps<T, K extends keyof T> = {
        data: Array<T>;
        columns: Array<ColumnDefinitionType<T, K>>;
    };

    function getValue(item: any, key: string): any {
        let actualResult: string | unknown = "";
        Object.entries(item).forEach(([tmpKey, value]) => {
            if (tmpKey === key) {
                actualResult = value;
            }
        });
        return actualResult;
    }

    function getTableCellContent(data: any): React.ReactNode {
        const itemAsReactFragment = data as React.ReactNode;
        if (itemAsReactFragment !== null) {
            return itemAsReactFragment;
        }
        return String(data);

    }

    const TableRows = <T, K extends keyof T>({
        data,
        columns,
    }: TableRowsProps<T, K>): JSX.Element => {
        const rows = data
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
                return (
                    <TableRow key={`row-${index}`}>
                        {columns.map((column, index2) => {
                            return (
                                <TableCell
                                    key={`cell-${index2}`}
                                    align="left"
                                    scope="row"
                                    component="th"
                                >
                                    {(String(column.key) === "caringPersonName" && showGender) && getGenderValue(index, String(column.key))}
                                    {(String(column.key) === "personInNeedOfCareName" && showGender) && getGenderValue(index, String(column.key))}
                                    {(String(column.key) === "personName" && showGender) && getGenderValue(index, String(column.key))}
                                    {getTableCellContent(row[column.key])}
                                </TableCell>
                            );
                        })}
                        <Fragment>
                            <TableCell />
                            <TableCell>
                                {callOnFindInPageClick ? (<IconButton
                                    edge="end"
                                    aria-label="show"
                                    onClick={() => callOnFindInPageClick(row)}
                                    sx={{
                                        color: theme.palette.primary.main,
                                    }}
                                >
                                    <FindInPage />
                                </IconButton>
                                ) : (<React.Fragment></React.Fragment>)}
                                {callOnEditClick ? (<IconButton
                                    edge="end"
                                    aria-label="show"
                                    onClick={() => callOnEditClick(row)}
                                    sx={{
                                        color: theme.palette.primary.main,
                                    }}
                                >
                                    <Edit />
                                </IconButton>
                                ) : (<React.Fragment></React.Fragment>)}
                                {callOnSaveClick ? (<IconButton
                                    edge="end"
                                    aria-label="show"
                                    onClick={() => callOnSaveClick(row)}
                                    sx={{
                                        color: theme.palette.primary.main,
                                    }}
                                >
                                    <Save />
                                </IconButton>
                                ) : (<React.Fragment></React.Fragment>)}
                                {callOnDeleteClick ? (<IconButton
                                    edge="end"
                                    aria-label="show"
                                    onClick={() => callOnDeleteClick(row)}
                                    sx={{
                                        color: theme.palette.primary.main,
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                                ) : (<React.Fragment></React.Fragment>)}
                                {callOnInactiveClick ? (<IconButton
                                    edge="end"
                                    aria-label="show"
                                    onClick={() => callOnInactiveClick(row)}
                                    sx={{
                                        color: theme.palette.primary.main,
                                    }}
                                >
                                    {(getActiveForInstitution(index) === null || getActiveForInstitution(index)) && <NotInterested />}
                                    {(getActiveForInstitution(index) !== null && !getActiveForInstitution(index)) && <CheckCircleOutline />}
                                </IconButton>
                                ) : (<React.Fragment></React.Fragment>)}
                                {callOnRestoreClick ? (<IconButton
                                    edge="end"
                                    aria-label="show"
                                    onClick={() => callOnRestoreClick(row)}
                                    sx={{
                                        color: theme.palette.primary.main,
                                    }}
                                >
                                    <Restore />
                                </IconButton>
                                ) : (<React.Fragment></React.Fragment>)}
                                {callOnPersonConnectionClick ? (<IconButton
                                    edge="end"
                                    aria-label="show"
                                    sx={{
                                        color: theme.palette.primary.main,
                                    }}
                                    onClick={() => callOnPersonConnectionClick(row)}
                                >
                                    <Insights />
                                </IconButton>
                                ) : (<React.Fragment></React.Fragment>)}
                                {callOnCaringPersonClick ? (
                                    <IconButton
                                        edge="end"
                                        aria-label="show"
                                        sx={{
                                            color: theme.palette.primary.main,
                                        }}
                                        onClick={() => callOnCaringPersonClick(row)}
                                    >
                                        <Person />
                                    </IconButton>
                                ) : (<React.Fragment></React.Fragment>)}
                                {callOnPersonInNeedOfCareClick ? (
                                    <IconButton
                                        edge="end"
                                        aria-label="show"
                                        sx={{
                                            color: theme.palette.primary.main,
                                        }}
                                        onClick={() => callOnPersonInNeedOfCareClick(row)}
                                    >
                                        <AssistWalker />
                                    </IconButton>
                                ) : (<React.Fragment></React.Fragment>)}
                            </TableCell>
                        </Fragment>
                    </TableRow>
                );
            });

        return (
            <TableBody className="table__table-body TableBody">{rows}</TableBody>
        );
    };

    // ********************************

    return (
        <TableContainer className="table__table-container">
            <Table size="small" className="table-overview">
                <TableHeader columns={columns} />
                <TableRows data={sortedElements} columns={columns} />
            </Table>
            <TablePagination
                component="div"
                rowsPerPageOptions={paginationOptions}
                count={sortedElements.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
    );
};

export default CrudTable;
