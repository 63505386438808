import { useEffect } from 'react';
import { Divider } from "@mui/material";
import PageLayout from "../../../../shared/components/page-layout";
import "../../details.css";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    ContractTypes,
    getAllContractTypes,
    PersonInNeedOfCareHistoryAPI,
} from "../../../../models/person-in-need-of-care";
import {
    fetchAllPersonConnectionForPersonInNeedOfCare,
    fetchHistoryForPersonInNeedOfCare,
    fetchPersonInNeedOfCare,
    fetchSingleHistoryForPersonInNeedOfCare,
} from "../../../../shared/requests/person-in-need-of-care.requests";
import { getValidityValues } from '../../../../models/historization';
import { InstitutionSelect } from '../../../../models/institution';
import AddressForm from '../../../../shared/components/address-form/address-form';
import { GenderType, getGenderType } from '../../../../models/person';
import { fetchAids, fetchInsurances } from '../../../../shared/requests/institution.requests';
import PersonForm from '../../../../shared/components/person-form/person_form';
import { Feed, Person, Summarize } from '@mui/icons-material';
import CrudTable, { ColumnDefinitionType } from '../../../../shared/components/crud-table/crud-table';
import { Sort } from '../../../../hooks/useSort.hook';
import { SortingDirection } from '../../../../enums/sort-by';
import { TimeLineItemModelHistory } from '../../../../models/timeline';
import Timeline from '../../../../shared/components/time-line';
import PersonInNeedOfCareForm from '../form/person-in-need-of-care-form';
import AccordionComponent from '../../../../shared/components/accordion-component';
import { CountryData, getCountries } from '../../../../shared/components/select-component-country';
import { fetchAidConfig } from '../../../../shared/requests/aid-request';
import { AidConfigAidLevels } from '../../../../models/aid';
import { PersonConnectionAPI } from '../../../../models/person-connection';
import { getFromUntilStringFromCalculationBasisList } from '../../../../helper/formatter';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { addToHistory, HistoryTimelineItemModel } from '../../../../models/history';
import HistoryLine from '../../../../shared/components/history_line';

function PersonInNeedOfCareShow() {
    //Select Listen
    const [historyData, setHistoryData] = useState<PersonInNeedOfCareHistoryAPI[]>([]);
    const [personConnection, setPersonConnection] = useState<PersonConnectionAPI[]>([]);
    const [aidValues, setAidValues] = useState<InstitutionSelect[]>([]);
    const [insuranceValues, setInsuranceValues] = useState<InstitutionSelect[]>([]);
    const [aidLevelValues, setAidLevelValues] = useState<AidConfigAidLevels[]>([]);
    //Bedürftige extra Daten
    const [effectiveDate, setEffectiveDate] = useState<string>("");
    const [conceptOfOrder1, setConceptOfOrder1] = useState<string>("");
    const [conceptOfOrder2, setConceptOfOrder2] = useState<string>("");
    const [externalConceptOfOrder1, setExternalConceptOfOrder1] = useState<string>("");
    const [externalConceptOfOrder2, setExternalConceptOfOrder2] = useState<string>("");
    const [aidLevel, setAidLevel] = useState<AidConfigAidLevels | null>(null);
    const [contractType, setContractType] = useState<ContractTypes | null>(null);
    const [insurance, setInsurance] = useState<InstitutionSelect | null>(null);
    const [aid, setAid] = useState<InstitutionSelect | null>(null);
    //Personendaten
    const [lastname, setLastname] = useState<string>("");
    const [birthName, setBirthName] = useState<string | null>("");
    const [firstname, setFirstname] = useState<string>("");
    const [placeOfBirth, setPlaceOfBirth] = useState<string>("");
    const [countryOfBirth, setCountryOfBirth] = useState<CountryData | null>(null);
    const [dateOfBirth, setDateOfBirth] = useState<string | null>("");
    const [gender, setGender] = useState<GenderType | null>(null);
    const [namePrefix, setNamePrefix] = useState<string | null>("");
    const [title, setTitle] = useState<string | null>("");
    const [nationality, setNationality] = useState<CountryData | null>(null);
    const [pensionInsuranceNumber, setPensionInsuranceNumber] = useState<string | null>("");
    //Addressdaten
    const [street, setStreet] = useState<string>("");
    const [houseNumber, setHouseNumber] = useState<number | null>(null);
    const [country, setCountry] = useState<CountryData | null>(null);
    const [postalCode, setPostalCode] = useState<string>("");
    const [location, setLocation] = useState<string>("");
    const [poBox, setPOBox] = useState<string>("");
    const [poBoxPostalCode, setPOBoxPostalCode] = useState<string>("");
    const [addressSupplement, setAddressSupplement] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [fax, setFax] = useState<string>("");
    const [email, setEmail] = useState<string>("");

    //Systemdaten
    const [chronoItems, setChronoItems] = useState<TimeLineItemModelHistory[]>([]);
    const [historyChronoItems, setHistoryChronoItems] = useState<HistoryTimelineItemModel[]>([]);
    const [defaultChronoIndex, setDefaultChronoIndex] = useState<number>(0);
    const [selectedItem, setSelectedItem] = useState<TimeLineItemModelHistory | null>(null);
    const [isCurrentRendered, setCurrentRendered] = useState<boolean>(false);
    const { user } = useSelector(({ authReducer }: RootState) => authReducer);
    const { id, history } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        var today = new Date();
        if (user != null && (user.userRole === "USER_ROLE_AID_ADMIN" || user.userRole === "USER_ROLE_AID_USER")) {
            fetchAidConfiguration(user?.institution.id)
        }
        fetchAids().then((response) => {
            let institutions = response.data;
            let institutionsSelect: InstitutionSelect[] = [];
            institutions.forEach((institution) => {
                if (institution.id === user?.institution.id) {
                    setAid({
                        id: String(institution.id),
                        name: institution.name,
                        realValue: +institution.id,
                        type: institution.type
                    });
                }
                institutionsSelect.push({
                    id: String(institution.id),
                    name: institution.name,
                    realValue: +institution.id,
                    type: institution.type
                })
            })
            setAidValues(institutionsSelect);
        })
        fetchInsurances().then((response) => {
            let institutions = response.data;
            let institutionsSelect: InstitutionSelect[] = [];
            institutions.forEach((institution) => {
                if (institution.id === user?.institution.id) {
                    setInsurance({
                        id: String(institution.id),
                        name: institution.name,
                        realValue: +institution.id,
                        type: institution.type
                    });
                }
                institutionsSelect.push({
                    id: String(institution.id),
                    name: institution.name,
                    realValue: +institution.id,
                    type: institution.type
                })
            })
            setInsuranceValues(institutionsSelect);
        })
        if (id) {
            if (!history) {
                fetchSinglePersonAPI(id);
            } else {
                fetchSinglePersonHistoryAPI(id, history);
            }
        } else {
            const elementCountry = getCountries().find((element) => { return "" === element.value; });
            if (elementCountry) {
                setCountry(elementCountry)
                setCountryOfBirth(elementCountry)
            }
            setEffectiveDate(today.getFullYear() + '-' + ((today.getMonth() + 1 < 10) ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1)) + '-' + today.getDate())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, history]);

    const getUserTitle = (): string => {
        return "Bedürftige/n anzeigen";
    };

    const fetchSinglePersonAPI = (id: string) => {
        fetchPersonInNeedOfCare(id).then((response) => {
            setEffectiveDate(response.data.effectiveDate);
            setConceptOfOrder1(response.data.conceptOfOrder1);
            setConceptOfOrder2(response.data.conceptOfOrder2 ? response.data.conceptOfOrder2 : "");
            setExternalConceptOfOrder1(response.data.externalConceptOfOrder1 ? response.data.externalConceptOfOrder1 : "");
            setExternalConceptOfOrder2(response.data.externalConceptOfOrder2 ? response.data.externalConceptOfOrder2 : "")
            if (response.data.aidLevel) {
                setAidLevel({
                    id: String(response.data.aidLevel.id),
                    name: String(response.data.aidLevel.percentage) + "%"
                });
            }
            const elementContractType = getAllContractTypes().find((element) => { return response.data.contractType === element.id; });
            if (elementContractType) {
                setContractType(elementContractType)
            }
            setInsurance({
                id: response.data.insurance.id,
                name: response.data.insurance.name,
                realValue: +response.data.insurance.id,
                type: response.data.insurance.type
            })
            setAid({
                id: response.data.client.id,
                name: response.data.client.name,
                realValue: +response.data.client.id,
                type: response.data.client.type
            })
            // Addressdaten setzen
            if (response.data.address != null) {
                setStreet(response.data.address.street);
                setHouseNumber(response.data.address.houseNumber);
                const elementCountry = getCountries().find((element) => { return response.data.address?.country === element.value; });
                if (elementCountry) {
                    setCountry(elementCountry)
                }
                setPostalCode(response.data.address.postalCode);
                setLocation(response.data.address.location);
                setPOBox(response.data.address.poBox);
                setPOBoxPostalCode(response.data.address.poBoxPostalCode);
                setAddressSupplement(response.data.address.addressSupplement);
                setPhoneNumber(response.data.address.phoneNumber);
                setFax(response.data.address.fax);
                setEmail(response.data.address.email);
            } else {
                const elementCountry = getCountries().find((element) => { return "" === element.value; });
                if (elementCountry) {
                    setCountry(elementCountry)
                }
            }
            //Personendaten setzen
            setFirstname(response.data.person.firstname)
            setLastname(response.data.person.lastname)
            setBirthName(response.data.person.birthName)
            setPlaceOfBirth(response.data.person.placeOfBirth)
            const elementCountryBirth = getCountries().find((element) => { return response.data.person.countryOfBirth === element.value; });
            if (elementCountryBirth) {
                setCountryOfBirth(elementCountryBirth)
            } else {
                const elementCountry = getCountries().find((element) => { return "" === element.value; });
                if (elementCountry) {
                    setCountryOfBirth(elementCountry)
                }
            }
            setDateOfBirth(response.data.person.dateOfBirth)
            setNamePrefix(response.data.person.namePrefix)
            setTitle(response.data.person.title)
            const elementNationality = getCountries().find((element) => { return response.data.person.nationality === element.value; });
            if (elementNationality) {
                setNationality(elementNationality)
            } else {
                const elementCountry = getCountries().find((element) => { return "" === element.value; });
                if (elementCountry) {
                    setNationality(elementCountry)
                }
            }
            setPensionInsuranceNumber(response.data.person.pensionInsuranceNumber)
            const elementGender = getGenderType().find((element) => { return response.data.person.gender === element.id; });
            if (elementGender) {
                setGender(elementGender)
            }
            fetchHistoryData(response.data.id, response.data.effectiveDate)
            fetchAidConfiguration(response.data.client.id)
            fetchPersonConnectionForCaringPerson(response.data.id)
            if (!isCurrentRendered) {
                const historyData: HistoryTimelineItemModel[] = [];
                addToHistory({
                    type: "Bedürftige/r",
                    name: `${response.data.person.lastname}, ${response.data.person.firstname}`,
                    url: `/person-in-need-of-care/show/${id}`
                }).forEach((element => {
                    historyData.push({
                        type: element.type,
                        title: element.name,
                        url: element.url
                    })
                }))
                setHistoryChronoItems(historyData);
            }
        });
        setCurrentRendered(true);
    }


    const fetchSinglePersonHistoryAPI = (id: string, historyNumber: string) => {
        fetchSingleHistoryForPersonInNeedOfCare(id, historyNumber).then((response) => {
            setEffectiveDate(response.data.effectiveDate);
            setConceptOfOrder1(response.data.conceptOfOrder1);
            setConceptOfOrder2(response.data.conceptOfOrder2 ? response.data.conceptOfOrder2 : "");
            setExternalConceptOfOrder1(response.data.externalConceptOfOrder1 ? response.data.externalConceptOfOrder1 : "");
            setExternalConceptOfOrder2(response.data.externalConceptOfOrder2 ? response.data.externalConceptOfOrder2 : "")
            if (response.data.aidLevel) {
                setAidLevel({
                    id: String(response.data.aidLevel.id),
                    name: String(response.data.aidLevel.percentage) + "%"
                });
            }
            const elementContractType = getAllContractTypes().find((element) => { return response.data.contractType === element.id; });
            if (elementContractType) {
                setContractType(elementContractType)
            }
            setInsurance({
                id: response.data.insurance.id,
                name: response.data.insurance.name,
                realValue: +response.data.insurance.id,
                type: response.data.insurance.type
            })
            setAid({
                id: response.data.client.id,
                name: response.data.client.name,
                realValue: +response.data.client.id,
                type: response.data.client.type
            })
            // Addressdaten setzen
            if (response.data.address != null) {
                setStreet(response.data.address.street);
                setHouseNumber(response.data.address.houseNumber);
                const elementCountry = getCountries().find((element) => { return response.data.address?.country === element.value; });
                if (elementCountry) {
                    setCountry(elementCountry)
                }
                setPostalCode(response.data.address.postalCode);
                setLocation(response.data.address.location);
                setPOBox(response.data.address.poBox);
                setPOBoxPostalCode(response.data.address.poBoxPostalCode);
                setAddressSupplement(response.data.address.addressSupplement);
                setPhoneNumber(response.data.address.phoneNumber);
                setFax(response.data.address.fax);
                setEmail(response.data.address.email);
            } else {
                const elementCountry = getCountries().find((element) => { return "" === element.value; });
                if (elementCountry) {
                    setCountry(elementCountry)
                }
            }

            //Personendaten setzen
            setFirstname(response.data.person.firstname)
            setLastname(response.data.person.lastname)
            setBirthName(response.data.person.birthName)
            setPlaceOfBirth(response.data.person.placeOfBirth)
            const elementCountryBirth = getCountries().find((element) => { return response.data.person.countryOfBirth === element.value; });
            if (elementCountryBirth) {
                setCountryOfBirth(elementCountryBirth)
            } else {
                const elementCountry = getCountries().find((element) => { return "" === element.value; });
                if (elementCountry) {
                    setCountryOfBirth(elementCountry)
                }
            }
            setDateOfBirth(response.data.person.dateOfBirth)
            setNamePrefix(response.data.person.namePrefix)
            setTitle(response.data.person.title)
            const elementNationality = getCountries().find((element) => { return response.data.person.nationality === element.value; });
            if (elementNationality) {
                setNationality(elementNationality)
            } else {
                const elementCountry = getCountries().find((element) => { return "" === element.value; });
                if (elementCountry) {
                    setNationality(elementCountry)
                }
            }
            setPensionInsuranceNumber(response.data.person.pensionInsuranceNumber)
            const elementGender = getGenderType().find((element) => { return response.data.person.gender === element.id; });
            if (elementGender) {
                setGender(elementGender)
            }
            fetchHistoryData(response.data.id, response.data.effectiveDate)
            fetchAidConfiguration(response.data.client.id)
            fetchPersonConnectionForCaringPerson(response.data.id)
            if (!isCurrentRendered) {
                const historyData: HistoryTimelineItemModel[] = [];
                addToHistory({
                    type: "Bedürftige/r",
                    name: `${response.data.person.lastname}, ${response.data.person.firstname}`,
                    url: `/person-in-need-of-care/show/${id}`
                }).forEach((element => {
                    historyData.push({
                        type: element.type,
                        title: element.name,
                        url: element.url
                    })
                }))
                setHistoryChronoItems(historyData);
            }
        });
        setCurrentRendered(true);
    }

    const fetchHistoryData = (id: string, effDate: string) => {
        fetchHistoryForPersonInNeedOfCare(id).then((response2) => {
            const data: PersonInNeedOfCareHistoryAPI[] = []
            const chron: TimeLineItemModelHistory[] = [];
            let date: Date;
            let index: number = 0;
            let selItem: TimeLineItemModelHistory | null = selectedItem
            let selIndex: number = defaultChronoIndex;
            response2.data.forEach(element => {
                if (element.deleteTimestamp === null) {
                    element.deleteTimestamp = "";
                }
                if (element.deleteTransaction === null) {
                    date = new Date(Date.parse(element.effectiveDate))
                    const item = {
                        title: date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear(),
                        validity: element.validity,
                        identifier: element.id,
                        historyNumber: element.historyNumber,
                        status: element.status,
                    }
                    chron.push(item)
                    if (!history && !isCurrentRendered && element.effectiveDate === effDate && selItem === null) {
                        selIndex = index;
                        selItem = item;
                    }
                    if (!isCurrentRendered && element.status === "STATUS_FLOATING") {
                        selIndex = index;
                        selItem = item;
                    }
                    index++;
                }
                data.push(element);
            })
            setDefaultChronoIndex(selIndex)
            setSelectedItem(selItem)
            setHistoryData(data);
            setChronoItems(chron);
        });
    }

    const fetchAidConfiguration = (institutionId: string) => {
        fetchAidConfig(institutionId).then((response) => {
            const aidLevelVal: AidConfigAidLevels[] = []
            response.data.aidLevels.sort((a, b) => a > b ? 1 : -1).forEach((element) => {
                aidLevelVal.push({
                    id: element.id,
                    name: String(element.percentage) + "%"
                })
            })
            setAidLevelValues(aidLevelVal);
        })
    }

    const fetchPersonConnectionForCaringPerson = (personInNeedOfCareId: string) => {
        fetchAllPersonConnectionForPersonInNeedOfCare(personInNeedOfCareId).then((response2) => {
            setPersonConnection(response2.data)
        });
    }

    const onTimeLineItemSelected = (index: number) => {
        const valueCasted: TimeLineItemModelHistory = chronoItems[index]
        fetchSinglePersonHistoryAPI(valueCasted.identifier, valueCasted.historyNumber)

    }

    const onClickHistoryShow = (person: PersonInNeedOfCareHistoryAPI) => {
        navigate(`history/${person.historyNumber}`)
    }

    const onClickCaringPersonEdit = (personConnection: PersonConnectionAPI) => {
        navigate(`/caring-person/show/${personConnection.caringPerson.id}`)
    }

    const onClickCalculationBasisEdit = (personConnection: PersonConnectionAPI) => {
        navigate(`/calculation-basis/show/${personConnection.id}`)
    }

    const columnsHistory: ColumnDefinitionType<PersonInNeedOfCareHistoryAPI, keyof PersonInNeedOfCareHistoryAPI>[] = [
        {
            key: "historyNumber",
            header: "Historiennummer",
        },
        {
            key: "conceptOfOrder1",
            header: "Ord. Begriff1",
        },
        {
            key: "effectiveDate",
            header: "Wirksamkeitsdatum",
        },
        {
            key: "createTimestamp",
            header: "Zeitstempel Erstellung",
        },
        {
            key: "deleteTimestamp",
            header: "Zeitstempel Löschung",
        },
    ];

    const columnsPersonConnection: ColumnDefinitionType<PersonConnectionAPI, keyof PersonConnectionAPI>[] = [
        {
            key: "caringPersonName",
            header: "Name, Vorname, Geburtsdatum",
        },
        {
            key: "fromUntilString",
            header: "Beziehung Von - Bis",
        },
    ];

    const [sortByHistory] = useState<Sort<PersonInNeedOfCareHistoryAPI>>({
        key: "historyNumber",
        direction: SortingDirection.Descending,
    });

    const [sortByPersonConnection] = useState<Sort<PersonConnectionAPI>>({
        key: "personInNeedOfCareName",
        direction: SortingDirection.Descending,
    });

    return (
        <PageLayout title={getUserTitle()}>
            <div className="single-view">
                {id && <HistoryLine
                    items={historyChronoItems}
                    setCurrentRendered={setCurrentRendered}
                />}
                {id && <Timeline
                    items={chronoItems}
                    defaultChronoIndex={defaultChronoIndex}
                    onItemSelected={onTimeLineItemSelected}
                />}
                <PersonForm
                    savePushed={false}
                    disabled={true}
                    lastname={lastname}
                    setLastname={setLastname}
                    birthName={birthName}
                    setBirthName={setBirthName}
                    firstname={firstname}
                    setFirstname={setFirstname}
                    placeOfBirth={placeOfBirth}
                    setPlaceOfBirth={setPlaceOfBirth}
                    countryOfBirth={countryOfBirth}
                    setCountryOfBirth={setCountryOfBirth}
                    dateOfBirth={dateOfBirth}
                    setDateOfBirth={setDateOfBirth}
                    gender={gender}
                    setGender={setGender}
                    namePrefix={namePrefix}
                    setNamePrefix={setNamePrefix}
                    title={title}
                    setTitle={setTitle}
                    nationality={nationality}
                    setNationality={setNationality}
                    pensionInsuranceNumber={pensionInsuranceNumber}
                    setPensionInsuranceNumber={setPensionInsuranceNumber}
                    formError={false}
                />

                <AddressForm
                    savePushed={false}
                    disabled={true}
                    street={street}
                    setStreet={setStreet}
                    houseNumber={houseNumber}
                    setHouseNumber={setHouseNumber}
                    postalCode={postalCode}
                    setPostalCode={setPostalCode}
                    location={location}
                    setLocation={setLocation}
                    country={country}
                    setCountry={setCountry}
                    poBox={poBox}
                    poBoxPostalCode={poBoxPostalCode}
                    setPOBox={setPOBox}
                    setPOBoxPostalCode={setPOBoxPostalCode}
                    addressSupplement={addressSupplement}
                    setAddressSupplement={setAddressSupplement}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    fax={fax}
                    setFax={setFax}
                    email={email}
                    setEmail={setEmail}
                    useAddress={true}
                    formError={false}
                />

                <PersonInNeedOfCareForm
                    aid={aid}
                    aidValues={aidValues}
                    aidLevelValues={aidLevelValues}
                    conceptOfOrder1={conceptOfOrder1}
                    conceptOfOrder2={conceptOfOrder2}
                    contractType={contractType}
                    disabled={true}
                    effectiveDate={effectiveDate}
                    externalConceptOfOrder1={externalConceptOfOrder1}
                    externalConceptOfOrder2={externalConceptOfOrder2}
                    insurance={insurance}
                    insuranceValues={insuranceValues}
                    savePushed={false}
                    setAid={setAid}
                    setConceptOfOrder1={setConceptOfOrder1}
                    setConceptOfOrder2={setConceptOfOrder2}
                    setContractType={setContractType}
                    setEffectiveDate={setEffectiveDate}
                    setExternalConceptOfOrder1={setExternalConceptOfOrder1}
                    setExternalConceptOfOrder2={setExternalConceptOfOrder2}
                    setInsurance={setInsurance}
                    setAidLevel={setAidLevel}
                    aidLevel={aidLevel}
                    user={user}
                    formError={false}
                />
                {id && <Divider variant='fullWidth' sx={{ mt: 3, mb: 3 }} ></Divider>}
                {id &&
                    <div className='single-view-accordion'>
                        <AccordionComponent
                            label="Pflegeperson Beziehungen"
                            defaultExpanded={false}
                            startIcon={<Person></Person>}
                            content={
                                <CrudTable
                                    tableData={personConnection}
                                    columns={columnsPersonConnection}
                                    sortBy={sortByPersonConnection}
                                    fetchElementsWithId={fetchAllPersonConnectionForPersonInNeedOfCare}
                                    dataFormatter={(element: PersonConnectionAPI) => {
                                        var date;
                                        if (element.caringPerson.person.dateOfBirth != null) {
                                            date = new Date(element.caringPerson.person.dateOfBirth);
                                            element.caringPerson.person.dateOfBirth = (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
                                                (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
                                                date.getFullYear();
                                        }
                                        if (element.personInNeedOfCare.person.dateOfBirth != null) {
                                            date = new Date(element.personInNeedOfCare.person.dateOfBirth);
                                            element.personInNeedOfCare.person.dateOfBirth = (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
                                                (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
                                                date.getFullYear();
                                        }
                                        element.caringPersonName = element.caringPerson.person.lastname + ", " + element.caringPerson.person.firstname + (element.caringPerson.person.dateOfBirth !== null ? " *" + element.caringPerson.person.dateOfBirth : "")
                                        element.personInNeedOfCareName = element.personInNeedOfCare.person.firstname + " " + element.personInNeedOfCare.person.lastname + (element.personInNeedOfCare.person.dateOfBirth != null ? " *" + element.personInNeedOfCare.person.dateOfBirth : "")
                                        const elementValidty = getValidityValues().find((ele) => { return element.validity === ele.id; });
                                        element.validityString = elementValidty ? elementValidty.name : "Kein Gültigkeitskennzeichen"
                                        element.fromUntilString = getFromUntilStringFromCalculationBasisList(element.calculationBasisList)
                                        return element;
                                    }}
                                    callOnPersonConnectionClick={onClickCalculationBasisEdit}
                                    callOnCaringPersonClick={onClickCaringPersonEdit}
                                    showGender
                                    searchingId={id}
                                />
                            }
                        />
                    </div>
                }
                {id &&
                    <div className='single-view-accordion'>
                        <AccordionComponent
                            label="Berechnungen"
                            defaultExpanded={false}
                            startIcon={<Summarize></Summarize>}
                            content={
                                <div>
                                    TODO ausfüllen!
                                </div>
                            }
                        />
                    </div>
                }
                {id &&
                    <div className='single-view-accordion'>
                        <AccordionComponent
                            label="Historie"
                            defaultExpanded={false}
                            startIcon={<Feed></Feed>}
                            content={
                                <CrudTable
                                    tableData={historyData}
                                    columns={columnsHistory}
                                    sortBy={sortByHistory}
                                    fetchElementsWithId={fetchHistoryForPersonInNeedOfCare}
                                    dataFormatter={(element: PersonInNeedOfCareHistoryAPI) => {
                                        var date = new Date(element.createTimestamp);
                                        element.createTimestamp = (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
                                            (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
                                            date.getFullYear() + " " +
                                            (date.getHours() < 10 ? "0" : "") + date.getHours() + ":" +
                                            (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
                                        date = new Date(element.effectiveDate);
                                        element.effectiveDate = (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
                                            (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
                                            date.getFullYear();
                                        if (element.deleteTimestamp === null) {
                                            element.deleteTimestamp = "";
                                        } else {
                                            date = new Date(element.deleteTimestamp);
                                            element.deleteTimestamp = (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
                                                (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
                                                date.getFullYear() + " " +
                                                (date.getHours() < 10 ? "0" : "") + date.getHours() + ":" +
                                                (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
                                        }


                                        return element;
                                    }}
                                    callOnFindInPageClick={onClickHistoryShow}
                                    hideSearchbar
                                    searchingId={id}
                                />
                            }
                        />
                    </div>
                }
            </div>
        </PageLayout>
    );
}

export default PersonInNeedOfCareShow;
