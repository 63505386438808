import "./styles.scss";
import Login from "./components/pages/auth/login/login";
import { Navigate, Route, Routes } from "react-router";
import MainLayout from "./shared/main-layout";
import AuthGuard from "./guards/auth.guard";
import { useEffect } from "react";
import { unregisterRequest } from "./redux/spinner.reducer";
import { useDispatch, useSelector } from "react-redux";
import { userInformationRequest } from "./redux/auth.reducer";
import { csrfTokenThunk } from "./redux/csrf.reducer";
import { AppDispatch, RootState } from "./redux/store";
import Spinner from "./shared/components/spinner";
import UserOverwiew from "./components/pages/user/overview/user-overview";
import User from "./components/pages/user/detail/user";
import Home from "./components/pages/home/home";
import CaringPersonOverview from "./components/pages/caring-person/overview/caring-person-overview";
import PersonInNeedOfCareOverview from "./components/pages/person-in-need-of-care/overview/person-in-need-of-care-overview";
import InstitutionOverview from "./components/pages/institution/overview/institution-overview";
import Institution from "./components/pages/institution/detail/institution-detail";
import ShowAids from "./components/pages/institution/aid-show/aid-show";
import ShowInsurances from "./components/pages/institution/insurance_show/insurance_show";
import PersonInNeedOfCare from "./components/pages/person-in-need-of-care/detail/person-in-need-of-care-detail";
import PersonInNeedOfCareHistoryDetail from "./components/pages/person-in-need-of-care/history_detail/person-in-need-of-care-history-detail";
import CaringPerson from "./components/pages/caring-person/detail/caring-person-detail";
import CaringPersonHistory from "./components/pages/caring-person/history_detail/caring-person-history-detail";
import ForgotPassword from "./components/pages/auth/forgot-password/forgot-password";
import ResetPassword from "./components/pages/auth/reset-password/reset-password";
import CalculationBasisOverview from "./components/pages/calculation-basis/overview/person-connection-overview";
import CalculationBasis from "./components/pages/calculation-basis/detail/person-connection-detail";
import CalculationBasisHistory from "./components/pages/calculation-basis/history_detail/person-connection-history-detail";
import CalculationBasisShow from "./components/pages/calculation-basis/detail/person-connection-detail-show";
import AidConfig from "./components/pages/institution/aid-config/aid-config-detail";
import VerifyEmail from "./components/pages/auth/verify-email/verify-email";
import PersonInNeedOfCareEntry from "./components/pages/person-in-need-of-care/detail/person-in-need-of-care-detail-entry";
import CaringPersonEntry from "./components/pages/caring-person/detail/caring-person-details-entry";
import CalculationBasisEntry from "./components/pages/calculation-basis/detail/person-connection-detail-entry";
import CaringPersonShow from "./components/pages/caring-person/detail/caring-person-detail-show";
import PersonInNeedOfCareShow from "./components/pages/person-in-need-of-care/detail/person-in-need-of-care-detail-show";
import CalculationRun from "./components/pages/calculation_run/calculation-run";
import ShowPensionInstitutions from "./components/pages/institution/pension-institution-show/pension-institution-show";
import CalculationBasisChanger from "./components/pages/calculation_run/calculation-basis-changer";

function App() {
    const dispatch = useDispatch<AppDispatch>();
    const { pendingRequests } = useSelector(
        (state: RootState) => state.spinnerReducer
    );
    const { isAuthenticated } = useSelector(
        (state: RootState) => state.authReducer
    );

    useEffect(() => {
        dispatch(csrfTokenThunk());
        dispatch(userInformationRequest()).finally(() => {
            dispatch(unregisterRequest());
        });
    }, [dispatch]);
    return (
        <div className="full-size center-content">
            <Routes>
                {isAuthenticated && (
                    <Route
                        element={
                            <AuthGuard
                                loggedInComponent={<MainLayout />}
                                redirectTo="/login"
                            />
                        }
                    >
                        <Route
                            path={"/home"}
                            element={<Home />}
                        />
                        {/***************** base data routes *****************/}
                        <Route
                            path={"/person-in-need-of-care"}
                            element={<PersonInNeedOfCareOverview />}
                        />
                        <Route
                            path={"/person-in-need-of-care/show/:id"}
                            element={<PersonInNeedOfCareShow />}
                        />
                        <Route
                            path={"/person-in-need-of-care/show/:id/history/:history"}
                            element={<PersonInNeedOfCareHistoryDetail />}
                        />
                        <Route
                            path={"/person-in-need-of-care/add"}
                            element={<PersonInNeedOfCareEntry />}
                        />
                        <Route
                            path={"/person-in-need-of-care/add/:processId"}
                            element={<PersonInNeedOfCareEntry />}
                        />
                        <Route
                            path={"/person-in-need-of-care/add/:processId/process"}
                            element={<PersonInNeedOfCare />}
                        />
                        <Route
                            path={"/person-in-need-of-care/edit/:id"}
                            element={<PersonInNeedOfCareEntry />}
                        />
                        <Route
                            path={"/person-in-need-of-care/edit/:id/:processId"}
                            element={<PersonInNeedOfCareEntry />}
                        />
                        <Route
                            path={"/person-in-need-of-care/edit/:id/:processId/process"}
                            element={<PersonInNeedOfCare />}
                        />
                        <Route
                            path={"/person-in-need-of-care/edit/:id/:history"}
                            element={<PersonInNeedOfCareEntry />}
                        />
                        <Route
                            path={"/person-in-need-of-care/edit/:id/:history/:processId"}
                            element={<PersonInNeedOfCareEntry />}
                        />
                        <Route
                            path={"/person-in-need-of-care/edit/:id/:history/:processId/process"}
                            element={<PersonInNeedOfCare />}
                        />
                        <Route
                            path={"/person-in-need-of-care/edit/:id/:processId/process/show/:history"}
                            element={<PersonInNeedOfCareHistoryDetail />}
                        />


                        <Route
                            path={"/caring-person"}
                            element={<CaringPersonOverview />}
                        />
                        <Route
                            path={"/caring-person/show/:id"}
                            element={<CaringPersonShow />}
                        />
                        <Route
                            path={"/caring-person/show/:id/history/:history"}
                            element={<CaringPersonHistory />}
                        />
                        <Route
                            path={"/caring-person/add"}
                            element={<CaringPersonEntry />}
                        />
                        <Route
                            path={"/caring-person/add/:processId"}
                            element={<CaringPersonEntry />}
                        />
                        <Route
                            path={"/caring-person/add/:processId/process"}
                            element={<CaringPerson />}
                        />
                        <Route
                            path={"/caring-person/edit/:id"}
                            element={<CaringPersonEntry />}
                        />
                        <Route
                            path={"/caring-person/edit/:id/:processId"}
                            element={<CaringPersonEntry />}
                        />
                        <Route
                            path={"/caring-person/edit/:id/:processId/process"}
                            element={<CaringPerson />}
                        />
                        <Route
                            path={"/caring-person/edit/:id/:history"}
                            element={<CaringPersonEntry />}
                        />
                        <Route
                            path={"/caring-person/edit/:id/:history/:processId"}
                            element={<CaringPersonEntry />}
                        />
                        <Route
                            path={"/caring-person/edit/:id/:history/:processId/process"}
                            element={<CaringPerson />}
                        />
                        <Route
                            path={"/caring-person/edit/:id/:processId/process/show/:history"}
                            element={<CaringPersonHistory />}
                        />


                        <Route
                            path={"/calculation-basis"}
                            element={<CalculationBasisOverview />}
                        />
                        <Route
                            path={"/calculation-basis/show/:id"}
                            element={<CalculationBasisShow />}
                        />
                        <Route
                            path={"/calculation-basis/show/:id/history/:history"}
                            element={<CalculationBasisHistory />}
                        />
                        <Route
                            path={"/calculation-basis/add"}
                            element={<CalculationBasisEntry />}
                        />
                        <Route
                            path={"/calculation-basis/add/:processId"}
                            element={<CalculationBasisEntry />}
                        />
                        <Route
                            path={"/calculation-basis/add/:processId/process"}
                            element={<CalculationBasis />}
                        />
                        <Route
                            path={"/calculation-basis/edit/:id"}
                            element={<CalculationBasisEntry />}
                        />
                        <Route
                            path={"/calculation-basis/edit/:id/:processId"}
                            element={<CalculationBasisEntry />}
                        />
                        <Route
                            path={"/calculation-basis/edit/:id/:processId/process"}
                            element={<CalculationBasis />}
                        />
                        <Route
                            path={"/calculation-basis/edit/:id/:history"}
                            element={<CalculationBasisEntry />}
                        />
                        <Route
                            path={"/calculation-basis/edit/:id/:history/:processId"}
                            element={<CalculationBasisEntry />}
                        />
                        <Route
                            path={"/calculation-basis/edit/:id/:history/:processId/process"}
                            element={<CalculationBasis />}
                        />
                        <Route
                            path={"/calculation-basis/edit/:id/:processId/process/show/:history"}
                            element={<CalculationBasisHistory />}
                        />

                        {/***************** show data routes *****************/}

                        <Route
                            path={"/aids"}
                            element={<ShowAids />}
                        />

                        <Route
                            path={"/insurances"}
                            element={<ShowInsurances />}
                        />

                        <Route
                            path={"/pension-institutions"}
                            element={<ShowPensionInstitutions />}
                        />

                        {/***************** administration routes *****************/}

                        <Route
                            path={"/user"}
                            element={<UserOverwiew />}
                        />
                        <Route
                            path={"/user/add"}
                            element={<User />}
                        />
                        <Route
                            path={"/user/edit/:id"}
                            element={<User />}
                        />

                        <Route
                            path={"/institution"}
                            element={<InstitutionOverview />}
                        />
                        <Route
                            path={"/institution/add"}
                            element={<Institution />}
                        />
                        <Route
                            path={"/institution/edit/:id"}
                            element={<Institution />}
                        />
                        <Route
                            path={"/aid/config/edit/:id"}
                            element={<AidConfig />}
                        />
                        <Route
                            path={"/calculation"}
                            element={<CalculationRun />}
                        />
                        <Route
                            path={"/update-calculation-basis"}
                            element={<CalculationBasisChanger />}
                        />

                    </Route>
                )}
                <Route
                    path="/forgot-password"
                    element={
                        <AuthGuard
                            loggedOutComponent={<ForgotPassword />}
                            redirectTo="/home"
                        />
                    }
                />
                <Route
                    path="/reset-password/:id"
                    element={
                        <AuthGuard
                            loggedOutComponent={<ResetPassword />}
                            redirectTo="/home"
                        />
                    }
                />
                <Route
                    path="/validate-email/:id"
                    element={
                        <AuthGuard
                            loggedOutComponent={<VerifyEmail />}
                            redirectTo="/home"
                        />
                    }
                />
                <Route
                    path="/login"
                    element={
                        <AuthGuard
                            loggedOutComponent={<Login />}
                            redirectTo="/home"
                        />
                    }
                />
                <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
            <Spinner isLoading={pendingRequests > 0} />
        </div>
    );
}

export default App;
