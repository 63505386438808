import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    TextField,
} from "@mui/material";
import { useState } from "react";
import React from "react";
import { useDispatch } from "react-redux";

import { Credentials } from "../../../../models/credentials";
import { login } from "../../../../redux/auth.reducer";
import { AppDispatch } from "../../../../redux/store";

import "./login.css";
import { AccountCircle, Key } from "@mui/icons-material";
import ErrorDisplay from "../../../../shared/components/error-display";

function Login() {
    const dispatch = useDispatch<AppDispatch>();

    const [credentials, setCredentials] = useState<Credentials>({
        email: "",
        password: "",
    });

    const handleUsernameChange = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        const email = event.target.value.toLowerCase();
        setCredentials({ email, password: credentials.password });
    };

    const handlePasswordChange = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        const password = event.target.value;
        setCredentials({ email: credentials.email, password });
    };

    const signIn = () => {
        dispatch(login(credentials));
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        signIn()
    };

    return (
        <>
            <Card component="form" noValidate onSubmit={handleSubmit} className="login-bg">
                <CardContent className="flex flex-column login-width content-padding">

                    <ErrorDisplay>
                        <div className="login-image-container">
                            <img className="login-image" src="/assets/img/AidPlus_Logo_transparent.png" alt="AidPlus Icon" />
                        </div>

                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "flex-end",
                                marginBottom: "0.5rem",
                            }}
                        >
                            <AccountCircle sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                            <TextField
                                sx={{ width: "100%" }}
                                label="Email-Adresse"
                                variant="standard"
                                onChange={handleUsernameChange}
                            />
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <Key sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                            <TextField
                                sx={{ width: "100%" }}
                                label="Passwort"
                                variant="standard"
                                type="password"
                                onChange={handlePasswordChange}
                            />
                        </Box>
                        <a className="forgot-password" href="/forgot-password">Passwort vergessen?</a>

                    </ErrorDisplay>
                </CardContent>
                <CardActions className="flex flex-end">
                    <Button variant="contained" type="submit">
                        Login
                    </Button>
                </CardActions>
            </Card>

        </>
    );
}

export default Login;
