import { InputAdornment, TextField } from "@mui/material";
import React from 'react';
import "./text-field-component.css";

type TextFieldProps = {
    id: string,
    type: React.HTMLInputTypeAttribute,
    required: boolean,
    label: string,
    value: string | number | null,
    error: boolean,
    helperText: string,
    disabled: boolean,
    setValueString?: (value: string) => void;
    setValueNumber?: (value: number) => void;
    setValueNull?: (value: null) => void,
    endButton?: React.ReactNode
    setValueChanged?: (val: boolean) => void;
};

const TextFieldComponent = ({
    id,
    required,
    type,
    label,
    value,
    error,
    helperText,
    disabled,
    setValueString,
    setValueNumber,
    setValueNull,
    endButton,
    setValueChanged
}: TextFieldProps) => {
    return <TextField
        sx={{
            input: { color: '#2D3335' },
            "& .MuiInputBase-input": { backgroundColor: "#EEEEEE" },
            "& .MuiFormLabel-asterisk": { color: "red" },
        }}
        InputLabelProps={{ className: "textfield__label" }}
        required={required}
        id={id}
        label={label}
        type={type}
        value={value}
        className="single-view__text-field"
        variant="filled"
        error={error}
        helperText={
            helperText
        }
        disabled={disabled}
        onChange={(event) => {
            if (setValueString) {
                setValueString(event.target.value);
            } else if (setValueNumber) {
                setValueNumber(+event.target.value);
            } else if (setValueNull) {
                setValueNull(null);
            }
            if (setValueChanged) {
                setValueChanged(true);
            }
        }}
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    {endButton ?
                        endButton : <div></div>
                    }
                </InputAdornment>
            )
        }}
    />
}

export default TextFieldComponent;