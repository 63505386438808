import { useEffect } from 'react';
import PageLayout from "../../../../shared/components/page-layout";
import "../../details.css";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { StatusSelect, ValiditySelect, getStatusValues, getValidityValues } from '../../../../models/historization';
import HistoryShow from '../../../../shared/components/history-show/history-show';
import { fetchCaringPersons } from '../../../../shared/requests/caring-person.requests';
import { PersonInNeedOfCareSelect } from '../../../../models/person-in-need-of-care';
import { fetchSingleHistoryForPersonConnection } from '../../../../shared/requests/calculation-basis.requests';
import PersonConnectionForm from '../form/person-connection-form';
import { fetchPersonsInNeedOfCare } from '../../../../shared/requests/person-in-need-of-care.requests';
import { CaringPersonSelect } from '../../../../models/caring-person';
import CalculationBasisForm from '../form/calculation-basis-form';
import { CalculationBasisAmountFlagSelect, CalculationBasisAPI } from '../../../../models/calculation-basis';
import { addToHistory, HistoryTimelineItemModel } from '../../../../models/history';
import HistoryLine from '../../../../shared/components/history_line';

function PersonConnectionHistory() {
    //Select Listen
    const [caringPersonValues, setCaringPersonValues] = useState<CaringPersonSelect[]>([]);
    const [personInNeedOfCareValues, setPersonInNeedOfCareValues] = useState<PersonInNeedOfCareSelect[]>([]);
    //Berechnungsgrundlage extra Daten
    const [caringPerson, setCaringPerson] = useState<CaringPersonSelect | null>(null);
    const [personInNeedOfCare, setPersonInNeedOfCare] = useState<PersonInNeedOfCareSelect | null>(null);
    const [effectiveDate, setEffectiveDate] = useState<string>("");
    const [endingDate, setEndingDate] = useState<string | null>(null);
    const [amount, setAmount] = useState<number>(0.0);
    const [amountFlag, setAmountFlag] = useState<CalculationBasisAmountFlagSelect | null>(null);

    //Historisierungsdaten
    const [validity, setValidity] = useState<ValiditySelect | null>(null);
    const [status, setStatus] = useState<StatusSelect | null>(null);
    const [createTimestamp, setCreateTimestamp] = useState<string>("");
    const [deleteTimestamp, setDeleteTimestamp] = useState<string>("");
    const [createTransactionID, setCreateTransactionID] = useState<string>("");
    const [createTransactionUserEmail, setCreateTransactionUserEmail] = useState<string>("");
    const [deleteTransactionID, setDeleteTransactionID] = useState<string>("");
    const [deleteTransactionUserEmail, setDeleteTransactionUserEmail] = useState<string>("");
    const [imported, setImported] = useState<boolean>(true);
    const [calculationBasisList, setCalculationBasisList] = useState<CalculationBasisAPI[]>([]);
    //Systemdaten
    const { id, history, processId } = useParams();
    const [isCurrentRendered, setCurrentRendered] = useState<boolean>(false);
    const [historyChronoItems, setHistoryChronoItems] = useState<HistoryTimelineItemModel[]>([]);

    useEffect(() => {
        fetchPersonsInNeedOfCare().then((response) => {
            let personsInNeedOfCare = response.data;
            let personsSelect: PersonInNeedOfCareSelect[] = [];
            personsInNeedOfCare.forEach((person) => {
                personsSelect.push({
                    id: String(person.id),
                    name: person.person.firstname + " " + person.person.lastname,
                    realValue: +person.id,
                    genderId: person.person.gender
                })
            })
            setPersonInNeedOfCareValues(personsSelect)
        });
        fetchCaringPersons().then((response) => {
            let caringPersons = response.data;
            let personsSelect: CaringPersonSelect[] = [];
            caringPersons.forEach((person) => {
                personsSelect.push({
                    id: String(person.id),
                    name: person.person.firstname + " " + person.person.lastname,
                    realValue: +person.id,
                    genderId: person.person.gender
                })
            })
            setCaringPersonValues(personsSelect)
        });
        if (id && history) {
            fetchSingleHistoryForPersonConnection(id, history).then((response) => {
                setCaringPerson({
                    id: response.data.caringPerson.id,
                    name: response.data.caringPerson.person.firstname + " " + response.data.caringPerson.person.lastname,
                    realValue: +response.data.caringPerson.id,
                    genderId: response.data.caringPerson.person.gender
                });
                setPersonInNeedOfCare({
                    id: response.data.personInNeedOfCare.id,
                    name: response.data.personInNeedOfCare.person.firstname + " " + response.data.personInNeedOfCare.person.lastname,
                    realValue: +response.data.personInNeedOfCare.id,
                    genderId: response.data.personInNeedOfCare.person.gender
                });
                setCalculationBasisList(response.data.calculationBasisList.sort((a, b) => a.from > b.from ? 1 : -1))
                //Historiendaten setzen
                const element = getValidityValues().find((element) => { return response.data.validity === element.id; });
                if (element) {
                    setValidity(element);
                }
                const elementStatus = getStatusValues().find((element) => { return response.data.status === element.id; });
                if (elementStatus) {
                    setStatus(elementStatus);
                }
                setImported(response.data.imported)
                setCreateTimestamp(response.data.createTimestamp);
                setCreateTransactionID(response.data.createTransaction.id)
                setCreateTransactionUserEmail(response.data.createTransaction.user.email)
                if (response.data.deleteTimestamp != null) {
                    setDeleteTimestamp(response.data.deleteTimestamp);
                }
                if (response.data.deleteTransaction != null) {
                    setDeleteTransactionID(response.data.deleteTransaction.id);
                    setDeleteTransactionUserEmail(response.data.deleteTransaction.user.email)
                }
                if (!isCurrentRendered) {
                    const historyData: HistoryTimelineItemModel[] = [];
                    addToHistory({
                        type: "Historie",
                        name: `${response.data.personInNeedOfCare.person.lastname}, ${response.data.personInNeedOfCare.person.firstname} | ${response.data.caringPerson.person.lastname}, ${response.data.caringPerson.person.firstname}`,
                        url: `/calculation-basis/edit/${id}/${processId}/process/show/${history}`
                    }).forEach((element => {
                        historyData.push({
                            type: element.type,
                            title: element.name,
                            url: element.url
                        })
                    }))
                    setHistoryChronoItems(historyData);
                }

            });
            setCurrentRendered(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUserTitle = (): string => {
        if (!imported) {
            return "Berechnungsgrundlage Historiendetails anzeigen"
        } else {
            return "Berechnungsgrundlage importieren"
        }
    };


    return (
        <PageLayout title={getUserTitle()}>
            <div className="single-view">
                {id && <HistoryLine
                    items={historyChronoItems}
                />}
                <PersonConnectionForm
                    caringPerson={caringPerson}
                    caringPersonValues={caringPersonValues}
                    disabled={true}
                    personInNeedOfCare={personInNeedOfCare}
                    personInNeedOfCareValues={personInNeedOfCareValues}
                    savePushed={false}
                    setCaringPerson={setCaringPerson}
                    setPersonInNeedOfCare={setPersonInNeedOfCare}
                    formError={false}
                />
                <CalculationBasisForm
                    disabled={true}
                    savePushed={false}
                    formError={false}
                    dateFrom={effectiveDate}
                    dateUntil={endingDate}
                    amount={amount}
                    amountFlag={amountFlag}
                    calculationBasisList={calculationBasisList}
                    setDateFrom={setEffectiveDate}
                    setDateUntil={setEndingDate}
                    setAmount={setAmount}
                    setAmountFlag={setAmountFlag}
                    setCalculationBasisList={setCalculationBasisList}
                />

                <HistoryShow
                    validity={validity}
                    status={status}
                    createTimestamp={createTimestamp}
                    deleteTimestamp={deleteTimestamp}
                    createTransactionID={createTransactionID}
                    createTransactionUserEmail={createTransactionUserEmail}
                    deleteTransactionID={deleteTransactionID}
                    deleteTransactionUserEmail={deleteTransactionUserEmail}
                />


            </div>
        </PageLayout >
    );
}

export default PersonConnectionHistory;
