import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";
import "./dialog.css";
import { Theme, useTheme } from "@mui/material";
import ErrorDisplay from "../../error-display";
import SubmitButtonsErrorDialog from "../submit-buttons/submit-button-dialog-confirm";

export interface DeleteProps {
    showDialog: boolean;
    dialogInfo: string | undefined;
    onClickSubmit: () => void;
}

const ErrorDialog = ({
    showDialog,
    onClickSubmit,
    dialogInfo,
}: DeleteProps) => {
    const theme: Theme = useTheme();

    return (
        <Dialog open={showDialog}>
            <DialogTitle
                sx={{ backgroundColor: theme.palette.primary.main, color: "white" }}
            >
                Es ist ein Fehler aufgetreten!
            </DialogTitle>
            <DialogContent sx={{ marginTop: "1rem" }} className="DialogContent">
                <ErrorDisplay>
                    <DialogContentText>
                        {dialogInfo}
                    </DialogContentText>
                </ErrorDisplay>
            </DialogContent>
            <DialogActions>
                <SubmitButtonsErrorDialog
                    submitText="OK"
                    onClickSubmit={onClickSubmit}
                />
            </DialogActions>
        </Dialog>
    );
};

export default ErrorDialog;
