import { useEffect } from 'react';
import PageLayout from "../../../../shared/components/page-layout";
import "../../details.css";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import { } from "../../../../redux/auth.reducer";
import { StatusSelect, ValiditySelect, getStatusValues, getValidityValues } from '../../../../models/historization';
import { InstitutionSelect } from '../../../../models/institution';
import AddressForm from '../../../../shared/components/address-form/address-form';
import { GenderType, getGenderType } from '../../../../models/person';
import { fetchAids, fetchInsurances, fetchPensionInstitutions } from '../../../../shared/requests/institution.requests';
import PersonForm from '../../../../shared/components/person-form/person_form';
import HistoryShow from '../../../../shared/components/history-show/history-show';
import { fetchSingleHistoryForCaringPerson } from '../../../../shared/requests/caring-person.requests';
import CaringPersonForm from '../form/caring-person-form';
import { CountryData, getCountries } from '../../../../shared/components/select-component-country';
import { addToHistory, HistoryTimelineItemModel } from '../../../../models/history';
import HistoryLine from '../../../../shared/components/history_line';

function CaringPersonHistory() {
    //Select Listen
    const [aidValues, setAidValues] = useState<InstitutionSelect[]>([]);
    const [insuranceValues, setInsuranceValues] = useState<InstitutionSelect[]>([]);
    const [pensionInstitutionValues, setPensionInstitutionValues] = useState<InstitutionSelect[]>([]);
    //Pflegeperson extra Daten
    const [effectiveDate, setEffectiveDate] = useState<string>("");
    const [caringPersonExternalIdentifier, setCaringPersonExternalIdentifier] = useState<string>("");
    const [pensionInstitution, setPensionInstitution] = useState<InstitutionSelect | null>(null);
    const [conceptOfOrder, setConceptOfOrder] = useState<string | null>(null);
    const [insurance, setInsurance] = useState<InstitutionSelect | null>(null);
    const [aid, setAid] = useState<InstitutionSelect | null>(null);
    //Personendaten
    const [lastname, setLastname] = useState<string>("");
    const [birthName, setBirthName] = useState<string | null>("");
    const [firstname, setFirstname] = useState<string>("");
    const [placeOfBirth, setPlaceOfBirth] = useState<string>("");
    const [countryOfBirth, setCountryOfBirth] = useState<CountryData | null>(null);
    const [dateOfBirth, setDateOfBirth] = useState<string | null>("");
    const [gender, setGender] = useState<GenderType | null>(null);
    const [namePrefix, setNamePrefix] = useState<string | null>("");
    const [title, setTitle] = useState<string | null>("");
    const [nationality, setNationality] = useState<CountryData | null>(null);
    const [pensionInsuranceNumber, setPensionInsuranceNumber] = useState<string | null>("");
    //Addressdaten
    const [street, setStreet] = useState<string>("");
    const [houseNumber, setHouseNumber] = useState<number | null>(0);
    const [country, setCountry] = useState<CountryData | null>(null);
    const [postalCode, setPostalCode] = useState<string>("");
    const [location, setLocation] = useState<string>("");
    const [poBox, setPOBox] = useState<string>("");
    const [poBoxPostalCode, setPOBoxPostalCode] = useState<string>("");
    const [addressSupplement, setAddressSupplement] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [fax, setFax] = useState<string>("");
    const [email, setEmail] = useState<string>("");

    //Historisierungsdaten
    const [validity, setValidity] = useState<ValiditySelect | null>(null);
    const [status, setStatus] = useState<StatusSelect | null>(null);
    const [createTimestamp, setCreateTimestamp] = useState<string>("");
    const [deleteTimestamp, setDeleteTimestamp] = useState<string>("");
    const [createTransactionID, setCreateTransactionID] = useState<string>("");
    const [createTransactionUserEmail, setCreateTransactionUserEmail] = useState<string>("");
    const [deleteTransactionID, setDeleteTransactionID] = useState<string>("");
    const [deleteTransactionUserEmail, setDeleteTransactionUserEmail] = useState<string>("");
    const [imported, setImported] = useState<boolean>(true);
    //Systemdaten
    const { user } = useSelector(({ authReducer }: RootState) => authReducer);
    const { id, history, processId } = useParams();
    const [isCurrentRendered, setCurrentRendered] = useState<boolean>(false);
    const [historyChronoItems, setHistoryChronoItems] = useState<HistoryTimelineItemModel[]>([]);

    useEffect(() => {
        fetchAids().then((response) => {
            let institutions = response.data;
            let institutionsSelect: InstitutionSelect[] = [];
            institutions.forEach((institution) => {
                if (institution.id === user?.id) {
                    setAid({
                        id: String(institution.id),
                        name: institution.name,
                        realValue: +institution.id,
                        type: institution.type
                    });
                }
                institutionsSelect.push({
                    id: String(institution.id),
                    name: institution.name,
                    realValue: +institution.id,
                    type: institution.type
                })
            })
            setAidValues(institutionsSelect);
        })
        fetchPensionInstitutions().then((response) => {
            let institutions = response.data;
            let institutionsSelect: InstitutionSelect[] = [];
            institutions.forEach((institution) => {
                if (institution.id === user?.id) {
                    setAid({
                        id: String(institution.id),
                        name: institution.name,
                        realValue: +institution.id,
                        type: institution.type
                    });
                }
                institutionsSelect.push({
                    id: String(institution.id),
                    name: institution.name,
                    realValue: +institution.id,
                    type: institution.type
                })
            })
            setPensionInstitutionValues(institutionsSelect);
        })
        fetchInsurances().then((response) => {
            let institutions = response.data;
            let institutionsSelect: InstitutionSelect[] = [];
            institutions.forEach((institution) => {
                if (institution.id === user?.id) {
                    setInsurance({
                        id: String(institution.id),
                        name: institution.name,
                        realValue: +institution.id,
                        type: institution.type
                    });
                }
                institutionsSelect.push({
                    id: String(institution.id),
                    name: institution.name,
                    realValue: +institution.id,
                    type: institution.type
                })
            })
            setInsuranceValues(institutionsSelect);
        })
        if (id && history) {
            fetchSingleHistoryForCaringPerson(id, history).then((response) => {
                setEffectiveDate(response.data.effectiveDate);
                setCaringPersonExternalIdentifier(response.data.caringPersonExternalIdentifier)
                if (response.data.pensionInstitution !== null) {
                    setPensionInstitution({
                        id: response.data.pensionInstitution.id,
                        name: response.data.pensionInstitution.name,
                        realValue: +response.data.pensionInstitution.id,
                        type: response.data.pensionInstitution.type
                    });
                }
                setConceptOfOrder(response.data.conceptOfOrder);
                setInsurance({
                    id: response.data.insurance.id,
                    name: response.data.insurance.name,
                    realValue: +response.data.insurance.id,
                    type: response.data.insurance.type
                })
                setAid({
                    id: response.data.client.id,
                    name: response.data.client.name,
                    realValue: +response.data.client.id,
                    type: response.data.client.type
                })
                // Addressdaten setzen
                if (response.data.address != null) {
                    setStreet(response.data.address.street);
                    setHouseNumber(response.data.address.houseNumber);
                    const elementCountry = getCountries().find((element) => { return response.data.address?.country === element.value; });
                    if (elementCountry) {
                        setCountry(elementCountry)
                    }
                    setPostalCode(response.data.address.postalCode);
                    setLocation(response.data.address.location);
                    setPOBox(response.data.address.poBox);
                    setPOBoxPostalCode(response.data.address.poBoxPostalCode);
                    setAddressSupplement(response.data.address.addressSupplement);
                    setPhoneNumber(response.data.address.phoneNumber);
                    setFax(response.data.address.fax);
                    setEmail(response.data.address.email);
                }

                //Personendaten setzen
                setFirstname(response.data.person.firstname)
                setLastname(response.data.person.lastname)
                setBirthName(response.data.person.birthName)
                setPlaceOfBirth(response.data.person.placeOfBirth)
                const elementCountryBirth = getCountries().find((element) => { return response.data.person.countryOfBirth === element.value; });
                if (elementCountryBirth) {
                    setCountryOfBirth(elementCountryBirth)
                }
                setDateOfBirth(response.data.person.dateOfBirth)
                setNamePrefix(response.data.person.namePrefix)
                setTitle(response.data.person.title)
                const elementNationality = getCountries().find((element) => { return response.data.person.nationality === element.value; });
                if (elementNationality) {
                    setNationality(elementNationality)
                }
                setPensionInsuranceNumber(response.data.person.pensionInsuranceNumber)
                const elementGender = getGenderType().find((element) => { return response.data.person.gender === element.id; });
                if (elementGender) {
                    setGender(elementGender)
                }

                //Historiendaten setzen
                const element = getValidityValues().find((element) => { return response.data.validity === element.id; });
                if (element) {
                    setValidity(element);
                }
                const elementStatus = getStatusValues().find((element) => { return response.data.status === element.id; });
                if (elementStatus) {
                    setStatus(elementStatus);
                }
                setCreateTimestamp(response.data.createTimestamp);
                setCreateTransactionID(response.data.createTransaction.id)
                setCreateTransactionUserEmail(response.data.createTransaction.user.email)
                setImported(response.data.imported)
                if (response.data.deleteTimestamp != null) {
                    setDeleteTimestamp(response.data.deleteTimestamp);
                }
                if (response.data.deleteTransaction != null) {
                    setDeleteTransactionID(response.data.deleteTransaction.id);
                    setDeleteTransactionUserEmail(response.data.deleteTransaction.user.email)
                }
                if (!isCurrentRendered) {
                    const historyData: HistoryTimelineItemModel[] = [];
                    addToHistory({
                        type: "Historie",
                        name: `${response.data.person.lastname}, ${response.data.person.firstname}`,
                        url: `/calculation-basis/edit/${id}/${processId}/process/show/${history}`
                    }).forEach((element => {
                        historyData.push({
                            type: element.type,
                            title: element.name,
                            url: element.url
                        })
                    }))
                    setHistoryChronoItems(historyData);
                }
            });
            setCurrentRendered(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUserTitle = (): string => {
        if (!imported) {
            return "Pflegeperson Historiendetails anzeigen"
        } else {
            return "Pflegeperson importieren"
        }
    };

    return (
        <PageLayout title={getUserTitle()}>
            <div className="single-view">
                {id && <HistoryLine
                    items={historyChronoItems}
                />}
                <PersonForm
                    savePushed={false}
                    disabled={true}
                    lastname={lastname}
                    setLastname={setLastname}
                    birthName={birthName}
                    setBirthName={setBirthName}
                    firstname={firstname}
                    setFirstname={setFirstname}
                    placeOfBirth={placeOfBirth}
                    setPlaceOfBirth={setPlaceOfBirth}
                    countryOfBirth={countryOfBirth}
                    setCountryOfBirth={setCountryOfBirth}
                    dateOfBirth={dateOfBirth}
                    setDateOfBirth={setDateOfBirth}
                    gender={gender}
                    setGender={setGender}
                    namePrefix={namePrefix}
                    setNamePrefix={setNamePrefix}
                    title={title}
                    setTitle={setTitle}
                    nationality={nationality}
                    setNationality={setNationality}
                    pensionInsuranceNumber={pensionInsuranceNumber}
                    setPensionInsuranceNumber={setPensionInsuranceNumber}
                    formError={false}
                />

                <AddressForm
                    savePushed={false}
                    disabled={true}
                    street={street}
                    setStreet={setStreet}
                    houseNumber={houseNumber}
                    setHouseNumber={setHouseNumber}
                    postalCode={postalCode}
                    setPostalCode={setPostalCode}
                    location={location}
                    setLocation={setLocation}
                    country={country}
                    setCountry={setCountry}
                    poBox={poBox}
                    setPOBox={setPOBox}
                    poBoxPostalCode={poBoxPostalCode}
                    setPOBoxPostalCode={setPOBoxPostalCode}
                    addressSupplement={addressSupplement}
                    setAddressSupplement={setAddressSupplement}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    fax={fax}
                    setFax={setFax}
                    email={email}
                    setEmail={setEmail}
                    useAddress={true}
                    formError={false}
                />
                <CaringPersonForm
                    aid={aid}
                    aidValues={aidValues}
                    pensionInstitutionValues={pensionInstitutionValues}
                    caringPersonExternalIdentifier={caringPersonExternalIdentifier}
                    conceptOfOrder={conceptOfOrder}
                    disabled={true}
                    effectiveDate={effectiveDate}
                    insurance={insurance}
                    insuranceValues={insuranceValues}
                    pensionInstitution={pensionInstitution}
                    savePushed={false}
                    setAid={setAid}
                    setCaringPersonExternalIdentifier={setCaringPersonExternalIdentifier}
                    setConceptOfOrder={setConceptOfOrder}
                    setEffectiveDate={setEffectiveDate}
                    setInsurance={setInsurance}
                    setPensionInstitution={setPensionInstitution}
                    user={user}
                    formError={false}
                />
                <HistoryShow
                    validity={validity}
                    status={status}
                    createTimestamp={createTimestamp}
                    deleteTimestamp={deleteTimestamp}
                    createTransactionID={createTransactionID}
                    createTransactionUserEmail={createTransactionUserEmail}
                    deleteTransactionID={deleteTransactionID}
                    deleteTransactionUserEmail={deleteTransactionUserEmail}
                />

            </div>
        </PageLayout>
    );
}

export default CaringPersonHistory;
