import { CalculationBasisAPI } from "../models/calculation-basis";

export const formatDateString = (dateString: string) => {
    var date = new Date(dateString);
    return (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
        (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
        date.getFullYear();
}

export const formatMonthString = (dateString: string) => {
    var date = new Date(dateString);
    return (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
        date.getFullYear();
}

export const formatTimestampString = (timestampString: string) => {
    var date = new Date(timestampString);
    return (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
        (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
        date.getFullYear() + " " +
        (date.getHours() < 10 ? "0" : "") + date.getHours() + ":" +
        (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
}

export const getFromUntilStringFromCalculationBasisList = (list: CalculationBasisAPI[]) => {
    var lowestStartDate: Date;
    var highestEndDate: Date | null;
    if (list.length === 0) {
        return "";
    } else {
        list = list.sort((a, b) => a.from > b.from ? 1 : -1);
        lowestStartDate = new Date(list[0].from)
        list = list.sort((a, b) => a.until === null ? -1 : b.until === null ? 1 : a.until > b.until ? -1 : 1);
        if (list[0].until !== null) {
            highestEndDate = new Date(list[0].until)
        } else {
            highestEndDate = null;
        }
    }
    if (highestEndDate !== null) {
        return (lowestStartDate.getDate() < 10 ? "0" : "") + lowestStartDate.getDate() + "." +
            (lowestStartDate.getMonth() < 9 ? "0" : "") + (lowestStartDate.getMonth() + 1) + "." +
            lowestStartDate.getFullYear() + " - " + (highestEndDate.getDate() < 10 ? "0" : "") + highestEndDate.getDate() + "." +
            (highestEndDate.getMonth() < 9 ? "0" : "") + (highestEndDate.getMonth() + 1) + "." +
            highestEndDate.getFullYear();
    } else {
        return (lowestStartDate.getDate() < 10 ? "0" : "") + lowestStartDate.getDate() + "." +
            (lowestStartDate.getMonth() < 9 ? "0" : "") + (lowestStartDate.getMonth() + 1) + "." +
            lowestStartDate.getFullYear() + " - Offen";
    }

}