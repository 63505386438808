import { Institution, InstitutionAPI } from "./institution";
import { PersonInNeedOfCare } from "./person-in-need-of-care";
import { UserAPI } from "./user";

export interface AidConfig {
    id: string;
    institution: Institution
    autoImport: boolean
    aidLevels: AidLevel[]
    pensionRecords: InsuranceRecord[]
    unemploymentRecords: InsuranceRecord[]
}

export type AidConfigAPI = {
    id: string;
    institution: InstitutionAPI
    autoImport: boolean
    aidLevels: AidLevelAPI[]
    pensionRecords: InsuranceRecordAPI[]
    unemploymentRecords: InsuranceRecordAPI[]
};

export type AidConfigAPIRequest = {
    institutionId: string;
    autoImport: boolean
    aidLevels: AidLevelAPIRequest[]
    pensionRecords: InsuranceRecordRequestAPI[]
    unemploymentRecords: InsuranceRecordRequestAPI[]
};

export type AidLevel = {
    id: string;
    aidConfiguration: AidConfig;
    percentage: number;
    validity: string;
    personsInNeedOfCareForAidLevel: PersonInNeedOfCare[];
}

export type AidLevelAPI = {
    id: string;
    percentage: number;
    validity: string;
    amountPersonInNeedOfCare: number;
    canBeDeleted: boolean;
}
export type AidLevelAPIRequest = {
    id: string;
    percentage: number;
    validity: string;
}

export type InsuranceRecord = {
    id: string;
    recordYear: number;
    percentage: number;
}

export type InsuranceRecordAPI = {
    id: string;
    recordYear: number;
    percentage: number;
}

export type InsuranceRecordRequestAPI = {
    recordYear: number;
    percentage: number;
}

export type NotImportedEntitiesAPI = {
    objectType: string;
    id: string;
    historyNumber: string;
    effectiveDate: string;
    name: string;
    createTimestamp: string;
    effectiveString: string;
};

export type CalculationRunAPI = {
    id: string;
    client: InstitutionAPI;
    startTimestamp: string;
    endTimestamp: string;
    status: string;
    runStartUser: UserAPI;
    amountCalculatedCaringPerson: number;
    sumRV: number;
    sumABV: number;
    sumAV: number;
    statusString: string;
    startString: string;
    endString: string;
    startUserString: string;
    rvString: string;
    abvString: string;
    avString: string
};

export type CalculationBasisChangerAPIRequest = {
    effectiveDate: string;
    percentageChange: number;
}

export type CalculationRunSort = Pick<
    CalculationRunAPI,
    "id" | "startString" | "endString"
>;

export type AidConfigAutoImport = {
    id: string;
    name: string;
    realValue: boolean;
}

export type AidConfigAidLevels = {
    id: string;
    name: string;
}


export const getAutoImportType = () => {
    return [
        {
            id: "true",
            name: "Änderungen von Versicherungen automatisch importieren",
            realValue: true,
        },
        {
            id: "false",
            name: "Änderungen von Versicherung erst nach Genehmigung importieren",
            realValue: false,
        }
    ]
}