import { Button } from "@mui/material";

export interface SubmitProps {
    submitText: string;
    cancelText: string;
    submitLink?: string;
    cancelLink?: string;
    isValid?: boolean;
    onClickSubmit: (date: string) => void;
    onClickCancel?: () => void;
    value: string;
}

function SubmitButtonsDialogHistory({
    submitText,
    cancelText,
    onClickCancel,
    onClickSubmit,
    value
}: SubmitProps) {
    return (
        <>
            <Button variant="contained" onClick={() => onClickSubmit(value)}>
                {submitText}
            </Button>
            <Button variant="contained" onClick={onClickCancel}>
                {cancelText}
            </Button>
        </>
    );
}

export default SubmitButtonsDialogHistory;
