import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchProcessAndCheckAccessabilityForPersonInNeedOfCare } from "../../../../shared/requests/person-in-need-of-care.requests";
import PageLayout from "../../../../shared/components/page-layout";
import SubmitButtonsHistory from "../../../../shared/components/standard-actions/submit-buttons/submit-button-history";
import ErrorDialog from "../../../../shared/components/standard-actions/dialogs/error-dialog";

function PersonInNeedOfCareEntry() {
    const navigate = useNavigate();
    const [initialLoad, setInitialLoad] = useState<boolean>(true);
    const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
    const { id, processId } = useParams();
    const getUserTitle = (): string => {
        const createUserTitle = "Bedürftige/n erstellen";
        const editUserTitle = "Bedürftige/n bearbeiten";
        if (id) {
            return editUserTitle;
        }
        return createUserTitle;
    };

    const getSavingButtonText = (): string => {
        const createEmployeeText = "Erstellen";
        const editEmployeeText = "Speichern";
        if (id) {
            return editEmployeeText;
        }
        return createEmployeeText;
    };

    const actions = (
        <SubmitButtonsHistory
            submitText={getSavingButtonText()}
            cancelText="Verwerfen"
            pauseText="Unterbrechen"
            onClickSubmit={() => { }}
            onClickCancel={() => { }}
            onClickPause={() => { }}
        />
    );

    useEffect(() => {
        if (initialLoad) {
            setInitialLoad(false)
            fetchProcessAndCheckAccessabilityForPersonInNeedOfCare(id ? id : "-1", processId ? processId : "-1")
                .then((response) => {
                    if (processId) {
                        navigate(`process`)
                    } else {
                        const procID = response.data.id;
                        navigate(`${procID}/process`)
                    }
                })
                .catch(() => {
                    setShowErrorDialog(true)
                })
        }
    }, [initialLoad, navigate, id, processId]);

    return (
        <PageLayout title={getUserTitle()} actions={actions}>
            <ErrorDialog
                showDialog={showErrorDialog}
                dialogInfo="Der/Die Bedürftige/r wird aktuell von einem anderen Benutzer / einem anderen Vorgang bearbeitet!"
                onClickSubmit={() => {
                    navigate(-1)
                }}
            />
        </PageLayout>
    );
}

export default PersonInNeedOfCareEntry;
