import { CheckCircle, Error, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React from "react";

type AccordionProps = {
    label: string;
    content: React.ReactNode;
    defaultExpanded: boolean;
    startIcon?: React.ReactNode;
    validation?: boolean;
    error?: boolean;
};

const AccordionComponent = ({
    label,
    content,
    defaultExpanded,
    startIcon,
    validation,
    error
}: AccordionProps) => {
    return <Accordion defaultExpanded={defaultExpanded}>
        <AccordionSummary
            id="panel-header"

            aria-controls="panel-content"
            expandIcon={<ExpandMore sx={{ color: 'success.dark' }} />}
            sx={{
                flexDirection: 'row-reverse',
                '&.Mui-expanded': { backgroundColor: '#D4E7FF', height: "100%", width: "100%" },
                '& .MuiAccordionSummary-content': {
                    marginLeft: 2,
                    justifyContent: "space-between"
                },
            }}>
            <div className="flex flex-row accordion-summary___icon-gap">
                {startIcon ? startIcon : <div></div>}
                {label}
            </div>
            {validation ? (error === true ? <Error sx={{ color: "red" }} /> : <CheckCircle sx={{ color: "green" }} />) : <div></div>}
        </AccordionSummary>
        <AccordionDetails>
            {content}
        </AccordionDetails>
    </Accordion>
}

export default AccordionComponent;