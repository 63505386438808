import { useEffect, useRef, useState } from "react";
import { SortingDirection } from "../../../../enums/sort-by";
import { PersonInNeedOfCareAPI, PersonInNeedOfCareSort } from "../../../../models/person-in-need-of-care";
import CrudTable, {
    ColumnDefinitionType,
} from "../../../../shared/components/crud-table/crud-table";
import {
    makePersonInNeedOfCareInvalid,
    fetchPersonsInNeedOfCare,
} from "../../../../shared/requests/person-in-need-of-care.requests";
import PageLayout from "../../../../shared/components/page-layout";
import { Sort } from "../../../../hooks/useSort.hook";
import { DateAPI, getValidityValues } from "../../../../models/historization";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { } from "../../../../redux/auth.reducer";
import { useNavigate } from "react-router-dom";
import InvalidDialog from "../../../../shared/components/standard-actions/dialogs/invalid-dialog";
import { clearHistory } from "../../../../models/history";

function ListPersonsInNeedOfCare() {
    const [sortBy] = useState<Sort<PersonInNeedOfCareSort>>({
        key: "id",
        direction: SortingDirection.Descending,
    });
    const { user } = useSelector(({ authReducer }: RootState) => authReducer);
    const [persons, setPersons] = useState<PersonInNeedOfCareAPI[]>([]);
    const navigate = useNavigate();
    const isInitRender = useRef(true);
    const [showInvalidDialog, setShowInvalidDialog] = useState<boolean>(false);
    const [entityName, setEntityName] = useState<string>("");
    const [clickedId, setClickedId] = useState<string>("");

    const onShowClick = (entity: PersonInNeedOfCareAPI) => {
        navigate(`show/${entity.id}`);
    }

    const onAddClick = () => {
        navigate('add');
    }
    const onEditClick = (entity: PersonInNeedOfCareAPI) => {
        navigate(`edit/${entity.id}`);
    }
    const onInvalidClick = (entity: PersonInNeedOfCareAPI) => {
        setEntityName(entity.personName)
        setClickedId(entity.id)
        setShowInvalidDialog(true)
    }
    const onInvalidCancel = () => {
        setShowInvalidDialog(false)
    }

    const onInvalidSubmit = (value: string) => {
        const date: DateAPI = {
            effectiveDate: value
        }
        makePersonInNeedOfCareInvalid(clickedId ?? "", date).then((response) => {
            if (response.status === 200) {
                setShowInvalidDialog(false)
            }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        clearHistory();
        if (isInitRender.current) {
            fetchPersonsInNeedOfCare().then((response) => {
                setPersons(response.data);
            });
            isInitRender.current = false;
        }
    });

    const columns: ColumnDefinitionType<PersonInNeedOfCareAPI, keyof PersonInNeedOfCareAPI>[] = [
        {
            key: "id",
            header: "ID",
        },

        {
            key: "personName",
            header: "Name, Vorname, Geburtsdatum",
        },

        {
            key: "addressString",
            header: "Anschrift",
        },

        {
            key: "effectiveDate",
            header: "Wirksamkeitsdatum",
        },
        {
            key: "conceptOfOrder1",
            header: "Ord. Begriff1",
        },
        {
            key: "validityString",
            header: "Gültigkeit",
        }
    ];
    return (
        <PageLayout title={"Übersicht Bedürftige | Aktueller Zustand"}>
            <InvalidDialog
                showDialog={showInvalidDialog}
                dialogTitle={"Ungültig setzen eines Bedürftigen"}
                deleteInfo={`Zu wann soll die/der Bedürftige/r { ${entityName} } ungültig werden?`}
                onClickCancel={onInvalidCancel}
                onClickSubmit={(value: string) => onInvalidSubmit(value)}
            />
            <CrudTable
                callOnAddClick={user?.userRole === "USER_ROLE_SYSTEM_ADMIN" ? undefined : onAddClick}
                callOnFindInPageClick={onShowClick}
                callOnEditClick={onEditClick}
                callOnInactiveClick={onInvalidClick}
                showGender
                tableData={persons}
                columns={columns}
                sortBy={sortBy}
                fetchElements={fetchPersonsInNeedOfCare}
                dataFormatter={(element: PersonInNeedOfCareAPI) => {
                    var date = new Date(element.effectiveDate);
                    element.effectiveDate = (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
                        (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
                        date.getFullYear();
                    if (element.person.dateOfBirth !== null) {
                        date = new Date(element.person.dateOfBirth);
                        element.person.dateOfBirth = (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
                            (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
                            date.getFullYear();
                    }
                    element.personName = element.person.lastname + ", " + element.person.firstname + (element.person.dateOfBirth !== null ? " *" + element.person.dateOfBirth : "")
                    element.addressString = element.address ? ((element.address.street !== "" && element.address.houseNumber !== null ? element.address.street + " " + element.address.houseNumber + " | " : "") + element.address.postalCode + " " + element.address.location) : "Unbekannt"
                    const elementValidty = getValidityValues().find((ele) => { return element.validity === ele.id; });
                    element.validityString = elementValidty ? elementValidty.name : "Keine Benutzerrolle"
                    return element;
                }}
            />
        </PageLayout>
    );
}

export default ListPersonsInNeedOfCare;
