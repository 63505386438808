import React from 'react';
import SelectComponent from '../../../../shared/components/select-component';
import { PersonInNeedOfCareSelect } from '../../../../models/person-in-need-of-care';
import { CaringPersonSelect } from '../../../../models/caring-person';
import AccordionComponent from '../../../../shared/components/accordion-component';
import { Group } from '@mui/icons-material';

type SelectProps = {
    savePushed: boolean
    disabled: boolean
    caringPersonValues: CaringPersonSelect[];
    personInNeedOfCareValues: PersonInNeedOfCareSelect[];
    caringPerson: CaringPersonSelect | null;
    setCaringPerson: (value: CaringPersonSelect | null) => void;
    personInNeedOfCare: PersonInNeedOfCareSelect | null;
    setPersonInNeedOfCare: (value: PersonInNeedOfCareSelect | null) => void;
    formError: boolean;
    validation?: boolean;

};

const PersonConnectionForm = ({
    savePushed,
    disabled,
    caringPersonValues,
    personInNeedOfCareValues,
    caringPerson,
    setCaringPerson,
    personInNeedOfCare,
    setPersonInNeedOfCare,
    formError,
    validation

}: SelectProps) => {
    return <div className='single-view-accordion'>
        <AccordionComponent
            defaultExpanded={true}
            label="Personenverknüpfung"
            startIcon={<Group></Group>}
            error={formError}
            validation={validation}
            content={
                <div className="flex flex-row single-view__form-row-width">
                    <SelectComponent
                        selectData={personInNeedOfCareValues}
                        tooltip="Es wurden noch keine Bedürftigen angelegt."
                        className="single-view__text-field"
                        label="Bedürftige/r "
                        personGenderSelect
                        value={personInNeedOfCare?.name || ""}
                        onChange={(value: String) => setPersonInNeedOfCare(personInNeedOfCareValues.find(({ name }) => name === value) || null)}
                        disabled={disabled}
                        error={savePushed && !personInNeedOfCare}
                    ></SelectComponent>
                    <SelectComponent
                        selectData={caringPersonValues}
                        tooltip="Es wurden noch keine Pflegepersonen angelegt."
                        className="single-view__text-field"
                        label="Pflegeperson "
                        personGenderSelect
                        value={caringPerson?.name || ""}
                        onChange={(value: String) => setCaringPerson(caringPersonValues.find(({ name }) => name === value) || null)}
                        disabled={disabled}
                        error={savePushed && !caringPerson}
                    ></SelectComponent>
                </div>
            }
        />
    </div >
}
export default PersonConnectionForm;