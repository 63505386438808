import { resourceAxiosInstance } from "../../config/axios.config";
import { AidConfigAPI, AidConfigAPIRequest, CalculationBasisChangerAPIRequest, CalculationRunAPI } from "../../models/aid";

export const fetchAidConfig = (institutionId: string) =>
    resourceAxiosInstance.get<AidConfigAPI>(`/aid/${institutionId}/configuration`, {
        cache: false,
    });

export const updateAidConfig = (aidConfig: Partial<AidConfigAPIRequest>) =>
    resourceAxiosInstance.patch<AidConfigAPIRequest>(
        `/aid/${aidConfig.institutionId}/configuration`,
        aidConfig,
        {
            cache: false,
        }
    );

export const startCalculationRuns = (institutionId: string) =>
    resourceAxiosInstance.post(`/aid/${institutionId}/calc-run`, {
        cache: false,
    });

export const fetchAllCalculationRuns = (institutionId: string) =>
    resourceAxiosInstance.get<CalculationRunAPI[]>(`/aid/${institutionId}/calc-run`, {
        cache: false,
    });

export const startCalculationBasisChanger = (institutionId: string, changeParameters: CalculationBasisChangerAPIRequest) =>
    resourceAxiosInstance.post(`/aid/${institutionId}/calculation-basis-changer`, changeParameters, {
        cache: false,
    });

export const fetchAllCalculationBasisChanger = (institutionId: string) =>
    resourceAxiosInstance.get<[]>(`/aid/${institutionId}/calculation-basis-changer`, {
        cache: false,
    });