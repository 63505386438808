import { useEffect } from 'react';
import PageLayout from "../../../../shared/components/page-layout";
import "../../details.css";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    InstitutionAPIRequest,
    InstitutionTypes,
    getInstitutionTypesWithoutSystem,
} from "../../../../models/institution";
import { userInformationRequest } from "../../../../redux/auth.reducer";
import SubmitButtons from "../../../../shared/components/standard-actions/submit-buttons/submit-button";
import {
    createInstitution,
    fetchInstitution,
    updateInstitution,
    fetchInstitutions
} from "../../../../shared/requests/institution.requests";
import SelectComponent from "../../../../shared/components/select-component";
import { InstitutionSelect } from '../../../../models/institution';
import { AddressAPIRequest } from '../../../../models/address';
import AddressForm from '../../../../shared/components/address-form/address-form';
import { AppDispatch, RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { } from "../../../../redux/auth.reducer";
import TextFieldComponent from '../../../../shared/components/text-field-component';
import { isValidPostalcode, onlyDigits } from '../../../../helper/validation';
import { CountryData, getCountries } from '../../../../shared/components/select-component-country';
import AccordionComponent from '../../../../shared/components/accordion-component';
import { Business } from '@mui/icons-material';
import { clearHistory } from '../../../../models/history';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { deDE } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/de';
import { Dayjs } from 'dayjs';

function Institution() {
    const [institutionTypes, setInstitutionTypes] = useState<InstitutionTypes[]>([]);
    const [institutions, setInstitutions] = useState<InstitutionSelect[]>([]);

    const [name, setName] = useState<string>("");
    const [institutionType, setInstitutionType] = useState<InstitutionTypes | null>(null);
    const [successsor, setSuccesssor] = useState<InstitutionSelect | null>(null);
    const [companyNumber, setCompanyNumber] = useState<string>("");
    const [earliestCalculationYear, setEarliestCalculationYear] = useState<Dayjs | null>(null);
    //Address Data
    const [street, setStreet] = useState<string>("");
    const [houseNumber, setHouseNumber] = useState<number | null>(null);
    const [country, setCountry] = useState<CountryData | null>(null);
    const [postalCode, setPostalCode] = useState<string>("");
    const [location, setLocation] = useState<string>("");
    const [poBox, setPOBox] = useState<string>("");
    const [poBoxPostalCode, setPOBoxPostalCode] = useState<string>("");
    const [addressSupplement, setAddressSupplement] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [fax, setFax] = useState<string>("");
    const [email, setEmail] = useState<string>("");

    const [savePushed, setSavePushed] = useState<boolean>(false);
    const errorInstitution: boolean = name === "" || institutionType?.name === "" || (companyNumber === "" || !onlyDigits(companyNumber) || companyNumber.length !== 8);
    const errorAddress: boolean = postalCode === "" || location === "" || country === null || !isValidPostalcode(postalCode, country) || country === null;
    const errorInput: boolean = errorInstitution || errorAddress;
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { id } = useParams();
    const { user } = useSelector(({ authReducer }: RootState) => authReducer);



    const goBackToOverview = () => {
        dispatch(userInformationRequest()).then(() => {
            if (user?.userRole === "USER_ROLE_SYSTEM_ADMIN") {
                navigate(`/institution`);
            } else {
                navigate(`/home`);
            }
        })

    }

    useEffect(() => {
        clearHistory();
        fetchInstitutions().then((response) => {
            let institutions = response.data;
            let institutionsSelect: InstitutionSelect[] = [];
            let emptySuccessor: InstitutionSelect = {
                id: String(""),
                name: "Kein Nachfolger",
                realValue: -1,
                type: ""
            }
            setSuccesssor(emptySuccessor);
            institutionsSelect.push(emptySuccessor)
            institutions.forEach((institution) => {
                institutionsSelect.push({
                    id: String(institution.id),
                    name: institution.name,
                    realValue: +institution.id,
                    type: institution.type
                })
            })
            setInstitutions(institutionsSelect);
        })
        setInstitutionTypes(getInstitutionTypesWithoutSystem());
        if (id) {
            fetchInstitution(id).then((response) => {
                setName(response.data.name);
                setCompanyNumber(response.data.companyNumber)
                const element = getInstitutionTypesWithoutSystem().find((element) => { return response.data.type === element.id; });
                if (element) {
                    setInstitutionType(element);
                }
                if (response.data.successor != null) {
                    setSuccesssor({
                        id: response.data.successor!.id,
                        name: response.data.successor!.name,
                        realValue: +response.data.successor!.id,
                        type: response.data.successor!.type
                    })
                }
                if (response.data.address != null) {
                    setStreet(response.data.address.street);
                    setHouseNumber(response.data.address.houseNumber);
                    const elementCountry = getCountries().find((element) => { return response.data.address?.country === element.value; });
                    if (elementCountry) {
                        setCountry(elementCountry)
                    }
                    setPostalCode(response.data.address.postalCode);
                    setLocation(response.data.address.location);
                    setPOBox(response.data.address.poBox);
                    setPOBoxPostalCode(response.data.address.poBoxPostalCode);
                    setAddressSupplement(response.data.address.addressSupplement);
                    setPhoneNumber(response.data.address.phoneNumber);
                    setFax(response.data.address.fax);
                    setEmail(response.data.address.email);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUserTitle = (): string => {
        const createUserTitle = "Institution erstellen";
        const editUserTitle = "Institution bearbeiten";
        if (id) {
            return editUserTitle;
        }
        return createUserTitle;
    };

    const getSavingButtonText = (): string => {
        const createText = "Erstellen";
        const editText = "Speichern";
        if (id) {
            return editText;
        }
        return createText;
    };

    const handleInputChangeInstitutionType = (value: string) => {
        setInstitutionType(institutionTypes.find(({ name }) => name === value) || null);
    };

    const handleInputChangeSuccessor = (value: string) => {
        setSuccesssor(institutions.find(({ name }) => name === value) || null);
    };

    const filterOptions = (value: InstitutionSelect[]) => {
        return value.filter((insSelect) => insSelect.id !== id)
    }



    const save = () => {
        var today = new Date();
        const effectiveDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        const coun = country ? country.value : "";
        const address: AddressAPIRequest = {
            effectiveDate,
            houseNumber,
            country: coun,
            postalCode,
            poBox,
            poBoxPostalCode,
            addressSupplement,
            location,
            phoneNumber,
            fax,
            email,
            street,
        };
        const type = institutionType ? institutionType.id : ""
        const successorID = (successsor && successsor.id !== "") ? successsor.realValue : null;
        const calcYear = earliestCalculationYear && !id ? earliestCalculationYear.year() : null;
        const institution: InstitutionAPIRequest = {
            id: id ? id : "",
            type,
            name,
            successorID,
            companyNumber,
            address,
            earliestCalculationYear: calcYear
        };
        setSavePushed(true);
        if (!errorInput) {
            if (id) {
                updateInstitution(institution).then(goBackToOverview).catch(console.error);
            } else {
                createInstitution(institution).then(goBackToOverview).catch(console.error);
            }
        }
    };

    const actions = (
        <SubmitButtons
            submitText={getSavingButtonText()}
            cancelText="Abbrechen"
            onClickSubmit={save
            }
            onClickCancel={goBackToOverview}
        />
    );

    return (
        <PageLayout title={getUserTitle()} actions={actions}>
            <div className="single-view">
                <div className='single-view-accordion'>
                    <AccordionComponent
                        defaultExpanded
                        label='Institutionsdaten'
                        error={errorInstitution}
                        validation
                        startIcon={<Business />}
                        content={
                            <div>
                                <div className="flex flex-row single-view__form-row-width">
                                    <TextFieldComponent
                                        required={true}
                                        id="name"
                                        label="Name der Institution"
                                        value={name}
                                        type='text'
                                        error={name === "" && savePushed}
                                        helperText={
                                            name === "" && savePushed
                                                ? "Der Name darf nicht leer sein!"
                                                : ""
                                        }
                                        disabled={false}
                                        setValueString={setName}
                                    />
                                    <TextFieldComponent
                                        required={true}
                                        id="companyNumber"
                                        label="Betriebsnummer"
                                        value={companyNumber}
                                        type='text'
                                        error={(companyNumber === "" || !onlyDigits(companyNumber) || companyNumber.length !== 8) && savePushed}
                                        helperText={
                                            (companyNumber === "" || !onlyDigits(companyNumber) || companyNumber.length !== 8) && savePushed
                                                ? "Die Betriebsnummer muss aus 8 Zahlen bestehen!"
                                                : ""
                                        }
                                        disabled={false}
                                        setValueString={setCompanyNumber}
                                    />
                                </div>
                                <div className="flex flex-row single-view__form-row-width">
                                    <SelectComponent
                                        selectData={institutionTypes}
                                        tooltip="Es wurden noch keine Institutions-Typen angelegt."
                                        className="single-view__text-field"
                                        label="Institutions-Typ"
                                        value={institutionType?.name || ""}
                                        onChange={handleInputChangeInstitutionType}
                                        disabled={user?.userRole !== "USER_ROLE_SYSTEM_ADMIN"}
                                        error={savePushed && !institutionType}
                                    ></SelectComponent>
                                    <SelectComponent
                                        selectData={filterOptions(institutions)}
                                        tooltip="Es wurden noch keine Institutionen angelegt."
                                        className="single-view__text-field"
                                        label="Nachfolger Institution"
                                        value={successsor?.name || ""}
                                        onChange={handleInputChangeSuccessor}
                                        disabled={false}
                                        error={false}
                                    ></SelectComponent>
                                </div>
                                {id === undefined && institutionType !== null && institutionType.id === "INSTITUTION_TYPE_AID" &&
                                    <div className="flex flex-row single-view__form-row-width">
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale="de"
                                            localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}
                                        >
                                            <DatePicker
                                                disabled={id !== undefined}
                                                value={earliestCalculationYear}
                                                label={'Erstes Berechnungsjahr'}
                                                views={['year']}
                                                onChange={(value) => {
                                                    setEarliestCalculationYear(value)
                                                }}
                                                sx={{
                                                    backgroundColor: "#EEEEEE",
                                                    color: '#2D3335',
                                                }}
                                            />
                                        </LocalizationProvider>
                                        <div className="single-view__empty-form-width"></div>
                                    </div>
                                }
                            </div>
                        }
                    />
                </div>

                <AddressForm
                    savePushed={savePushed}
                    disabled={false}
                    street={street}
                    setStreet={setStreet}
                    houseNumber={houseNumber}
                    setHouseNumber={setHouseNumber}
                    postalCode={postalCode}
                    setPostalCode={setPostalCode}
                    location={location}
                    setLocation={setLocation}
                    country={country}
                    setCountry={setCountry}
                    poBox={poBox}
                    setPOBox={setPOBox}
                    poBoxPostalCode={poBoxPostalCode}
                    setPOBoxPostalCode={setPOBoxPostalCode}
                    addressSupplement={addressSupplement}
                    setAddressSupplement={setAddressSupplement}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    fax={fax}
                    setFax={setFax}
                    email={email}
                    setEmail={setEmail}
                    useAddress={true}
                    formError={errorAddress}
                    validation
                />

            </div>
        </PageLayout>
    );
}

export default Institution;