import { AddressAPI, Address, AddressAPIRequest } from "../models/address";
export interface Institution {
    id: string;
    type: string;
    name: string;
    address: Address
    active: boolean
    companyNumber: string;
    earliestCalculationYear: number | null;
    latestRunDate: string | null;
}

export type InstitutionAPI = {
    id: string;
    type: string;
    name: string;
    successor: InstitutionAPI | null;
    address: AddressAPI;
    successorID: string;
    typeString: string;
    addressString: string;
    active: boolean;
    companyNumber: string;
    earliestCalculationYear: number | null;
    latestRunDate: string | null;
};

export type InstitutionAPIRequest = {
    id: string;
    type: string;
    name: string;
    successorID: number | null
    address: AddressAPIRequest | null;
    companyNumber: string;
    earliestCalculationYear: number | null;
};

export type InstitutionSelect = {
    id: string;
    name: string;
    realValue: number;
    type: string;
};

export interface InstitutionTypes {
    id: string,
    name: string
}

export const getInstitutionTypesWithoutSystem = () => {
    return [
        {
            id: "INSTITUTION_TYPE_AID",
            name: "Beihilfestelle"
        },
        {
            id: "INSTITUTION_TYPE_PRIVATE_HEALTH_INSURANCE",
            name: "Private Krankenversicherung"
        },
        {
            id: "INSTITUTION_TYPE_STATUTORY_HEALTH_INSURANCE",
            name: "Gesetzliche Krankenversicherung"
        },
        {
            id: "INSTITUTION_TYPE_PENSION_INSTITUTION",
            name: "Versorgungseinrichtung"
        },
    ]
}

export type InstitutionSort = Pick<
    InstitutionAPI,
    "id" | "name" | "type"
>;

export type InstitutionShortKey = keyof Institution;