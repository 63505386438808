import { Button } from "@mui/material";
import { useEffect, useRef, useState } from 'react';
import "../details.css"
import { ColumnDefinitionType } from "../../../shared/components/crud-table/crud-table";
import { Sort } from "../../../hooks/useSort.hook";
import { SortingDirection } from "../../../enums/sort-by";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { } from "../../../redux/auth.reducer";
import PageLayout from "../../../shared/components/page-layout";
import { Calculate, Update } from "@mui/icons-material";
import { clearHistory } from "../../../models/history";
import { CalculationBasisChangerAPIRequest, CalculationRunAPI, CalculationRunSort } from "../../../models/aid";
import TextFieldComponent from "../../../shared/components/text-field-component";
import AccordionComponent from "../../../shared/components/accordion-component";
import ConfirmCancelDialog from "../../../shared/components/standard-actions/dialogs/confirm-cancel-dialog";
import { formatDateString } from "../../../helper/formatter";
import { startCalculationBasisChanger } from "../../../shared/requests/aid-request";
import { dateBeforeOrSameAsCurrentDate } from "../../../helper/validation";

function CalculationBasisChanger() {
    const [effectiveDate, setEffectiveDate] = useState<string>("");
    const [percentage, setPercentage] = useState<number>(0);
    const [requestPushed, setRequestPushed] = useState<boolean>(false);
    const [externalModified, setExternalModified] = useState<boolean>(false);
    const [showConfirmStartRunDialog, setShowConfirmStartRunDialog] = useState<boolean>(false);
    const currentDate = new Date();
    const isInitRender = useRef(true);
    const errorStartRunValues = effectiveDate === "" || !dateBeforeOrSameAsCurrentDate(effectiveDate) || percentage < -11 || percentage > 11;
    const { user } = useSelector(({ authReducer }: RootState) => authReducer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (isInitRender.current && user) {
            clearHistory();
            isInitRender.current = false;
        }
    }, [user]);

    const columns: ColumnDefinitionType<CalculationRunAPI, keyof CalculationRunAPI>[] = [
        {
            key: "startString",
            header: "Start des Laufs",
        },
        {
            key: "endString",
            header: "Ende des Laufs",
        },
        {
            key: "statusString",
            header: "Status",
        },
        {
            key: "startUserString",
            header: "Start durch User",
        },
        {
            key: "amountCalculatedCaringPerson",
            header: "Anzahl berechneter Pflegepersonen",
        },
        {
            key: "rvString",
            header: "Gesamtbeitrag (D)RV",
        },
        {
            key: "abvString",
            header: "Gesamtbeitrag ABV",
        },
        {
            key: "avString",
            header: "Gesamtbeitrag AV",
        },
    ]

    const [sortBy] = useState<Sort<CalculationRunSort>>({
        key: "startString",
        direction: SortingDirection.Descending,
    });

    const onStartRunClicked = () => {
        if (!errorStartRunValues) {
            setShowConfirmStartRunDialog(true)
            setRequestPushed(false)
        } else {
            setRequestPushed(true)
        }
    }

    const onStartRunCancel = () => {
        setShowConfirmStartRunDialog(false)
    }

    const onStartRunSubmit = () => {
        if (user) {
            const changeParameters: CalculationBasisChangerAPIRequest = {
                effectiveDate: effectiveDate,
                percentageChange: percentage
            }
            startCalculationBasisChanger(user.institution.id, changeParameters).then(() => {
                setShowConfirmStartRunDialog(false)
                setExternalModified(true)
            });
        }
    }




    return <PageLayout title={"Berechnungsgrundlagen aktualisieren"}>
        <ConfirmCancelDialog
            dialogTitle="Starten einer Berechnungsgrundlagen Aktualisierung"
            dialogInfo={`Sie veranlassen die Aktualisierungen der Abschlagswerte um ${percentage} Prozent zum ${formatDateString(effectiveDate)}. Wollen Sie dies durchführen? Der Vorgang kann nicht rückgängig gemacht werden!`}
            onClickCancel={onStartRunCancel}
            onClickSubmit={onStartRunSubmit}
            showDialog={showConfirmStartRunDialog}

        />
        <div className="single-view">
            <div className="flex flex-column single-view-person__data-container">
                <h1>Diese Seite sollte aktuell ignoriert werden, da die Funktion zum Durchführen der Aktualisierung von Berechnungsgrundlagen noch nicht vollständig funktioniert und die Ansicht über die vorhergegangen Läufe noch nicht implementiert ist!</h1>
                <div className="flex flex-row single-view__form-row-width">
                    <TextFieldComponent
                        required={true}
                        id="effectiveDate"
                        label="Stichtag der Aktualisierung"
                        type='date'
                        value={effectiveDate}
                        error={(effectiveDate === "" || !dateBeforeOrSameAsCurrentDate(effectiveDate)) && requestPushed}
                        helperText={
                            (effectiveDate === "" || !dateBeforeOrSameAsCurrentDate(effectiveDate)) && requestPushed
                                ? `Der Stichtag der Aktualisierung darf nicht leer sein und darf maximal das aktuelle Tagedatum sein!`
                                : ""
                        }
                        disabled={false}
                        setValueString={setEffectiveDate}
                    />
                    <TextFieldComponent
                        required={true}
                        id="percentage"
                        label="Prozentsatz der Veränderung"
                        type='number'
                        value={percentage}
                        error={(percentage < -11 || percentage > 11) && requestPushed}
                        helperText={
                            (percentage < -11 || percentage > 11) && requestPushed
                                ? "Der Prozentsatz der Veränderung darf nur zwischen -11 und 11 liegen!"
                                : ""
                        }
                        disabled={false}
                        setValueNumber={setPercentage}
                    />
                </div>
                <div className="flex flex-row single-view__form-row-width">
                    <div className="single-view__empty-form-width"></div>
                    <Button startIcon={<Update />} variant="contained" size="large" onClick={onStartRunClicked}
                        sx={{
                            height: 50,
                            width: 300,
                            color: "#001D53",
                            background: "#D4E7FF",
                            ':hover': {
                                background: 'primary.main', // theme.palette.primary.main
                                color: 'white',
                            },
                        }}>Durchführen</Button>
                </div>
            </div>
        </div>


        <div className="single-view">
            <div className='single-view-accordion'>
                <AccordionComponent
                    defaultExpanded={true}
                    label="Berechnungsgrundlagen Änderungsläufe"
                    startIcon={<Calculate></Calculate>}
                    validation={false}
                    error={false}
                    content={
                        <div>Die Tabelle zum Anzeigen der Berechnungsgrundlagen Änderungläufe entsteht noch!</div>
                    }></AccordionComponent>
            </div>
        </div>
    </PageLayout>
}

export default CalculationBasisChanger;