
import { Button } from "@mui/material";

export interface SubmitProps {
    submitText: string;
    cancelText: string;
    submitLink?: string;
    cancelLink?: string;
    isValid?: boolean;
    onClickSubmit?: () => void;
    onClickCancel?: () => void;
}

function SubmitButtonsDialog({
    submitText,
    cancelText,
    onClickCancel,
    onClickSubmit,
}: SubmitProps) {
    return (
        <>
            <Button variant="contained" onClick={onClickSubmit}>
                {submitText}
            </Button>
            <Button variant="contained" onClick={onClickCancel}>
                {cancelText}
            </Button>
        </>
    );
}

export default SubmitButtonsDialog;
