import React from 'react';
import { useEffect, useRef, useState } from "react";
import { SortingDirection } from "../../../../enums/sort-by";
import { InstitutionAPI, InstitutionSort, getInstitutionTypesWithoutSystem } from "../../../../models/institution";
import CrudTable, {
    ColumnDefinitionType,
} from "../../../../shared/components/crud-table/crud-table";
import {
    changeActiveOfInstitution,
    deleteInstitution,
    fetchInstitutions,
} from "../../../../shared/requests/institution.requests";
import PageLayout from "../../../../shared/components/page-layout";
import { Sort } from "../../../../hooks/useSort.hook";
import { useNavigate } from 'react-router-dom';
import ConfirmCancelDialog from '../../../../shared/components/standard-actions/dialogs/confirm-cancel-dialog';
import { clearHistory } from '../../../../models/history';

function ListInstitutions() {
    const [sortBy] = useState<Sort<InstitutionSort>>({
        key: "id",
        direction: SortingDirection.Descending,
    });

    const [institutions, setInstitutions] = useState<InstitutionAPI[]>([]);
    const [externalModified, setExternalModified] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [showInstitutionInvalidDialog, setShowInstitutionInvalidDialog] = useState<boolean>(false);
    const [selectedInstitution, setSelectedInstitution] = useState<InstitutionAPI | null>(null);
    const [entityName, setEntityName] = useState<string>("");
    const [clickedId, setClickedId] = useState<string>("");
    const isInitRender = useRef(true);
    const navigate = useNavigate();

    const onAddClick = () => {
        navigate('add');
    }
    const onEditClick = (entity: InstitutionAPI) => {
        navigate(`edit/${entity.id}`);
    }
    const onDeleteClick = (entity: InstitutionAPI) => {
        setEntityName(entity.name)
        setClickedId(entity.id)
        setShowDeleteDialog(true)
    }
    const onDeleteCancel = () => {
        setShowDeleteDialog(false)
    }

    const onDeleteSubmit = () => {
        deleteInstitution(clickedId ?? "").then(() => {
            setShowDeleteDialog(false)
            setExternalModified(true)
        });
    }

    const onInvalidClick = (entity: InstitutionAPI) => {
        setEntityName(entity.name)
        setClickedId(entity.id)
        setSelectedInstitution(entity)
        setShowInstitutionInvalidDialog(true)
    }

    const onInvalidCancel = () => {
        setShowInstitutionInvalidDialog(false)
    }

    const onInvalidSubmit = () => {
        changeActiveOfInstitution(clickedId ?? "").then(() => {
            setShowInstitutionInvalidDialog(false)
            setExternalModified(true)
        });
    }


    useEffect(() => {
        clearHistory();
        if (isInitRender.current) {
            fetchInstitutions().then((response) => {
                setInstitutions(response.data);
            });
            isInitRender.current = false;
        }
    });

    const columns: ColumnDefinitionType<InstitutionAPI, keyof InstitutionAPI>[] = [
        {
            key: "id",
            header: "ID",
        },

        {
            key: "name",
            header: "Name",
        },
        {
            key: "typeString",
            header: "Institutions-Typ",
        },
        {
            key: "successorID",
            header: "Nachfolger",
        },
        {
            key: "addressString",
            header: "Anschrift",
        }
    ];
    return (
        <PageLayout title={"Übersicht Institutionen"}>
            <ConfirmCancelDialog
                showDialog={showDeleteDialog}
                dialogTitle={"Löschen einer Institution"}
                dialogInfo={`Soll die Institution { ${entityName} } wirklich gelöscht werden?`}
                onClickCancel={onDeleteCancel}
                onClickSubmit={onDeleteSubmit}
            />
            <ConfirmCancelDialog
                showDialog={showInstitutionInvalidDialog && selectedInstitution !== null}
                dialogTitle={`${selectedInstitution?.active ? "Ungültig" : "Gültig"} setzen einer Institution`}
                dialogInfo={`Soll die Institution { ${entityName} } wirklich ${selectedInstitution?.active ? "Ungültig" : "Gültig"} gesetzt werden?`}
                onClickCancel={onInvalidCancel}
                onClickSubmit={onInvalidSubmit}
            />
            <CrudTable
                tableData={institutions}
                callOnAddClick={onAddClick}
                callOnEditClick={onEditClick}
                callOnInactiveClick={onInvalidClick}
                callOnDeleteClick={onDeleteClick}
                externalModified={externalModified}
                setExternalModified={setExternalModified}
                columns={columns}
                sortBy={sortBy}
                fetchElements={fetchInstitutions}
                dataFormatter={(data: InstitutionAPI) => {
                    data.successorID = data.successor ? String(data.successor.name) : "Kein Nachfolger";
                    data.addressString = data.address ? ((data.address.street !== "" && data.address.houseNumber !== null ? data.address.street + " " + data.address.houseNumber + " | " : "") + data.address.postalCode + " " + data.address.location) : "Unbekannt"
                    const element = getInstitutionTypesWithoutSystem().find((element) => { return data.type === element.id; });
                    data.typeString = element ? element.name : "Kein Institutionstyp"
                    return data;
                }}
            />
        </PageLayout>
    );
}

export default ListInstitutions;
