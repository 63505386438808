import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    TextField,
} from "@mui/material";
import { useEffect, useState } from "react";

import "./verify-email.css";
import { Email } from "@mui/icons-material";
import ErrorDisplay from "../../../../shared/components/error-display";
import { useNavigate, useParams } from "react-router-dom";
import { sendEmailVerifyLinkRequest, verifyEmail } from "../../../../shared/requests/user.requests";
import { EmailAPI } from "../../../../models/user";

function VerifyEmail() {

    const [email, setEmail] = useState<string>("");
    const [savePushed, setSavePushed] = useState<boolean>(false);
    const [tokenValid, setTokenValid] = useState<boolean>(false);
    const { id } = useParams();
    const errorInput: boolean =
        email === "";

    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            verifyEmail(id).then(() => {
                setTokenValid(true);
                alert("Email wurde erfolgreich bestätigt!");
                navigateToLogin();
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigateToLogin = () => {
        navigate(`/login`);
    }

    const sendEmailVerificationLink = () => {
        setSavePushed(true);
        if (!errorInput && id) {
            const mail: EmailAPI = {
                email: email
            }
            sendEmailVerifyLinkRequest(mail).then(() => {
                alert("Link zum bestätigen der Email wurde erfolgreich gesendet!");
                navigateToLogin();
            });
        }
    };

    return (
        <>
            {(!tokenValid) &&
                <Card className="login-bg">
                    <CardContent className="flex flex-column login-width content-padding">
                        <ErrorDisplay>
                            <div className="login-image-container">
                                <img className="login-image" src="/assets/img/AidPlus_Logo_transparent.png" alt="AidPlus Icon" />
                            </div>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "flex-end",
                                    marginBottom: "0.5rem",
                                }}
                            >
                                <Email sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                                <TextField
                                    sx={{ width: "100%" }}
                                    type="email"
                                    label="Email"
                                    variant="standard"
                                    onChange={(event) => setEmail(event.target.value)}
                                    error={savePushed && (email === "")}
                                    helperText={
                                        savePushed && email === "" ? "Die Email Adresse darf nicht leer sein!" : ""
                                    }
                                />
                            </Box>
                            <a className="forgot-password" href="/login">Einloggen?</a>
                        </ErrorDisplay>
                    </CardContent>
                    <CardActions className="flex flex-end">
                        <Button variant="contained" onClick={sendEmailVerificationLink}>
                            Link zum bestätigen versenden!
                        </Button>
                    </CardActions>
                </Card>
            }
        </>
    );
}

export default VerifyEmail;
