import { useEffect } from 'react';
import PageLayout from "../../../../shared/components/page-layout";
import "../../details.css";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { } from "../../../../redux/auth.reducer";
import SubmitButtons from "../../../../shared/components/standard-actions/submit-buttons/submit-button";

import SelectComponent from "../../../../shared/components/select-component";
import { AidConfigAPIRequest, AidConfigAutoImport, AidLevelAPI, getAutoImportType, InsuranceRecordAPI } from '../../../../models/aid';
import { fetchAidConfig, updateAidConfig } from '../../../../shared/requests/aid-request';
import { Divider, IconButton, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Add, CheckCircleOutline, Delete, NotInterested } from '@mui/icons-material';
import TextFieldComponent from '../../../../shared/components/text-field-component';
import { clearHistory } from '../../../../models/history';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { deDE } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/de';
import { Dayjs } from 'dayjs';

function AidConfig() {
    //Config Data
    const [autoImport, setAutoImport] = useState<AidConfigAutoImport | null>(null);
    const [aidLevels, setAidLevels] = useState<AidLevelAPI[]>([]);
    const [pensionRecords, setPensionRecords] = useState<InsuranceRecordAPI[]>([]);
    const [unemploymentRecords, setUnemploymentRecords] = useState<InsuranceRecordAPI[]>([]);
    const [aidLevelAdd, setAidLevelAdd] = useState<number>(0);
    const [rvRecordYear, setRvRecordYear] = useState<Dayjs | null>(null);
    const [rvRecordPercentage, setRvRecordPercentage] = useState<number>(0.0);
    const [avRecordYear, setAvRecordYear] = useState<Dayjs | null>(null);
    const [avRecordPercentage, setAvRecordPercentage] = useState<number>(0.0);
    const [latestRunDate, setLatestRunDate] = useState<Date | null>(null);
    //Systemdata
    const [savePushed, setSavePushed] = useState<boolean>(false);
    const errorInput: boolean =
        autoImport === null;
    const navigate = useNavigate();
    const { id } = useParams();

    const goBackToOverview = () => {
        navigate(`/home`);
    }

    useEffect(() => {
        clearHistory();
        if (id) {
            fetchAidConfig(id).then((response) => {
                const element = getAutoImportType().find((element) => { return response.data.autoImport === element.realValue; });
                if (element) {
                    setAutoImport(element);
                }
                setAidLevels(response.data.aidLevels.sort((a, b) => a.percentage > b.percentage ? 1 : -1));
                setPensionRecords(response.data.pensionRecords.sort((a, b) => a.recordYear > b.recordYear ? 1 : -1));
                setUnemploymentRecords(response.data.unemploymentRecords.sort((a, b) => a.recordYear > b.recordYear ? 1 : -1));
                if (response.data.institution.latestRunDate != null) {
                    setLatestRunDate(new Date(response.data.institution.latestRunDate))
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUserTitle = (): string => {
        const createUserTitle = "Beihilfe Konfiguration erstellen";
        const editUserTitle = "Beihilfe Konfiguration bearbeiten";
        if (id) {
            return editUserTitle;
        }
        return createUserTitle;
    };

    const getSavingButtonText = (): string => {
        const createText = "Erstellen";
        const editText = "Speichern";
        if (id) {
            return editText;
        }
        return createText;
    };



    const save = () => {
        const autoImp = autoImport ? autoImport.realValue : false;
        const config: AidConfigAPIRequest = {
            institutionId: id ? id : "",
            autoImport: autoImp,
            aidLevels: aidLevels,
            pensionRecords,
            unemploymentRecords
        };

        setSavePushed(true);
        if (!errorInput) {
            if (id) {
                updateAidConfig(config).then(goBackToOverview).catch(console.error);
            }
        }
    };

    const actions = (
        <SubmitButtons
            submitText={getSavingButtonText()}
            cancelText="Abbrechen"
            onClickSubmit={save
            }
            onClickCancel={goBackToOverview}
        />
    );

    const deleteOrChangeValidAidLevel = (aLevel: AidLevelAPI) => {
        const aLevels: AidLevelAPI[] = []
        aidLevels.forEach((val) => {
            if (aLevel.canBeDeleted === true) {
                if (val.percentage !== aLevel.percentage) {
                    aLevels.push(val)
                }
            } else {
                if (val.percentage === aLevel.percentage) {
                    val.validity = (val.validity === "VALIDITY_VALID" ? "VALIDITY_INVALID" : "VALIDITY_VALID");
                }
                aLevels.push(val);
            }
        })
        setAidLevels(aLevels.sort((a, b) => a.percentage > b.percentage ? 1 : -1))
    }

    const deletePensionInsuranceRecord = (record: InsuranceRecordAPI) => {
        if (!latestRunDate || (latestRunDate && (record.recordYear > latestRunDate.getFullYear() || (record.recordYear > latestRunDate.getFullYear() && latestRunDate.getMonth() + 1 === 1)))) {
            const records: InsuranceRecordAPI[] = []
            pensionRecords.forEach((val) => {
                if (val !== record) {
                    records.push(val)
                }
            })
            setPensionRecords(records)
        }
    }

    const deleteUnemploymentInsuranceRecord = (record: InsuranceRecordAPI) => {
        if ((!latestRunDate) || (latestRunDate && (record.recordYear > latestRunDate.getFullYear() || (record.recordYear > latestRunDate.getFullYear() && latestRunDate.getMonth() + 1 === 1)))) {
            const records: InsuranceRecordAPI[] = []
            unemploymentRecords.forEach((val) => {
                if (val !== record) {
                    records.push(val)
                }
            })
            setUnemploymentRecords(records)
        }
    }

    const addAndSortAidLevels = () => {
        if (aidLevelAdd >= 0 && aidLevelAdd <= 100) {
            let contains: boolean = false
            let aLevels: AidLevelAPI[] = aidLevels;
            aLevels.forEach((val) => {
                if (val.percentage === aidLevelAdd) {
                    contains = true;
                }
            })
            if (!contains) {
                aLevels.push({
                    amountPersonInNeedOfCare: 0,
                    id: "-1",
                    percentage: aidLevelAdd,
                    validity: "VALIDITY_VALID",
                    canBeDeleted: true
                })
            }
            setAidLevels(aLevels.sort((a, b) => a.percentage > b.percentage ? 1 : -1))
        }
        setAidLevelAdd(0);
    }

    const addAndSortPensionInsuranceRecord = () => {
        if (rvRecordYear !== null && rvRecordPercentage >= 0 && rvRecordPercentage <= 100 && (latestRunDate == null || (rvRecordYear.year() > latestRunDate?.getFullYear()))) {
            const rvRecords: InsuranceRecordAPI[] = pensionRecords;
            const recordYear = rvRecordYear ? rvRecordYear.year() : 0
            const rvRecord: InsuranceRecordAPI = {
                recordYear,
                percentage: rvRecordPercentage,
                id: "0"
            }
            let notIn = true;
            rvRecords.forEach((element) => {
                if (rvRecordYear.year() === element.recordYear) {
                    notIn = false;
                    element.percentage = rvRecordPercentage;
                }
            })
            if (notIn) {
                rvRecords.push(rvRecord)
            }
            setPensionRecords(rvRecords.sort((a, b) => a.recordYear > b.recordYear ? 1 : -1))
        }
        setRvRecordYear(null);
        setRvRecordPercentage(0);
    }

    const addAndSortUnemploymentInsuranceRecord = () => {
        if (avRecordYear !== null && avRecordPercentage >= 0 && avRecordPercentage <= 100 && (latestRunDate == null || (avRecordYear.year() > latestRunDate?.getFullYear()))) {
            const avRecords: InsuranceRecordAPI[] = unemploymentRecords;
            const recordYear = avRecordYear ? avRecordYear.year() : 0
            const avRecord: InsuranceRecordAPI = {
                recordYear,
                percentage: avRecordPercentage,
                id: "0"
            }
            let notIn = true;
            avRecords.forEach((element) => {
                if (avRecordYear.year() === element.recordYear) {
                    notIn = false;
                    element.percentage = avRecordPercentage;
                }
            })
            if (notIn) {
                avRecords.push(avRecord)
            }
            setUnemploymentRecords(avRecords.sort((a, b) => a.recordYear > b.recordYear ? 1 : -1))
        }
        setAvRecordYear(null);
        setAvRecordPercentage(0);
    }

    return (
        <PageLayout title={getUserTitle()} actions={actions}>
            <div className="single-view">
                <div className="flex flex-row single-view__form-row-width">
                    <SelectComponent
                        selectData={getAutoImportType()}
                        tooltip="Es wurden noch keine Import-Typen angelegt."
                        className="single-view__text-field"
                        label="Automatisches Importieren"
                        value={autoImport?.name || ""}
                        onChange={(value: string) => setAutoImport(getAutoImportType().find(({ name }) => name === value) || null)}
                        disabled={false}
                        error={savePushed && !autoImport}
                    ></SelectComponent>
                </div>
                <div className="flex flex-row single-view__space-between">
                    <div className="flex flex-column single-view__list">
                        <h4>Beihilfestufen</h4>
                        <i className='info-text'>Es können nur Beihilfestufen gelöscht werden,<br></br> die für keine/n Bedürftige/n genutzt werden! </i>
                        <List
                            sx={{
                                width: '100%',
                                height: "400px",
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 500,
                                '& ul': { padding: 0 },
                            }}
                            aria-label="aid levels"
                        >
                            <TextFieldComponent
                                required={false}
                                id="aidLevelAdd"
                                label="Neue Beihilfestufe in %"
                                value={aidLevelAdd}
                                error={aidLevelAdd < 0 || aidLevelAdd > 100}
                                helperText={aidLevelAdd < 0 || aidLevelAdd > 100 ? "Die Beihilfestufe muss zwischen 0 und 100 liegen!" : ""}
                                type='number'
                                disabled={false}
                                setValueNumber={setAidLevelAdd}
                                endButton={
                                    <IconButton edge="end" color="primary" onClick={addAndSortAidLevels}>
                                        <Add></Add>
                                    </IconButton>
                                }
                            />
                            {aidLevels.map((value) => {
                                return <div>
                                    <ListItem disablePadding key={value.percentage} sx={{ background: "#EEEEEE" }}>
                                        <ListItemButton onClick={() => deleteOrChangeValidAidLevel(value)} sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <ListItemText primary={value.validity === "VALIDITY_VALID" ? <div>{value.percentage + "% - " + value.amountPersonInNeedOfCare + " Bedürftige - " + (value.validity === "VALIDITY_VALID" ? "Aktiviert" : "Deaktiviert")}</div>
                                                : <i>{value.percentage + "% - " + value.amountPersonInNeedOfCare + " Bedürftige - " + (value.validity === "VALIDITY_VALID" ? "Aktiviert" : "Deaktiviert")}</i>} sx={{
                                                    color: "#2D333B",

                                                }}
                                                primaryTypographyProps={{
                                                    fontWeight: 'bold',
                                                    fontSize: 14,
                                                }} />
                                            <IconButton edge="end">
                                                {value.canBeDeleted === true && <Delete></Delete>}
                                                {(value.canBeDeleted !== true && value.validity === "VALIDITY_VALID") && <NotInterested></NotInterested>}
                                                {(value.canBeDeleted !== true && value.validity === "VALIDITY_INVALID") && <CheckCircleOutline></CheckCircleOutline>}
                                            </IconButton >
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider></Divider>
                                </div>
                            })}
                        </List>
                    </div>
                    <div className="flex flex-column single-view__list">
                        <h4>RV-Sätze</h4>
                        <i className='info-text'>Der Jahressatz kann nur solange angepasst werden,<br></br> bis ein Berechnungslauf für das Jahr durchgeführt wurde! </i>
                        <List
                            sx={{
                                width: '100%',
                                height: "400px",
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 500,
                                '& ul': { padding: 0 },
                            }}
                            aria-label="rv records"
                        >
                            <div className='flex flex-row single-view__form-row-width'>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="de"
                                    localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}
                                >
                                    <DatePicker
                                        value={rvRecordYear}
                                        label={'Jahr'}
                                        views={['year']}
                                        onChange={(value) => {
                                            setRvRecordYear(value)
                                        }}
                                        sx={{
                                            "& .MuiOutlinedInput-root": { backgroundColor: "#EEEEEE" },
                                            color: '#2D3335',
                                        }}
                                    />
                                </LocalizationProvider>
                                <TextFieldComponent
                                    required={false}
                                    id="rvRecord"
                                    label="RV-Satz in %"
                                    value={rvRecordPercentage}
                                    error={rvRecordPercentage < 0 || rvRecordPercentage > 100}
                                    helperText={rvRecordPercentage < 0 || rvRecordPercentage > 100 ? "Der RV-Satz muss zwischen 0 und 100 liegen!" : ""}
                                    type='number'
                                    disabled={false}
                                    setValueNumber={setRvRecordPercentage}
                                    endButton={
                                        <IconButton edge="end" color="primary" onClick={addAndSortPensionInsuranceRecord}>
                                            <Add></Add>
                                        </IconButton>
                                    }
                                />
                            </div>
                            {pensionRecords.map((value) => {
                                return <div>
                                    <ListItem disablePadding key={value.recordYear} sx={{ background: "#EEEEEE" }}>
                                        <ListItemButton onClick={() => deletePensionInsuranceRecord(value)} sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <ListItemText primary={value.recordYear + " | " + value.percentage + "%"} sx={{
                                                color: "#2D333B",

                                            }}
                                                primaryTypographyProps={{
                                                    fontWeight: 'bold',
                                                    fontSize: 14,
                                                }} />
                                            {(!latestRunDate) || (latestRunDate && (value.recordYear > latestRunDate.getFullYear() || (value.recordYear > latestRunDate.getFullYear() && latestRunDate.getMonth() + 1 === 1)))
                                                ? <IconButton edge="end">
                                                    <Delete></Delete>
                                                </IconButton >
                                                : <div></div>}
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider></Divider>
                                </div>
                            })}
                        </List>
                    </div>
                    <div className="flex flex-column single-view__list">
                        <h4>AV-Sätze</h4>
                        <i className='info-text'>Der Jahressatz kann nur solange angepasst werden,<br></br> bis ein Berechnungslauf für das Jahr durchgeführt wurde! </i>
                        <List
                            sx={{
                                width: '100%',
                                height: "400px",
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 500,
                                '& ul': { padding: 0 },
                            }}
                            aria-label="av records"
                        >
                            <div className='flex flex-row single-view__form-row-width'>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="de"
                                    localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}>
                                    <DatePicker
                                        value={avRecordYear}
                                        label={'Jahr'}
                                        views={['year']}
                                        onChange={(value) => {
                                            setAvRecordYear(value)
                                        }}
                                        sx={{
                                            "& .MuiOutlinedInput-root": { backgroundColor: "#EEEEEE" },
                                            color: '#2D3335',
                                        }}
                                    />
                                </LocalizationProvider>
                                <TextFieldComponent
                                    required={false}
                                    id="avRecord"
                                    label="AV-Satz in %"
                                    value={avRecordPercentage}
                                    error={avRecordPercentage < 0 || avRecordPercentage > 100}
                                    helperText={avRecordPercentage < 0 || avRecordPercentage > 100 ? "Der AV-Satz muss zwischen 0 und 100 liegen!" : ""}
                                    type='number'
                                    disabled={false}
                                    setValueNumber={setAvRecordPercentage}
                                    endButton={
                                        <IconButton edge="end" color="primary" onClick={addAndSortUnemploymentInsuranceRecord}>
                                            <Add></Add>
                                        </IconButton>
                                    }
                                />
                            </div>
                            {unemploymentRecords.map((value) => {
                                return <div>
                                    <ListItem disablePadding key={value.recordYear} sx={{ background: "#EEEEEE" }}>
                                        <ListItemButton onClick={() => deleteUnemploymentInsuranceRecord(value)} sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <ListItemText primary={value.recordYear + " | " + value.percentage + "%"} sx={{
                                                color: "#2D333B",

                                            }}
                                                primaryTypographyProps={{
                                                    fontWeight: 'bold',
                                                    fontSize: 14,
                                                }} />
                                            {!latestRunDate || (latestRunDate && (value.recordYear > latestRunDate.getFullYear() || (value.recordYear > latestRunDate.getFullYear() && latestRunDate.getMonth() + 1 === 1)))
                                                ? <IconButton edge="end">
                                                    <Delete></Delete>
                                                </IconButton >
                                                : <div></div>}
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider></Divider>
                                </div>
                            })}
                        </List>
                    </div>
                </div>
            </div>
        </PageLayout>
    );
}

export default AidConfig;