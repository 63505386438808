import { Button } from "@mui/material";

export interface SubmitProps {
    submitText: string;
    onClickSubmit: () => void;
}

function SubmitButtonsErrorDialog({
    submitText,
    onClickSubmit,
}: SubmitProps) {
    return (
        <>
            <Button variant="contained" onClick={onClickSubmit}>
                {submitText}
            </Button>
        </>
    );
}

export default SubmitButtonsErrorDialog;
