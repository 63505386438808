export type StatusSelect = {
    id: string;
    name: string;
};

export type ValiditySelect = {
    id: string;
    name: string;
};

export const getStatusValues = () => {
    return [
        {
            id: "STATUS_FLOATING",
            name: "Schwebend"
        },
        {
            id: "STATUS_EFFECTIVE",
            name: "Bestandswirksam"
        },
    ]
}

export const getValidityValues = () => {
    return [
        {
            id: "VALIDITY_VALID",
            name: "Gültig"
        },
        {
            id: "VALIDITY_INVALID",
            name: "Ungültig"
        },
    ]
}

export type DateAPI = {
    effectiveDate: string;
};