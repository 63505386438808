import { resourceAxiosInstance } from "../../config/axios.config";
import { CalculationCaringPersonAPI, CaringPersonAPI, CaringPersonAPIRequest, CaringPersonHistoryAPI } from "../../models/caring-person";
import { DateAPI } from "../../models/historization";
import { PersonConnectionAPI } from "../../models/person-connection";
import { ProcessAPI } from "../../models/user";

export const fetchCaringPersons = () =>
    resourceAxiosInstance.get<CaringPersonAPI[]>(`/caring-person`, {
        cache: false,
    });

export const updateCaringPerson = (caringPerson: Partial<CaringPersonAPIRequest>) =>
    resourceAxiosInstance.patch<CaringPersonAPIRequest>(
        `/caring-person/${caringPerson.id}`,
        caringPerson,
        {
            cache: false,
        }
    );


export const makeCaringPersonInvalid = (caringPersonId: string | undefined, value: DateAPI) =>
    resourceAxiosInstance.put(`/caring-person/${caringPersonId}`,
        value,
        {
            cache: false,
        });

export const importCaringPerson = (caringPersonId: string | undefined, historyNumber: string) =>
    resourceAxiosInstance.put(`/caring-person/${caringPersonId}/history/${historyNumber}/import`,
        {
            cache: false,
        });

export const effectiveCaringPerson = (caringPersonId: string | undefined, historyNumber: string) =>
    resourceAxiosInstance.put(`/caring-person/${caringPersonId}/history/${historyNumber}/effective`,
        {
            cache: false,
        });

export const fetchCaringPerson = (caringPersonId: string) =>
    resourceAxiosInstance.get<CaringPersonAPI>(`/caring-person/${caringPersonId}`, {
        cache: false,
    });

export const fetchHistoryForCaringPerson = (caringPersonId: string) =>
    resourceAxiosInstance.get<CaringPersonHistoryAPI[]>(`/caring-person/${caringPersonId}/history`, {
        cache: false,
    });

export const fetchSingleHistoryForCaringPerson = (caringPersonId: string, historyNumber: string) =>
    resourceAxiosInstance.get<CaringPersonHistoryAPI>(`/caring-person/${caringPersonId}/history/${historyNumber}`, {
        cache: false,
    });

export const fetchAllCalculationsForCaringPerson = (caringPersonId: string) =>
    resourceAxiosInstance.get<CalculationCaringPersonAPI[]>(`/caring-person/${caringPersonId}/calculation`, {
        cache: false,
    });

export const deleteCaringPerson = (caringPersonId: string, historyNumber: string) =>
    resourceAxiosInstance.delete<CaringPersonHistoryAPI>(`/caring-person/${caringPersonId}/history/${historyNumber}`, {
        cache: false,
    });

export const createCaringPerson = (caringPerson: Partial<CaringPersonAPIRequest>) =>
    resourceAxiosInstance.post<CaringPersonAPIRequest>(`/caring-person`, caringPerson, {
        cache: false,
    });

export const fetchAllPersonConnectionForCaringPerson = (caringPersonId: string) =>
    resourceAxiosInstance.get<PersonConnectionAPI[]>(`/caring-person/${caringPersonId}/person-connection`, {
        cache: false,
    });

export const fetchProcessAndCheckAccessabilityForCaringPerson = (caringPersonId: string, processId: string) =>
    resourceAxiosInstance.get<ProcessAPI>(`/caring-person/${caringPersonId}/process/${processId}`, {
        cache: false,
    });
