import { CountryData } from "../shared/components/select-component-country";

export const isValidPostalcode = (postalCode: string, country: CountryData | null) => {
    if (country != null) {
        if (country.value === "DE") {
            return postalCode.length === 5 && onlyDigits(postalCode);
        }
    }
    return true;
}


export const onlyDigits = (value: string) => {
    return value.match(/^[0-9]+$/) != null;
}

export const endingDateSameOrAfterEffectiveDate = (endingDate: string | null, effectiveDate: string) => {
    if (endingDate === null) {
        return true;
    }
    const end = Date.parse(endingDate);
    const eff = Date.parse(effectiveDate);
    if (end != null && eff != null) {
        return (end >= eff);
    }
    return false;
}

export const dateOfBirthValid = (birthDate: string) => {
    const birth = Date.parse(birthDate);
    const current = Date.now();
    const earliest = Date.parse("1870-01-01");

    if (birth != null && current != null && earliest != null) {
        return birth >= earliest && birth <= current;
    }

    return false;
}

export const dateBeforeOrSameAsCurrentDate = (date: string) => {
    const toCheck = Date.parse(date);
    const current = Date.now();
    if (toCheck != null && current != null) {
        return toCheck <= current;
    }
    return false;
}

export const isValidPensionInsuranceNumber = (pensionInsuranceNumber: string) => {
    return pensionInsuranceNumber.match(/^(31|32|33|34|35)[0-9]{2}[0,1][0-9]{3}[A-Z][0-9]{3}$/)
}

export const isValidEmail = (email: string) => {
    return email.match(/^[\w-.]+@[\w-.]+.+[\w-]$/)
}