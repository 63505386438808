import { useEffect } from 'react';
import { Box, Button, Divider } from "@mui/material";
import PageLayout from "../../../../shared/components/page-layout";
import "../../details.css";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CrudTable, { ColumnDefinitionType } from '../../../../shared/components/crud-table/crud-table';
import { Sort } from '../../../../hooks/useSort.hook';
import { SortingDirection } from '../../../../enums/sort-by';
import { CaringPersonHistoryAPI, CaringPersonSelect } from '../../../../models/caring-person';
import { fetchPersonConnection, fetchHistoryForPersonConnection, fetchSingleHistoryForPersonConnection } from '../../../../shared/requests/calculation-basis.requests';
import { PersonInNeedOfCareSelect } from '../../../../models/person-in-need-of-care';
import { fetchPersonsInNeedOfCare } from '../../../../shared/requests/person-in-need-of-care.requests';
import { fetchCaringPersons } from '../../../../shared/requests/caring-person.requests';
import { } from "../../../../redux/auth.reducer";
import AccordionComponent from '../../../../shared/components/accordion-component';
import { PersonConnectionHistoryAPI } from '../../../../models/person-connection';
import PersonConnectionForm from '../form/person-connection-form';
import { AssistWalker, Person, Summarize } from '@mui/icons-material';
import CalculationBasisForm from '../form/calculation-basis-form';
import { CalculationBasisAmountFlagSelect, CalculationBasisAPI } from '../../../../models/calculation-basis';
import { addToHistory, HistoryTimelineItemModel } from '../../../../models/history';
import HistoryLine from '../../../../shared/components/history_line';

function PersonConnectionShow() {
    //Select Listen
    const [historyData, setHistoryData] = useState<PersonConnectionHistoryAPI[]>([]);
    const [caringPersonValues, setCaringPersonValues] = useState<CaringPersonSelect[]>([]);
    const [personInNeedOfCareValues, setPersonInNeedOfCareValues] = useState<PersonInNeedOfCareSelect[]>([]);
    //Berechnungsgrundlage extra Daten
    const [effectiveDate, setEffectiveDate] = useState<string>("");
    const [endingDate, setEndingDate] = useState<string | null>(null);
    const [amount, setAmount] = useState<number>(0.0);
    const [amountFlag, setAmountFlag] = useState<CalculationBasisAmountFlagSelect | null>(null);
    const [caringPerson, setCaringPerson] = useState<CaringPersonSelect | null>(null);
    const [personInNeedOfCare, setPersonInNeedOfCare] = useState<PersonInNeedOfCareSelect | null>(null);
    const [calculationBasisList, setCalculationBasisList] = useState<CalculationBasisAPI[]>([]);


    //Systemdaten
    const { id, history } = useParams();
    const [historyChronoItems, setHistoryChronoItems] = useState<HistoryTimelineItemModel[]>([]);
    const [isCurrentRendered, setCurrentRendered] = useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {
        fetchPersonsInNeedOfCare().then((response) => {
            let personsInNeedOfCare = response.data;
            let personsSelect: PersonInNeedOfCareSelect[] = [];
            personsInNeedOfCare.forEach((person) => {
                personsSelect.push({
                    id: String(person.id),
                    name: person.person.lastname + ", " + person.person.firstname,
                    realValue: +person.id,
                    genderId: person.person.gender
                })
            })
            setPersonInNeedOfCareValues(personsSelect)
        });
        fetchCaringPersons().then((response) => {
            let caringPersons = response.data;
            let personsSelect: CaringPersonSelect[] = [];
            caringPersons.forEach((person) => {
                personsSelect.push({
                    id: String(person.id),
                    name: person.person.lastname + ", " + person.person.firstname,
                    realValue: +person.id,
                    genderId: person.person.gender
                })
            })
            setCaringPersonValues(personsSelect)
        });
        if (id) {
            if (!history) {
                fetchSinglePersonAPI(id);
            } else {
                fetchSinglePersonHistoryAPI(id, history);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, history]);

    const getUserTitle = (): string => {
        return "Berechnungsgrundlage ansehen";
    };

    const fetchSinglePersonAPI = (id: string) => {
        fetchPersonConnection(id).then((response) => {
            setCaringPerson({
                id: response.data.caringPerson.id,
                name: response.data.caringPerson.person.lastname + ", " + response.data.caringPerson.person.firstname,
                realValue: +response.data.caringPerson.id,
                genderId: response.data.caringPerson.person.gender
            });
            setPersonInNeedOfCare({
                id: response.data.personInNeedOfCare.id,
                name: response.data.personInNeedOfCare.person.lastname + ", " + response.data.personInNeedOfCare.person.firstname,
                realValue: +response.data.personInNeedOfCare.id,
                genderId: response.data.personInNeedOfCare.person.gender
            });
            setCalculationBasisList(response.data.calculationBasisList.sort((a, b) => a.from > b.from ? 1 : -1))
            fetchHistoryData(id)
            if (!isCurrentRendered) {
                const historyData: HistoryTimelineItemModel[] = [];
                addToHistory({
                    type: "Berechnungsgrundlage",
                    name: `${response.data.personInNeedOfCare.person.lastname}, ${response.data.personInNeedOfCare.person.firstname} | ${response.data.caringPerson.person.lastname}, ${response.data.caringPerson.person.firstname}`,
                    url: `/calculation-basis/show/${id}`
                }).forEach((element => {
                    historyData.push({
                        type: element.type,
                        title: element.name,
                        url: element.url
                    })
                }))
                setHistoryChronoItems(historyData);
            }
        });
        setCurrentRendered(true)
    }

    const fetchSinglePersonHistoryAPI = (id: string, historyNumber: string) => {
        fetchSingleHistoryForPersonConnection(id, historyNumber).then((response) => {
            setCaringPerson({
                id: response.data.caringPerson.id,
                name: response.data.caringPerson.person.lastname + ", " + response.data.caringPerson.person.firstname,
                realValue: +response.data.caringPerson.id,
                genderId: response.data.caringPerson.person.gender
            });
            setPersonInNeedOfCare({
                id: response.data.personInNeedOfCare.id,
                name: response.data.personInNeedOfCare.person.lastname + ", " + response.data.personInNeedOfCare.person.firstname,
                realValue: +response.data.personInNeedOfCare.id,
                genderId: response.data.personInNeedOfCare.person.gender
            });
            setCalculationBasisList(response.data.calculationBasisList.sort((a, b) => a.from > b.from ? 1 : -1))
            fetchHistoryData(id)
            if (!isCurrentRendered) {
                const historyData: HistoryTimelineItemModel[] = [];
                addToHistory({
                    type: "Berechnungsgrundlage",
                    name: `${response.data.personInNeedOfCare.person.lastname}, ${response.data.personInNeedOfCare.person.firstname} | ${response.data.caringPerson.person.lastname}, ${response.data.caringPerson.person.firstname}`,
                    url: `/calculation-basis/show/${id}`
                }).forEach((element => {
                    historyData.push({
                        type: element.type,
                        title: element.name,
                        url: element.url
                    })
                }))
                setHistoryChronoItems(historyData);
            }
        });
        setCurrentRendered(true)
    }

    const fetchHistoryData = (id: string) => {
        fetchHistoryForPersonConnection(id).then((response2) => {
            const data: PersonConnectionHistoryAPI[] = []
            response2.data.forEach(element => {
                data.push(element);
                if (element.deleteTimestamp === null) {
                    element.deleteTimestamp = "";
                }
            })
            setHistoryData(data);
        });
    }



    const onClickHistoryShow = (person: PersonConnectionHistoryAPI) => {
        navigate(`history/${person.historyNumber}`)
    }

    const onClickCaringPersonEdit = () => {
        if (caringPerson) {
            navigate(`/caring-person/show/${caringPerson.id}`)

        }
    }

    const onClickPersonInNeedOfCareEdit = () => {
        if (personInNeedOfCare) {
            navigate(`/person-in-need-of-care/show/${personInNeedOfCare.id}`)
        }
    }

    const columns: ColumnDefinitionType<PersonConnectionHistoryAPI, keyof PersonConnectionHistoryAPI>[] = [
        {
            key: "historyNumber",
            header: "Historiennummer",
        },
        {
            key: "createTimestamp",
            header: "Zeitstempel Erstellung",
        },
        {
            key: "deleteTimestamp",
            header: "Zeitstempel Löschung",
        },
    ];

    const [sortBy] = useState<Sort<CaringPersonHistoryAPI>>({
        key: "historyNumber",
        direction: SortingDirection.Descending,
    });

    return (
        <PageLayout title={getUserTitle()}>
            <div className="single-view">
                {id && <HistoryLine
                    items={historyChronoItems}
                    setCurrentRendered={setCurrentRendered}
                />}
                <PersonConnectionForm
                    caringPerson={caringPerson}
                    caringPersonValues={caringPersonValues}
                    disabled={true}
                    personInNeedOfCare={personInNeedOfCare}
                    personInNeedOfCareValues={personInNeedOfCareValues}
                    savePushed={false}
                    setCaringPerson={setCaringPerson}
                    setPersonInNeedOfCare={setPersonInNeedOfCare}
                    formError={false}
                />

                <CalculationBasisForm
                    disabled={true}
                    savePushed={false}
                    formError={false}
                    dateFrom={effectiveDate}
                    dateUntil={endingDate}
                    amount={amount}
                    amountFlag={amountFlag}
                    setDateFrom={setEffectiveDate}
                    setDateUntil={setEndingDate}
                    setAmount={setAmount}
                    setAmountFlag={setAmountFlag}
                    calculationBasisList={calculationBasisList}
                    setCalculationBasisList={setCalculationBasisList}
                />
                {id && <Divider variant='fullWidth' sx={{ mt: 3, mb: 3 }} ></Divider>}
                {id &&
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        margin="auto"
                        sx={{ '& button': { mr: 10, mt: 2 } }}>
                        <Button startIcon={<Person />} variant="contained" size="large" onClick={onClickCaringPersonEdit}
                            sx={{
                                color: "#001D53",
                                background: "#D4E7FF",
                                ':hover': {
                                    background: 'primary.main',
                                    color: 'white',
                                },
                            }}>Pflegeperson ansehen</Button>
                        <Button startIcon={<AssistWalker />} variant="contained" size="large" onClick={onClickPersonInNeedOfCareEdit}
                            sx={{
                                color: "#001D53",
                                background: "#D4E7FF",
                                ':hover': {
                                    background: 'primary.main',
                                    color: 'white',
                                },
                            }}>Bedürftige/r ansehen</Button>
                    </Box>
                }
                {id &&
                    <div className='single-view-accordion'>
                        <AccordionComponent
                            label="Berechnungen"
                            defaultExpanded={false}
                            startIcon={<Summarize></Summarize>}
                            content={
                                <div>
                                    TODO ausfüllen!
                                </div>
                            }
                        />
                    </div>
                }
                {id &&
                    <div className='single-view-accordion'>
                        <AccordionComponent
                            label="Historie der Berechnungsgrundlage"
                            defaultExpanded={false}
                            content={
                                <CrudTable
                                    tableData={historyData}
                                    columns={columns}
                                    sortBy={sortBy}
                                    fetchElementsWithId={fetchHistoryForPersonConnection}
                                    dataFormatter={(element: PersonConnectionHistoryAPI) => {
                                        var date = new Date(element.createTimestamp);
                                        element.createTimestamp = (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
                                            (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
                                            date.getFullYear() + " " +
                                            (date.getHours() < 10 ? "0" : "") + date.getHours() + ":" +
                                            (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
                                        if (element.deleteTimestamp === null) {
                                            element.deleteTimestamp = "";
                                        } else {
                                            date = new Date(element.deleteTimestamp);
                                            element.deleteTimestamp = (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
                                                (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
                                                date.getFullYear() + " " +
                                                (date.getHours() < 10 ? "0" : "") + date.getHours() + ":" +
                                                (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
                                        }


                                        return element;
                                    }}
                                    callOnFindInPageClick={onClickHistoryShow}
                                    hideSearchbar
                                    searchingId={id}
                                />
                            }
                        />
                    </div>
                }
            </div>
        </PageLayout>
    );
}

export default PersonConnectionShow;
