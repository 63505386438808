import SelectComponent from '../../../../shared/components/select-component';
import { InstitutionSelect } from '../../../../models/institution';
import { User } from '../../../../models/user';
import TextFieldComponent from '../../../../shared/components/text-field-component';
import { Insights } from '@mui/icons-material';
import AccordionComponent from '../../../../shared/components/accordion-component';
import { ContractTypes, getAllContractTypes } from '../../../../models/person-in-need-of-care';
import { AidConfigAidLevels } from '../../../../models/aid';

type SelectProps = {
    user: User | null;
    savePushed: boolean
    disabled: boolean
    aidValues: InstitutionSelect[];
    insuranceValues: InstitutionSelect[];
    aidLevelValues: AidConfigAidLevels[];
    effectiveDate: string;
    setEffectiveDate: (value: string) => void;
    conceptOfOrder1: string;
    setConceptOfOrder1: (value: string) => void;
    conceptOfOrder2: string;
    setConceptOfOrder2: (value: string) => void;
    externalConceptOfOrder1: string;
    setExternalConceptOfOrder1: (value: string) => void;
    externalConceptOfOrder2: string;
    setExternalConceptOfOrder2: (value: string) => void;
    aidLevel: AidConfigAidLevels | null;
    setAidLevel: (value: AidConfigAidLevels | null) => void;
    contractType: ContractTypes | null;
    setContractType: (value: ContractTypes | null) => void;
    insurance: InstitutionSelect | null;
    setInsurance: (value: InstitutionSelect | null) => void;
    aid: InstitutionSelect | null;
    setAid: (value: InstitutionSelect | null) => void;
    formError: boolean;
    validation?: boolean;
    setValuesChanged?: (val: boolean) => void;
};

const PersonInNeedOfCareForm = ({
    user,
    savePushed,
    disabled,
    aidValues,
    insuranceValues,
    aidLevelValues,
    effectiveDate,
    setEffectiveDate,
    conceptOfOrder1,
    setConceptOfOrder1,
    conceptOfOrder2,
    setConceptOfOrder2,
    externalConceptOfOrder1,
    setExternalConceptOfOrder1,
    externalConceptOfOrder2,
    setExternalConceptOfOrder2,
    aidLevel,
    setAidLevel,
    contractType,
    setContractType,
    insurance,
    setInsurance,
    aid,
    setAid,
    formError,
    validation,
    setValuesChanged

}: SelectProps) => {
    return <div className='single-view-accordion'>
        <AccordionComponent
            defaultExpanded={false}
            label="Berechnungsdaten"
            startIcon={<Insights></Insights>}
            validation={validation}
            error={formError}
            content={
                <div className="flex flex-row single-view-person__all-container">
                    <div className="flex flex-column single-view-person__data-container">
                        <div className="flex flex-row single-view__form-row-width">
                            <TextFieldComponent
                                required={false}
                                id="effectiveDate"
                                label="Wirksamkeitsdatum"
                                value={effectiveDate}
                                type='date'
                                error={effectiveDate === "" && savePushed}
                                helperText={
                                    effectiveDate === "" && savePushed
                                        ? "Das Wirksamkeitsdatum darf nicht leer sein!"
                                        : ""
                                }
                                disabled={disabled}
                                setValueString={setEffectiveDate}
                                setValueChanged={setValuesChanged}
                            />
                            <div className="single-view__empty-form-width"></div>
                        </div>
                        <div className="flex flex-row single-view__form-row-width single-view-empty-container">
                        </div>
                        {user?.userRole !== "USER_ROLE_AID_ADMIN" && user?.userRole !== "USER_ROLE_AID_USER" && <div className="flex flex-row single-view__form-row-width">
                            <SelectComponent
                                selectData={aidValues}
                                tooltip="Es wurden noch keine Beihilfestellen angelegt."
                                className="single-view__text-field"
                                label="Beihilfestelle"
                                value={aid?.name || ""}
                                onChange={(value: string) => setAid(aidValues.find(({ name }) => name === value) || null)}
                                disabled={disabled || user?.userRole === "USER_ROLE_AID_ADMIN" || user?.userRole === "USER_ROLE_AID_USER"}
                                error={savePushed && !aid}
                                setValueChanged={setValuesChanged}
                            ></SelectComponent>
                            <div className="single-view__empty-form-width"></div>
                        </div>
                        }
                        <div className="flex flex-row single-view__form-row-width">
                            <TextFieldComponent
                                required={true}
                                id="conceptOfOrder1"
                                label="Ordnungsbegriff1"
                                value={conceptOfOrder1}
                                type='text'
                                error={conceptOfOrder1 === "" && savePushed}
                                helperText={
                                    conceptOfOrder1 === "" && savePushed
                                        ? "Der Ordnungsbegriff1 darf nicht leer sein!"
                                        : ""
                                }
                                disabled={disabled}
                                setValueString={setConceptOfOrder1}
                                setValueChanged={setValuesChanged}
                            />
                            <TextFieldComponent
                                required={false}
                                id="conceptOfOrder2"
                                label="Ordnungsbegriff2"
                                value={conceptOfOrder2}
                                type='text'
                                error={false}
                                helperText={""}
                                disabled={disabled}
                                setValueString={setConceptOfOrder2}
                                setValueChanged={setValuesChanged}
                            />
                        </div>
                        <div className="flex flex-row single-view__form-row-width single-view-empty-container">
                        </div>
                        <div className="flex flex-row single-view__form-row-width">
                            <SelectComponent
                                selectData={insuranceValues}
                                tooltip="Es wurden noch keine Versicherungen angelegt."
                                className="single-view__text-field"
                                label="Versicherung"
                                value={insurance?.name || ""}
                                onChange={(value: string) => setInsurance(insuranceValues.find(({ name }) => name === value) || null)}
                                disabled={disabled || user?.userRole === "USER_ROLE_INSURANCE_ADMIN" || user?.userRole === "USER_ROLE_INSURANCE_USER"}
                                error={savePushed && !insurance}
                                setValueChanged={setValuesChanged}
                            ></SelectComponent>
                            <div className="single-view__empty-form-width"></div>
                        </div>
                        <div className="flex flex-row single-view__form-row-width">
                            <TextFieldComponent
                                required={false}
                                id="externalConceptOfOrder1"
                                label="ExtOrdnungsbegriff1"
                                value={externalConceptOfOrder1}
                                type='text'
                                error={false}
                                helperText={""}
                                disabled={disabled}
                                setValueString={setExternalConceptOfOrder1}
                                setValueChanged={setValuesChanged}
                            />
                            <TextFieldComponent
                                required={false}
                                id="externalConceptOfOrder2"
                                label="ExtOrdnungsbegriff2"
                                value={externalConceptOfOrder2}
                                type='text'
                                error={false}
                                helperText={""}
                                disabled={disabled}
                                setValueString={setExternalConceptOfOrder2}
                                setValueChanged={setValuesChanged}
                            />
                        </div>
                        <div className="flex flex-row single-view__form-row-width single-view-empty-container">
                        </div>
                        <div className="flex flex-row single-view__form-row-width">
                            <SelectComponent
                                selectData={aidLevelValues}
                                tooltip="Es wurden noch keine Beihilfestufen angelegt."
                                className="single-view__text-field"
                                label="Beihilfestufe"
                                value={aidLevel?.name || ""}
                                onChange={(value: string) => setAidLevel(aidLevelValues.find(({ name }) => name === value) || null)}
                                disabled={disabled}
                                error={savePushed && !aidLevel}
                                helperText={
                                    aidLevel === null && savePushed
                                        ? "Der Tarif darf nicht leer sein!"
                                        : ""
                                }
                                setValueChanged={setValuesChanged}
                            ></SelectComponent>
                            <SelectComponent
                                selectData={getAllContractTypes()}
                                tooltip="Es wurden noch keine Vertragsarten angelegt."
                                className="single-view__text-field"
                                label="Vertragsart"
                                value={contractType?.name || ""}
                                onChange={(value: string) => setContractType(getAllContractTypes().find(({ name }) => name === value) || null)}
                                disabled={disabled}
                                error={savePushed && !contractType}
                                setValueChanged={setValuesChanged}
                            ></SelectComponent>
                        </div>
                    </div>
                </div>
            }
        />
    </div>
}
export default PersonInNeedOfCareForm;