import { TimelineItemModel } from "react-chrono/dist/models/TimelineItemModel";

export interface HistoryEntry {
    type: string,
    name: string,
    url: string;
}

export interface HistoryTimelineItemModel extends TimelineItemModel {
    type: string;
    url: string;
}

export const getHistory = (): HistoryEntry[] => {
    const data = localStorage.history
    if (data) {
        const history = JSON.parse(data) as HistoryEntry[]
        return history;
    } else {
        return [];
    }

}


export const addToHistory = (entry: HistoryEntry) => {
    const history: HistoryEntry[] = getHistory() || [];
    history.push(entry);
    localStorage.history = JSON.stringify(history);
    return history;
}

export const clearHistory = () => {
    localStorage.removeItem("history")
}