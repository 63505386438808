import { AddressAPI, Address, AddressAPIRequest } from "../models/address";
import { PersonAPI, Person, PersonAPIRequest } from "../models/person";
import { InstitutionAPI, Institution } from "../models/institution";
import { BusinessTransactionAPI } from "./business-transaction";
import { AidLevel, AidLevelAPI } from "./aid";
export interface PersonInNeedOfCare {
    id: string;
    effectiveDate: string;
    status: string;
    validity: string;
    person: Person;
    address: Address | null;
    conceptOfOrder1: string;
    conceptOfOrder2: string;
    externalConceptOfOrder1: string;
    externalConceptOfOrder2: string;
    aidLevel: AidLevel | null;
    contractType: string;
    insurance: Institution;
    client: Institution;



}

export type PersonInNeedOfCareAPI = {
    id: string;
    effectiveDate: string;
    status: string;
    validity: string;
    person: PersonAPI;
    address: AddressAPI | null;
    conceptOfOrder1: string;
    conceptOfOrder2: string | null;
    externalConceptOfOrder1: string | null;
    externalConceptOfOrder2: string | null;
    aidLevel: AidLevelAPI | null;
    contractType: string;
    insurance: InstitutionAPI;
    client: InstitutionAPI;
    personName: string;
    addressString: string;
    validityString: string;
};

export type PersonInNeedOfCareHistoryAPI = {
    id: string;
    historyNumber: string;
    effectiveDate: string;
    status: string;
    validity: string;
    person: PersonAPI;
    address: AddressAPI | null;
    conceptOfOrder1: string;
    conceptOfOrder2: string | null;
    externalConceptOfOrder1: string | null;
    externalConceptOfOrder2: string | null;
    aidLevel: AidLevelAPI | null;
    contractType: string;
    insurance: InstitutionAPI;
    client: InstitutionAPI;
    createTimestamp: string;
    deleteTimestamp: string | null;
    createTransaction: BusinessTransactionAPI;
    deleteTransaction: BusinessTransactionAPI | null;
    imported: boolean;
};

export type PersonInNeedOfCareAPIRequest = {
    id: string;
    effectiveDate: string;
    status: string;
    person: PersonAPIRequest;
    address: AddressAPIRequest | null;
    conceptOfOrder1: string;
    conceptOfOrder2: string | null;
    externalConceptOfOrder1: string | null;
    externalConceptOfOrder2: string | null;
    aidLevelIdentifier: number;
    contractType: string;
    insuranceIdentifier: number;
    aidIdentifier: number;
    processIdentifier: string
};

export type PersonInNeedOfCareSelect = {
    id: string;
    name: string;
    realValue: number;
    genderId: string;
};

export interface ContractTypes {
    id: string,
    name: string
}

export const getAllContractTypes = () => {
    return [
        {
            id: "E_CONTRACT_TYPE_NORMAL",
            name: "Normal",
        },
        {
            id: "E_CONTRACT_TYPE_EMPLOYEE",
            name: "Mitarbeiter",
        },
        {
            id: "E_CONTRACT_TYPE_VIP",
            name: "VIP",
        },
    ]
}

export type PersonInNeedOfCareSort = Pick<
    PersonInNeedOfCareAPI,
    "personName" | "id" | "addressString" | "effectiveDate" | "conceptOfOrder1" | "validityString"
>;

export type PersonInNeedOfCareHistorySort = Pick<
    PersonInNeedOfCareHistoryAPI,
    "person" | "id" | "address" | "historyNumber"
>;

export type PersonInNeedOfCareShortKey = keyof PersonInNeedOfCare;
