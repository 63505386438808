import { useEffect, useRef, useState } from "react";
import { SortingDirection } from "../../../../enums/sort-by";
import { InstitutionAPI, InstitutionSort, getInstitutionTypesWithoutSystem } from "../../../../models/institution";
import {
    fetchPensionInstitutions,
} from "../../../../shared/requests/institution.requests";
import PageLayout from "../../../../shared/components/page-layout";
import { Sort } from "../../../../hooks/useSort.hook";
import CrudTable, { ColumnDefinitionType } from "../../../../shared/components/crud-table/crud-table";
import { clearHistory } from "../../../../models/history";

function ShowPensionInstitutions() {
    const [sortBy] = useState<Sort<InstitutionSort>>({
        key: "name",
        direction: SortingDirection.Descending,
    });

    const [institutions, setInstitutions] = useState<InstitutionAPI[]>([]);
    const isInitRender = useRef(true);

    useEffect(() => {
        clearHistory();
        if (isInitRender.current) {
            fetchPensionInstitutions().then((response) => {
                setInstitutions(response.data);
            });
            isInitRender.current = false;
        }
    });

    const columns: ColumnDefinitionType<InstitutionAPI, keyof InstitutionAPI>[] = [
        {
            key: "id",
            header: "ID",
        },

        {
            key: "name",
            header: "Name",
        },
        {
            key: "typeString",
            header: "Institutions-Typ",
        },
        {
            key: "successorID",
            header: "Nachfolger",
        },
        {
            key: "addressString",
            header: "Anschrift",
        }
    ];
    return (
        <PageLayout title={"Übersicht Versorgungseinrichtungen"}>
            <CrudTable
                tableData={institutions}
                columns={columns}
                sortBy={sortBy}
                fetchElements={fetchPensionInstitutions}
                dataFormatter={(element: InstitutionAPI) => {
                    element.successorID = element.successor ? String(element.successor.name) : "Kein Nachfolger";
                    element.addressString = element.address ? (element.address.street + " " + element.address.houseNumber + " | " + element.address.postalCode + " " + element.address.location) : "Unbekannt"
                    const ele = getInstitutionTypesWithoutSystem().find((ele) => { return element.type === ele.id; });
                    element.typeString = ele ? ele.name : "Kein Institutionstyp"

                    return element;
                }}
            />
        </PageLayout>
    );
}

export default ShowPensionInstitutions;
