import TextFieldComponent from "../text-field-component";
import { isValidPostalcode } from "../../../helper/validation";
import SelectComponentCountry, { CountryData } from "../select-component-country";
import AccordionComponent from "../accordion-component";
import { Place } from "@mui/icons-material";

type SelectProps = {
    savePushed: boolean
    disabled: boolean
    street: string;
    setStreet: (value: string) => void;
    houseNumber: number | null;
    setHouseNumber: (value: number) => void;
    postalCode: string;
    setPostalCode: (value: string) => void;
    location: string;
    setLocation: (value: string) => void;
    country: CountryData | null;
    setCountry: (value: CountryData | null) => void;
    poBox: string;
    setPOBox: (value: string) => void;
    poBoxPostalCode: string;
    setPOBoxPostalCode: (value: string) => void;
    addressSupplement: string;
    setAddressSupplement: (value: string) => void;
    phoneNumber: string;
    setPhoneNumber: (value: string) => void;
    fax: string;
    setFax: (value: string) => void;
    email: string;
    setEmail: (value: string) => void;
    useAddress: boolean;
    formError: boolean;
    validation?: boolean;
    setValuesChanged?: (val: boolean) => void;
};

const AddressForm = ({
    savePushed,
    disabled,
    street,
    setStreet,
    houseNumber,
    setHouseNumber,
    postalCode,
    setPostalCode,
    location,
    setLocation,
    country,
    setCountry,
    poBox,
    setPOBox,
    poBoxPostalCode,
    setPOBoxPostalCode,
    addressSupplement,
    setAddressSupplement,
    phoneNumber,
    setPhoneNumber,
    fax,
    setFax,
    email,
    setEmail,
    useAddress,
    formError,
    validation,
    setValuesChanged
}: SelectProps) => {
    return <div className='single-view-accordion'>
        <AccordionComponent
            defaultExpanded={false}
            label="Adressdaten"
            startIcon={<Place></Place>}
            error={formError}
            validation={validation}
            content={
                <div className="flex flex-row single-view-person__all-container">
                    <div className="flex flex-column single-view-person__data-container">
                        <div className="flex flex-row single-view__form-row-width">
                            <TextFieldComponent
                                required={false}
                                id="street"
                                label="Straße"
                                value={street}
                                error={false}
                                helperText=""
                                type='text'
                                disabled={disabled}
                                setValueString={setStreet}
                                setValueChanged={setValuesChanged}
                            />
                            <TextFieldComponent
                                required={false}
                                id="houseNumber"
                                label="Hausnummer"
                                value={houseNumber}
                                type='number'
                                error={false}
                                helperText=""
                                disabled={disabled}
                                setValueNumber={setHouseNumber}
                                setValueChanged={setValuesChanged}
                            />
                        </div>
                        <div className="flex flex-row single-view__form-row-width">
                            <TextFieldComponent
                                required={false}
                                id="poBox"
                                label="Postfach"
                                value={poBox}
                                type='text'
                                error={false}
                                helperText=""
                                disabled={disabled}
                                setValueString={setPOBox}
                                setValueChanged={setValuesChanged}
                            />
                            <TextFieldComponent
                                required={false}
                                id="poBoxPostalCode"
                                label="Postfach PLZ"
                                value={poBoxPostalCode}
                                type='text'
                                error={false}
                                helperText=""
                                disabled={disabled}
                                setValueString={setPOBoxPostalCode}
                                setValueChanged={setValuesChanged}
                            />
                        </div>
                        <div className="flex flex-row single-view__form-row-width">
                            <TextFieldComponent
                                required={true}
                                id="postalCode"
                                label="PLZ"
                                value={postalCode}
                                type='text'
                                error={(postalCode === "" || !isValidPostalcode(postalCode, country)) && savePushed && useAddress}
                                helperText={
                                    postalCode === "" && savePushed && useAddress
                                        ? "Die Postleitzahl darf nicht leer sein!"
                                        : !isValidPostalcode(postalCode, country) && savePushed && useAddress
                                            ? "Die Postleitzahl hat ein falsches Format!"
                                            : ""
                                }
                                disabled={disabled}
                                setValueString={setPostalCode}
                                setValueChanged={setValuesChanged}
                            />
                            <TextFieldComponent
                                required={true}
                                id="location"
                                label="Ort"
                                value={location}
                                type='text'
                                error={location === "" && savePushed && useAddress}
                                helperText={
                                    location === "" && savePushed && useAddress
                                        ? "Der Wohnort darf nicht leer sein!"
                                        : ""
                                }
                                disabled={disabled}
                                setValueString={setLocation}
                                setValueChanged={setValuesChanged}
                            />
                        </div>
                        <div className="flex flex-row single-view__form-row-width">
                            <TextFieldComponent
                                required={false}
                                id="addressSupplement"
                                label="Adresszusatz"
                                value={addressSupplement}
                                type='text'
                                error={false}
                                helperText=""
                                disabled={disabled}
                                setValueString={setAddressSupplement}
                                setValueChanged={setValuesChanged}
                            />
                            <SelectComponentCountry
                                className="single-view__text-field"
                                label="Land"
                                value={country}
                                disabled={disabled}
                                setCountry={setCountry}
                                error={false}
                                setValueChanged={setValuesChanged}
                            ></SelectComponentCountry>
                        </div>
                        <div className="flex flex-row single-view__form-row-width single-view-empty-container">
                        </div>
                        <div className="flex flex-row single-view__form-row-width">
                            <TextFieldComponent
                                required={false}
                                id="phoneNumber"
                                label="Telefonnummer"
                                value={phoneNumber}
                                type='tel'
                                error={false}
                                helperText=""
                                disabled={disabled}
                                setValueString={setPhoneNumber}
                                setValueChanged={setValuesChanged}
                            />
                            <TextFieldComponent
                                required={false}
                                id="fax"
                                label="Telefax"
                                value={fax}
                                type='tel'
                                error={false}
                                helperText=""
                                disabled={disabled}
                                setValueString={setFax}
                                setValueChanged={setValuesChanged}
                            />
                        </div>
                        <div className="flex flex-row single-view__form-row-width single-view-tripple-values">
                            <TextFieldComponent
                                required={false}
                                id="email"
                                label="Email-Adresse"
                                value={email}
                                type='email'
                                error={false}
                                helperText=""
                                disabled={disabled}
                                setValueString={setEmail}
                                setValueChanged={setValuesChanged}
                            />
                            <div className="single-view__empty-form-width"></div>
                        </div>
                    </div>
                </div>
            }
        />
    </div>
}
export default AddressForm;