import { Female, Male, Transgender } from "@mui/icons-material";
import {
    Tooltip,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from "@mui/material";
import { PersonInNeedOfCareSelect } from "../../models/person-in-need-of-care";
import { CaringPersonSelect } from "../../models/caring-person";

export type Types = {
    id: string;
    name: string;
};

type SelectProps = {
    selectData: Array<Types>;
    tooltip: string;
    className: string;
    label: string;
    value: string;
    onChange: (value: string) => void;
    disabled: boolean;
    error: boolean;
    genderSelect?: boolean;
    personGenderSelect?: boolean;
    helperText?: string;
    notRequired?: boolean;
    setValueChanged?: (val: boolean) => void;
};

const getGenderIcon = (id: string) => {
    if (id === "GENDER_MALE") {
        return <Male></Male>
    } else if (id === "GENDER_FEMALE") {
        return <Female></Female>
    } else if (id === "GENDER_OTHER") {
        return <Transgender></Transgender>
    } else {
        return <div></div>
    }
}

const getPersonGender = (type: Types) => {
    const pinocSelect = type as PersonInNeedOfCareSelect;
    if (pinocSelect != null) {
        return getGenderIcon(pinocSelect.genderId);
    }
    const cpSelect = type as CaringPersonSelect;
    if (cpSelect != null) {
        return getGenderIcon(cpSelect.genderId);
    }
    return <div></div>
}

const SelectComponent = ({
    selectData,
    tooltip,
    className,
    label,
    value,
    onChange,
    disabled,
    error,
    genderSelect,
    personGenderSelect,
    helperText,
    notRequired,
    setValueChanged
}: SelectProps) => {
    return (
        <FormControl className={className}>
            <InputLabel id="dropdown" sx={{
                color: "#001D53",
                "& .MuiFormLabel-asterisk": { color: "red" },
            }} required={!notRequired}>{label}</InputLabel>
            {(!selectData.length && (
                <Tooltip title={tooltip}>
                    <Select labelId="dropdown" label={label} value="" disabled />
                </Tooltip>
            )) || (
                    <Select
                        sx={{
                            input: { color: '#2D3335' },
                            "& .MuiInputBase-input": { backgroundColor: "#EEEEEE" },
                            "& .MuiFormLabel-asterisk": { color: "red" },
                        }}
                        labelId="dropdown"
                        label={label}
                        id="dropdown"
                        value={value}
                        variant='filled'
                        disabled={disabled}
                        error={error}
                        inputProps={{ 'aria-label': helperText ? helperText : "" }}
                        onChange={(event) => {
                            if (setValueChanged) {
                                setValueChanged(true);
                            }
                            onChange(event.target.value);
                        }}
                    >
                        {selectData.map((type) => (
                            <MenuItem key={type.id} value={type.name}>
                                {genderSelect && getGenderIcon(type.id)}
                                {personGenderSelect && getPersonGender(type)}
                                {type.name}
                            </MenuItem>
                        ))}
                    </Select>
                )}
        </FormControl>
    );
};

export default SelectComponent;
