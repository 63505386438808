import { useEffect, useRef, useState } from "react";
import { SortingDirection } from "../../../../enums/sort-by";
import CrudTable, {
    ColumnDefinitionType,
} from "../../../../shared/components/crud-table/crud-table";
import PageLayout from "../../../../shared/components/page-layout";
import { Sort } from "../../../../hooks/useSort.hook";
import { DateAPI, getValidityValues } from "../../../../models/historization";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { } from "../../../../redux/auth.reducer";
import { fetchPersonConnections, makePersonConnectionInvalid } from "../../../../shared/requests/calculation-basis.requests";
import { PersonConnectionAPI, PersonConnectionSort } from "../../../../models/person-connection";
import { getFromUntilStringFromCalculationBasisList } from "../../../../helper/formatter";
import { useNavigate } from "react-router-dom";
import InvalidDialog from "../../../../shared/components/standard-actions/dialogs/invalid-dialog";
import { clearHistory } from "../../../../models/history";

function ListPersonConnection() {
    const [sortBy] = useState<Sort<PersonConnectionSort>>({
        key: "id",
        direction: SortingDirection.Descending,
    });
    const navigate = useNavigate();
    const { user } = useSelector(({ authReducer }: RootState) => authReducer);
    const [personConnection, setPersonConnection] = useState<PersonConnectionAPI[]>([]);
    const [showInvalidDialog, setShowInvalidDialog] = useState<boolean>(false);
    const [entityName, setEntityName] = useState<string>("");
    const [clickedId, setClickedId] = useState<string>("");
    const isInitRender = useRef(true);
    const onAddClick = () => {
        navigate('add');
    }
    const onEditClick = (entity: PersonConnectionAPI) => {
        navigate(`edit/${entity.id}`);
    }
    const onShowClick = (entity: PersonConnectionAPI) => {
        navigate(`show/${entity.id}`);
    }
    const onInvalidClick = (entity: PersonConnectionAPI) => {
        setEntityName("Bedürftiger: " + entity.personInNeedOfCareName + " - Pflegeperson:" + entity.caringPersonName)
        setClickedId(entity.id)
        setShowInvalidDialog(true)
    }
    const onInvalidCancel = () => {
        setShowInvalidDialog(false)
    }

    const onInvalidSubmit = (value: string) => {
        const date: DateAPI = {
            effectiveDate: value
        }
        makePersonConnectionInvalid(clickedId ?? "", date).then((response) => {
            if (response.status === 200) {
                setShowInvalidDialog(false)
            }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        clearHistory();
        if (isInitRender.current) {
            fetchPersonConnections().then((response) => {
                setPersonConnection(response.data);
            });
            isInitRender.current = false;
        }
    });

    const columns: ColumnDefinitionType<PersonConnectionAPI, keyof PersonConnectionAPI>[] = [
        {
            key: "id",
            header: "ID",
        },

        {
            key: "personInNeedOfCareName",
            header: "Bedürftige/r (Name, Vorname, Geburtsdatum)",
        },

        {
            key: "caringPersonName",
            header: "Pflegeperson (Name, Vorname, Geburtsdatum)",
        },
        {
            key: "fromUntilString",
            header: "Datum Von - Datum Bis"
        }
    ];
    return (
        <PageLayout title={"Übersicht Berechnungsgrundlagen | Aktueller Zustand"}>
            <InvalidDialog
                showDialog={showInvalidDialog}
                dialogTitle={"Ungültig setzen einer Berechnungsgrundlage"}
                deleteInfo={`Zu wann soll die Berechnungsgrundlage zwischen { ${entityName} } ungültig werden?`}
                onClickCancel={onInvalidCancel}
                onClickSubmit={(value: string) => onInvalidSubmit(value)}
            />
            <CrudTable
                callOnAddClick={user?.userRole === "USER_ROLE_SYSTEM_ADMIN" ? undefined : onAddClick}
                callOnFindInPageClick={onShowClick}
                callOnEditClick={onEditClick}
                callOnInactiveClick={onInvalidClick}
                tableData={personConnection}
                showGender
                columns={columns}
                sortBy={sortBy}
                fetchElements={fetchPersonConnections}
                dataFormatter={(element: PersonConnectionAPI) => {
                    if (element.caringPerson.person.dateOfBirth != null) {
                        var date = new Date(element.caringPerson.person.dateOfBirth);
                        element.caringPerson.person.dateOfBirth = (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
                            (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
                            date.getFullYear();
                    }
                    if (element.personInNeedOfCare.person.dateOfBirth != null) {
                        date = new Date(element.personInNeedOfCare.person.dateOfBirth);
                        element.personInNeedOfCare.person.dateOfBirth = (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
                            (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
                            date.getFullYear();
                    }
                    element.caringPersonName = element.caringPerson.person.lastname + ", " + element.caringPerson.person.firstname + (element.caringPerson.person.dateOfBirth !== null ? " *" + element.caringPerson.person.dateOfBirth : "")
                    element.personInNeedOfCareName = element.personInNeedOfCare.person.lastname + ", " + element.personInNeedOfCare.person.firstname + (element.personInNeedOfCare.person.dateOfBirth !== null ? " *" + element.personInNeedOfCare.person.dateOfBirth : "")
                    const elementValidty = getValidityValues().find((ele) => { return element.validity === ele.id; });
                    element.validityString = elementValidty ? elementValidty.name : "Kein Gültigkeitskennzeichen"
                    element.fromUntilString = getFromUntilStringFromCalculationBasisList(element.calculationBasisList)
                    return element;
                }}
            />
        </PageLayout>
    );
}

export default ListPersonConnection;