import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    TextField,
} from "@mui/material";
import { useState } from "react";
import React from "react";

import "./forgot-password.css";
import { AccountCircle } from "@mui/icons-material";
import ErrorDisplay from "../../../../shared/components/error-display";
import { useNavigate } from "react-router-dom";
import { sendResetPasswordLink } from "../../../../shared/requests/user.requests";
import { EmailAPI } from "../../../../models/user";

function ForgotPassword() {

    const [email, setEmail] = useState<string>("");

    const navigate = useNavigate();

    const navigateToLogin = () => {
        navigate(`/login`);
    }

    const handleUsernameChange = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
        setEmail(event.target.value);
    };

    const sendPasswordResetLink = () => {
        const emailAPI: EmailAPI = {
            email: email,
        }
        sendResetPasswordLink(emailAPI).then(() => {
            navigateToLogin();
        });
    };

    return (
        <>
            <Card className="login-bg">
                <CardContent className="flex flex-column login-width content-padding">
                    <ErrorDisplay>
                        <div className="login-image-container">
                            <img className="login-image" src="/assets/img/AidPlus_Logo_transparent.png" alt="AidPlus Icon" />
                        </div>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "flex-end",
                                marginBottom: "0.5rem",
                            }}
                        >
                            <AccountCircle sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                            <TextField
                                sx={{ width: "100%" }}
                                label="Email-Adresse"
                                variant="standard"
                                onChange={handleUsernameChange}
                            />
                        </Box>
                        <a className="forgot-password" href="/login">Einloggen?</a>
                    </ErrorDisplay>
                </CardContent>
                <CardActions className="flex flex-end">
                    <Button variant="contained" onClick={sendPasswordResetLink}>
                        Passwort zurücksetzen
                    </Button>
                </CardActions>
            </Card>
        </>
    );
}

export default ForgotPassword;
