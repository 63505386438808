import { Button } from "@mui/material";
import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import "../details.css"
import CrudTable, { ColumnDefinitionType } from "../../../shared/components/crud-table/crud-table";
import { Sort } from "../../../hooks/useSort.hook";
import { SortingDirection } from "../../../enums/sort-by";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { } from "../../../redux/auth.reducer";
import PageLayout from "../../../shared/components/page-layout";
import { Calculate } from "@mui/icons-material";
import { clearHistory } from "../../../models/history";
import { fetchAllCalculationRuns, startCalculationRuns } from "../../../shared/requests/aid-request";
import { CalculationRunAPI, CalculationRunSort } from "../../../models/aid";
import TextFieldComponent from "../../../shared/components/text-field-component";
import AccordionComponent from "../../../shared/components/accordion-component";
import { dateBeforeOrSameAsCurrentDate, endingDateSameOrAfterEffectiveDate } from "../../../helper/validation";
import ConfirmCancelDialog from "../../../shared/components/standard-actions/dialogs/confirm-cancel-dialog";

function CalculationRun() {
    const [calculationRuns, setCalculationRuns] = useState<CalculationRunAPI[]>([]);
    const [externalModifiedCalculationRuns, setExternalModifiedCalculationRuns] = useState<boolean>(false);
    const [dateFrom, setDateFrom] = useState<string>("");
    const [dateUntil, setDateUntil] = useState<string>("");
    const [searchAcivated, setSearchActivated] = useState<boolean>(false);
    const [showConfirmStartRunDialog, setShowConfirmStartRunDialog] = useState<boolean>(false);
    const currentDate = new Date();
    const isInitRender = useRef(true);
    const { user } = useSelector(({ authReducer }: RootState) => authReducer);
    const errorSearch = dateUntil === "" || !dateBeforeOrSameAsCurrentDate(dateUntil) || !endingDateSameOrAfterEffectiveDate(dateUntil, dateFrom) || dateFrom === "";

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (isInitRender.current && user) {
            clearHistory();
            fetchAllCalculationRuns(user.institution.id).then((response) => {
                setCalculationRuns(response.data)
            })
            isInitRender.current = false;
        }
        const date: Date = new Date();
        setDateFrom(date.getFullYear() + "-" + ((date.getMonth() + 1) < 10 ? "0" : "") + (date.getMonth() + 1) + "-01")
        setDateUntil(date.getFullYear() + "-" + ((date.getMonth() + 1) < 10 ? "0" : "") + (date.getMonth() + 1) + "-" + ((date.getDay() + 1) < 10 ? "0" : "") + (date.getDay() + 1))
    }, [user]);

    const columns: ColumnDefinitionType<CalculationRunAPI, keyof CalculationRunAPI>[] = [
        {
            key: "startString",
            header: "Start des Laufs",
        },
        {
            key: "endString",
            header: "Ende des Laufs",
        },
        {
            key: "statusString",
            header: "Status",
        },
        {
            key: "startUserString",
            header: "Start durch User",
        },
        {
            key: "amountCalculatedCaringPerson",
            header: "Anzahl berechneter Pflegepersonen",
        },
        {
            key: "rvString",
            header: "Gesamtbeitrag (D)RV",
        },
        {
            key: "abvString",
            header: "Gesamtbeitrag ABV",
        },
        {
            key: "avString",
            header: "Gesamtbeitrag AV",
        },
    ]

    const [sortBy] = useState<Sort<CalculationRunSort>>({
        key: "startString",
        direction: SortingDirection.Descending,
    });

    const onSearchPressed = () => {
        setSearchActivated(true);
        setExternalModifiedCalculationRuns(true)
    }

    const onStartRunClicked = () => {
        setShowConfirmStartRunDialog(true)
    }

    const onStartRunCancel = () => {
        setShowConfirmStartRunDialog(false)
    }

    const onStartRunSubmit = () => {
        if (user) {
            startCalculationRuns(user.institution.id).then(() => {
                setShowConfirmStartRunDialog(false)
                setExternalModifiedCalculationRuns(true)
            })
        }
    }




    return <PageLayout title={"Beitragsberechnungsläufe"}>
        <ConfirmCancelDialog
            dialogTitle="Starten eines Beitragsberechnungslauf"
            dialogInfo={`Soll ein neuer Beitragsberechnungslauf gestartet werden, der alle noch nicht berechneten Monate bis 
                einschließlich ${currentDate.getMonth() !== 0 ? currentDate.getMonth() : 12}/${currentDate.getMonth() !== 0 ? currentDate.getFullYear() : (currentDate.getFullYear() - 1)} berechnet?`}
            onClickCancel={onStartRunCancel}
            onClickSubmit={onStartRunSubmit}
            showDialog={showConfirmStartRunDialog}

        />
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            margin="auto"
            sx={{ '& button': { mr: 10, mt: 2 } }}>
            <Button startIcon={<Calculate />} variant="contained" size="large" onClick={onStartRunClicked}
                sx={{
                    height: 150,
                    width: 300,
                    color: "#001D53",
                    background: "#D4E7FF",
                    ':hover': {
                        background: 'primary.main', // theme.palette.primary.main
                        color: 'white',
                    },
                }}>Beitragsberechnungslauf starten</Button>
        </Box>
        <div className="single-view">
            <div className='single-view-accordion'>
                <AccordionComponent
                    defaultExpanded={true}
                    label="Berechnungsläufe"
                    startIcon={<Calculate></Calculate>}
                    validation={false}
                    error={false}
                    content={
                        <div className="flex flex-column single-view-person__data-container">
                            <div className="flex flex-row single-view__form-row-width">
                                <TextFieldComponent
                                    required={false}
                                    id="effectiveDate"
                                    label="Von-Datum"
                                    type='date'
                                    value={dateFrom}
                                    error={dateFrom === ""}
                                    helperText={
                                        dateFrom === ""
                                            ? "Das Von-Datum darf nicht leer sein!"
                                            : ""
                                    }
                                    disabled={false}
                                    setValueString={setDateFrom}
                                />
                                <TextFieldComponent
                                    required={false}
                                    id="effectiveDate"
                                    label="Bis-Datum"
                                    type='date'
                                    value={dateUntil}
                                    error={dateUntil === "" || !dateBeforeOrSameAsCurrentDate(dateUntil) || !endingDateSameOrAfterEffectiveDate(dateUntil, dateFrom)}
                                    helperText={
                                        dateFrom === ""
                                            ? "Das Bis-Datum darf nicht leer sein!"
                                            : !dateBeforeOrSameAsCurrentDate(dateUntil)
                                                ? "Das Bis-Datum darf nicht hinter dem aktuellen Datum sein!"
                                                : !endingDateSameOrAfterEffectiveDate(dateUntil, dateFrom)
                                                    ? "Das Bis-Datum darf nicht vor dem Von-Datum liegen!"
                                                    : ""
                                    }
                                    disabled={false}
                                    setValueString={setDateUntil}
                                />
                            </div>
                            <div className="flex flex-row single-view__form-row-width">
                                <div className="single-view__empty-form-width"></div>
                                <Button variant="outlined" size="large" onClick={onSearchPressed}
                                >Suchen</Button>
                            </div>
                            <div className='single-view-accordion'>
                                <CrudTable
                                    tableData={calculationRuns}
                                    externalModified={externalModifiedCalculationRuns}
                                    setExternalModified={setExternalModifiedCalculationRuns}
                                    columns={columns}
                                    sortBy={sortBy}
                                    fetchElementsWithId={fetchAllCalculationRuns}
                                    searchingId={user ? user.institution.id : "-1"}
                                    hideSearchbar
                                    dataFormatter={(element: CalculationRunAPI) => {
                                        element.abvString = element.sumABV + "€";
                                        element.avString = element.sumAV + "€";
                                        element.rvString = element.sumRV + "€";
                                        element.startUserString = element.runStartUser.lastname + ", " + element.runStartUser.firstname
                                        element.statusString = element.status === "E_RUN_COLLECTION_STATUS_RUNNING" ? "In Arbeit" : element.status === "E_RUN_COLLECTION_STATUS_FAILED" ? "Fehlgeschlagen" : "Abgeschlossen";
                                        var date = new Date(element.startTimestamp);
                                        element.startString = (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
                                            (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
                                            date.getFullYear() + " " +
                                            (date.getHours() < 10 ? "0" : "") + date.getHours() + ":" +
                                            (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
                                        date = new Date(element.endTimestamp);
                                        element.endString = (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
                                            (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
                                            date.getFullYear() + " " +
                                            (date.getHours() < 10 ? "0" : "") + date.getHours() + ":" +
                                            (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
                                        return element;
                                    }}
                                    filterData={(element: CalculationRunAPI) => {
                                        if (!searchAcivated || errorSearch) {
                                            return true;
                                        } else {
                                            const dateSearchFrom = new Date(dateFrom);
                                            const dateSearchUntil = new Date(dateUntil);
                                            const dateStarted = new Date(element.startTimestamp);
                                            dateStarted.setHours(0)
                                            dateStarted.setMinutes(0)
                                            dateStarted.setSeconds(0)
                                            if (dateSearchFrom !== null && dateSearchUntil !== null && dateStarted !== null) {
                                                return dateStarted >= dateSearchFrom && dateStarted <= dateSearchUntil
                                            }
                                        }
                                        return false
                                    }}
                                />
                            </div>
                        </div>

                    }></AccordionComponent>
            </div>
        </div>
    </PageLayout>
}

export default CalculationRun;