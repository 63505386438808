import SelectComponent from "../select-component";
import { StatusSelect, ValiditySelect, getStatusValues, getValidityValues } from "../../../models/historization";
import TextFieldComponent from "../text-field-component";
import { Feed } from "@mui/icons-material";
import AccordionComponent from "../accordion-component";

type SelectProps = {
    validity: ValiditySelect | null;
    status: StatusSelect | null;
    createTimestamp: string;
    deleteTimestamp: string;
    createTransactionID: string;
    createTransactionUserEmail: string;
    deleteTransactionID: string;
    deleteTransactionUserEmail: string;
};

const HistoryShow = ({
    validity,
    status,
    createTimestamp,
    deleteTimestamp,
    createTransactionID,
    createTransactionUserEmail,
    deleteTransactionID,
    deleteTransactionUserEmail

}: SelectProps) => {
    let validityTypes: ValiditySelect[] = getValidityValues();
    let statusTypes: StatusSelect[] = getStatusValues();
    return <div className='single-view-accordion'>
        <AccordionComponent
            defaultExpanded={false}
            label="Historiendaten"
            startIcon={<Feed></Feed>}
            content={
                <div className="flex flex-row single-view-person__all-container">
                    <div className="flex flex-column single-view-person__data-container">
                        <div className="flex flex-row single-view__form-row-width">
                            <SelectComponent
                                selectData={validityTypes}
                                tooltip="Es wurden noch keine Gültigkeits Werte angelegt."
                                className="single-view__text-field"
                                label="Gültigkeitskennzeichen"
                                value={validity?.name || ""}
                                disabled={true}
                                onChange={() => { }}
                                error={false}
                            ></SelectComponent>

                            <SelectComponent
                                selectData={statusTypes}
                                tooltip="Es wurden noch keine Status Werte angelegt."
                                className="single-view__text-field"
                                label="Status"
                                value={status?.name || ""}
                                disabled={true}
                                onChange={() => { }}
                                error={false}
                            ></SelectComponent>
                        </div>
                        <div className="flex flex-row single-view__form-row-width">
                            <TextFieldComponent
                                required={true}
                                id="createTimestamp"
                                label="Zeitstempel Erstellung"
                                value={createTimestamp}
                                type='text'
                                error={false}
                                helperText=""
                                disabled={true}
                            />
                            <TextFieldComponent
                                required={true}
                                id="deleteTimestamp"
                                label="Zeitstempel Löschung"
                                value={deleteTimestamp}
                                type='text'
                                error={false}
                                helperText=""
                                disabled={true}
                            />
                        </div>
                        <div className="flex flex-row single-view__form-row-width">
                            <TextFieldComponent
                                required={true}
                                id="createTransactionID"
                                label="Transaktions ID Erstellung"
                                value={createTransactionID}
                                type='text'
                                error={false}
                                helperText=""
                                disabled={true}
                            />
                            <TextFieldComponent
                                required={true}
                                id="deleteTransactionID"
                                label="Transaktions ID Löschung"
                                value={deleteTransactionID}
                                type='text'
                                error={false}
                                helperText=""
                                disabled={true}
                            />
                        </div>
                        <div className="flex flex-row single-view__form-row-width">
                            <TextFieldComponent
                                required={true}
                                id="createTransactionUserEmail"
                                label="Email Benutzer Erstellung"
                                value={createTransactionUserEmail}
                                type='text'
                                error={false}
                                helperText=""
                                disabled={true}
                            />
                            <TextFieldComponent
                                required={true}
                                id="deleteTransactionUserEmail"
                                label="Email Benutzer Löschung"
                                value={deleteTransactionUserEmail}
                                type='text'
                                error={false}
                                helperText=""
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
            }
        />
    </div>
}
export default HistoryShow;