import { resourceAxiosInstance } from "../../config/axios.config";
import { EmailAPI, PasswordAPI, ProcessAPI, UserAPI, UserAPIRequest } from "../../models/user";

export const fetchUsers = () =>
    resourceAxiosInstance.get<UserAPI[]>(`/user`, {
        cache: false,
    });

export const updateUser = (user: Partial<UserAPIRequest>) =>
    resourceAxiosInstance.patch<UserAPIRequest>(
        `/user/${user.id}`,
        user,
        {
            cache: false,
        }
    );

export const deleteUser = (userId: string | undefined) =>
    resourceAxiosInstance.delete(`/user/${userId}`, {
        cache: false,
    });

export const fetchUser = (userId: string) =>
    resourceAxiosInstance.get<UserAPI>(`/user/${userId}`, {
        cache: false,
    });

export const createUser = (user: Partial<UserAPIRequest>) =>
    resourceAxiosInstance.post<UserAPIRequest>(`/user`, user, {
        cache: false,
    });

export const sendResetPasswordLink = (email: EmailAPI) =>
    resourceAxiosInstance.post<UserAPIRequest>(`/auth/send-reset-password-link`, email, {
        cache: false,
    });

export const validateResetPasswordToken = (token: String) =>
    resourceAxiosInstance.get(`/auth/validate-reset-token/` + token, {
        cache: false,
    });

export const resetPasswordRequest = (token: String, password: PasswordAPI) =>
    resourceAxiosInstance.post(`/auth/reset-password/` + token, password, {
        cache: false,
    });

export const fetchAllProcesses = () =>
    resourceAxiosInstance.get<ProcessAPI[]>(`/user/process`, {
        cache: false,
    });

export const sendEmailVerifyLinkRequest = (email: EmailAPI) =>
    resourceAxiosInstance.post(`/user/verify-email`, email, {
        cache: false,
    });

export const verifyEmail = (token: String) =>
    resourceAxiosInstance.get(`/user/verify-email/` + token, {
        cache: false,
    });

export const saveProcess = (processID: String) =>
    resourceAxiosInstance.post(`/user/process/` + processID, {
        cache: false,
    });

export const importProcess = (processID: String) =>
    resourceAxiosInstance.post(`/user/process/` + processID + "/import", {
        cache: false,
    });

export const cancelProcess = (processID: String) =>
    resourceAxiosInstance.delete(`/user/process/` + processID, {
        cache: false,
    });


