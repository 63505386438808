import AccordionComponent from '../../../../shared/components/accordion-component';
import { Add, Delete, Insights } from '@mui/icons-material';
import TextFieldComponent from '../../../../shared/components/text-field-component';
import { CalculationBasisAmountFlagSelect, CalculationBasisAPI, getCalculationBasisAmountFlag } from '../../../../models/calculation-basis';
import SelectComponent from '../../../../shared/components/select-component';
import { Button, Checkbox, Divider, FormControlLabel, IconButton, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { List } from 'reactstrap';
import { formatDateString } from '../../../../helper/formatter';
import { endingDateSameOrAfterEffectiveDate } from '../../../../helper/validation';

type SelectProps = {
    savePushed: boolean
    disabled: boolean
    formError: boolean;
    validation?: boolean;
    dateFrom: string;
    setDateFrom: (value: string) => void;
    dateUntil: string | null;
    setDateUntil: (value: string | null) => void;
    amount: number;
    setAmount: (value: number) => void;
    amountFlag: CalculationBasisAmountFlagSelect | null;
    setAmountFlag: (value: CalculationBasisAmountFlagSelect | null) => void;
    calculationBasisList: CalculationBasisAPI[];
    setCalculationBasisList: (value: CalculationBasisAPI[]) => void;
};

const CalculationBasisForm = ({
    savePushed,
    disabled,
    formError,
    validation,
    dateFrom,
    setDateFrom,
    dateUntil,
    setDateUntil,
    amount,
    setAmount,
    amountFlag,
    setAmountFlag,
    calculationBasisList,
    setCalculationBasisList

}: SelectProps) => {

    const deleteCalculationBasis = (calc: CalculationBasisAPI) => {
        if (!disabled) {
            const data: CalculationBasisAPI[] = []
            calculationBasisList.forEach((val) => {
                if (val.id !== calc.id) {
                    data.push(val)
                }
            })
            setCalculationBasisList(data.sort((a, b) => a.from > b.from ? 1 : -1))
        }
    }

    const onAddAddToCalculationBasisList = () => {
        if (!formError && endingDateSameOrAfterEffectiveDate(dateUntil, dateFrom)) {
            const data: CalculationBasisAPI[] = calculationBasisList;
            let date;
            date = new Date(dateFrom);
            const fromFormatted = (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
                (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
                date.getFullYear();
            let untilFormatted;
            if (dateUntil !== null) {
                date = new Date(dateUntil);
                untilFormatted = (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
                    (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
                    date.getFullYear();
            } else {
                untilFormatted = "Offen";
            }


            const flag = amountFlag ? amountFlag.id : "";
            const newObject: CalculationBasisAPI = {
                id: "-1",
                amount,
                amountFlag: flag,
                from: dateFrom,
                until: dateUntil,
                fromUntilString: fromFormatted + " - " + untilFormatted,
                status: "",
                client: null
            }
            data.push(newObject);
            setCalculationBasisList(data.sort((a, b) => a.from > b.from ? 1 : -1));
        }
        const today: Date = new Date()
        setDateFrom(today.getFullYear() + '-' + ((today.getMonth() + 1 < 10) ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1)) + '-' + today.getDate());
        setDateUntil(today.getFullYear() + '-' + ((today.getMonth() + 1 < 10) ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1)) + '-' + today.getDate());
        setAmount(0);
        setAmountFlag(null);
    }

    return <div className='single-view-accordion'>
        <AccordionComponent
            defaultExpanded={false}
            label="Berechnungsgrundlagen"
            startIcon={<Insights></Insights>}
            error={formError}
            validation={validation}
            content={
                <div className="flex flex-row single-view-person__all-container">
                    <div className="flex flex-column single-view-person__data-container">
                        {!disabled && <div className="flex flex-column single-view-person__data-container">
                            <div className="flex flex-row single-view__form-row-width single-view-tripple-values">
                                <TextFieldComponent
                                    required={true}
                                    id="dateFrom"
                                    label="Datum Von"
                                    type='date'
                                    value={dateFrom}
                                    error={dateFrom === "" && savePushed}
                                    helperText={
                                        dateFrom === "" && savePushed
                                            ? "Das Von Datum darf nicht leer sein!"
                                            : ""
                                    }
                                    disabled={disabled}
                                    setValueString={setDateFrom}
                                />
                                <TextFieldComponent
                                    required={true}
                                    id="dateUntil"
                                    label="Datum Bis"
                                    type='date'
                                    value={dateUntil}
                                    error={dateUntil === "" && savePushed}
                                    helperText={
                                        dateUntil === "" && savePushed
                                            ? "Das Bis Datum darf nicht leer sein!"
                                            : ""
                                    }
                                    disabled={disabled || dateUntil === null}
                                    setValueString={setDateUntil}
                                />
                                <FormControlLabel
                                    className="grey-border"
                                    labelPlacement="end"
                                    sx={{ marginLeft: 1, marginBottom: 2 }}
                                    control={
                                        <Checkbox
                                            checked={dateUntil === null}
                                            onChange={() => {
                                                if (dateUntil == null) {
                                                    setDateUntil("");
                                                } else {
                                                    setDateUntil(null);
                                                }
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label={
                                        <div className="checklist-label">
                                            <div>{"Offenes Ende"}</div>
                                        </div>
                                    } />
                            </div>
                            <div className="flex flex-row single-view__form-row-width">
                                <TextFieldComponent
                                    required={false}
                                    id="amount"
                                    label="Betrag in €"
                                    value={amount}
                                    type='number'
                                    error={amount < 0 && savePushed}
                                    helperText={
                                        amount < 0 && savePushed
                                            ? "Der abzurechnende Betrag darf nicht negativ sein!"
                                            : ""
                                    }
                                    disabled={disabled}
                                    setValueNumber={setAmount}
                                />
                                <SelectComponent
                                    selectData={getCalculationBasisAmountFlag()}
                                    tooltip="Es wurden noch keine Betragskennzeichen angelegt."
                                    className="single-view__text-field"
                                    label="Abrechnungstyp "
                                    value={amountFlag?.name || ""}
                                    onChange={(value: String) => setAmountFlag(getCalculationBasisAmountFlag().find(({ name }) => name === value) || null)}
                                    disabled={disabled}
                                    error={savePushed && !amountFlag}
                                ></SelectComponent>
                            </div>
                            <Button startIcon={<Add />} variant="contained" size="large" onClick={onAddAddToCalculationBasisList}
                                sx={{
                                    height: 50,
                                    color: "#001D53",
                                    background: "#D4E7FF",
                                    ':hover': {
                                        background: 'primary.main', // theme.palette.primary.main
                                        color: 'white',
                                    },
                                }}>Berechnungsgrundlage hinzfügen</Button>

                        </div>}
                        <List
                            sx={{
                                width: '100%',
                                height: "400px",
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 500,
                                '& ul': { padding: 0 },
                            }}
                            aria-label="av records"
                        >
                            {calculationBasisList.map((value) => {
                                return <div>
                                    <ListItem disablePadding key={value.from + "-" + value.until} sx={{ background: "#EEEEEE" }}>
                                        <ListItemButton onClick={() => deleteCalculationBasis(value)} sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <ListItemText primary={formatDateString(value.from) + " - " + (value.until === null ? "Offen" : formatDateString(value.until)) + " | " + value.amount + "€  als " + getCalculationBasisAmountFlag().find(({ id }) => id === value.amountFlag)?.name} sx={{
                                                color: "#2D333B",

                                            }}
                                                primaryTypographyProps={{
                                                    fontWeight: 'bold',
                                                    fontSize: 14,
                                                }} />
                                            {!disabled && <IconButton edge="end">
                                                <Delete></Delete>
                                            </IconButton >}
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider></Divider>
                                </div>
                            })}
                        </List>
                    </div>
                </div>
            }
        />
    </div >
}
export default CalculationBasisForm;