import SelectComponent from '../../../../shared/components/select-component';
import { InstitutionSelect } from '../../../../models/institution';
import { User } from '../../../../models/user';
import TextFieldComponent from '../../../../shared/components/text-field-component';
import { Insights } from '@mui/icons-material';
import AccordionComponent from '../../../../shared/components/accordion-component';

type SelectProps = {
    user: User | null;
    savePushed: boolean
    disabled: boolean
    aidValues: InstitutionSelect[];
    insuranceValues: InstitutionSelect[];
    pensionInstitutionValues: InstitutionSelect[];
    effectiveDate: string;
    setEffectiveDate: (value: string) => void;
    caringPersonExternalIdentifier: string;
    setCaringPersonExternalIdentifier: (value: string) => void;
    conceptOfOrder: string | null;
    setConceptOfOrder: (value: string | null) => void;
    pensionInstitution: InstitutionSelect | null;
    setPensionInstitution: (value: InstitutionSelect | null) => void;
    insurance: InstitutionSelect | null;
    setInsurance: (value: InstitutionSelect | null) => void;
    aid: InstitutionSelect | null;
    setAid: (value: InstitutionSelect | null) => void;
    formError: boolean;
    validation?: boolean;
};

const CaringPersonForm = ({
    user,
    savePushed,
    disabled,
    aidValues,
    insuranceValues,
    pensionInstitutionValues,
    effectiveDate,
    setEffectiveDate,
    caringPersonExternalIdentifier,
    setCaringPersonExternalIdentifier,
    conceptOfOrder,
    setConceptOfOrder,
    pensionInstitution,
    setPensionInstitution,
    insurance,
    setInsurance,
    aid,
    setAid,
    formError,
    validation

}: SelectProps) => {
    return <div className='single-view-accordion'>
        <AccordionComponent
            defaultExpanded={false}
            label="Berechnungsdaten"
            startIcon={<Insights></Insights>}
            validation={validation}
            error={formError}
            content={
                <div className="flex flex-row single-view-person__all-container">
                    <div className="flex flex-column single-view-person__data-container">
                        <div className="flex flex-row single-view__form-row-width">
                            <TextFieldComponent
                                required={true}
                                id="effectiveDate"
                                label="Wirksamkeitsdatum"
                                type='date'
                                value={effectiveDate}
                                error={effectiveDate === "" && savePushed}
                                helperText={
                                    effectiveDate === "" && savePushed
                                        ? "Das Wirksamkeitsdatum darf nicht leer sein!"
                                        : ""
                                }
                                disabled={disabled}
                                setValueString={setEffectiveDate}
                            />
                            <div className="single-view__empty-form-width"></div>
                        </div>
                        <div className="flex flex-row single-view__form-row-width single-view-empty-container">
                        </div>
                        {user?.userRole !== "USER_ROLE_AID_ADMIN" && user?.userRole !== "USER_ROLE_AID_USER" && <div className="flex flex-row single-view__form-row-width">
                            <SelectComponent
                                selectData={aidValues}
                                tooltip="Es wurden noch keine Beihilfestellen angelegt."
                                className="single-view__text-field"
                                label="Beihilfestelle"
                                value={aid?.name || ""}
                                onChange={(value: string) => setAid(aidValues.find(({ name }) => name === value) || null)}
                                disabled={disabled || user?.userRole === "USER_ROLE_AID_ADMIN" || user?.userRole === "USER_ROLE_AID_USER"}
                                error={savePushed && !aid}
                            ></SelectComponent>
                            <div className="single-view__empty-form-width"></div>
                        </div>}
                        <div className="flex flex-row single-view__form-row-width">
                            <TextFieldComponent
                                required={true}
                                id="caringPersonExternalIdentifier"
                                label="Externer Schlüssel des Pflegers"
                                type='text'
                                value={caringPersonExternalIdentifier}
                                error={caringPersonExternalIdentifier === "" && savePushed}
                                helperText={
                                    caringPersonExternalIdentifier === "" && savePushed
                                        ? "Der Externe Schlüssel des Pflegers darf nicht leer sein!"
                                        : ""
                                }
                                disabled={disabled}
                                setValueString={setCaringPersonExternalIdentifier}
                            />

                        </div>

                        <div className="flex flex-row single-view__form-row-width">
                            <SelectComponent
                                selectData={pensionInstitutionValues}
                                tooltip="Es wurden noch keine Versorgungseinrichtungen angelegt."
                                className="single-view__text-field"
                                label="Versorgungseinrichtung"
                                value={pensionInstitution?.name || ""}
                                onChange={(value: string) => setPensionInstitution(pensionInstitutionValues.find(({ name }) => name === value) || null)}
                                disabled={disabled}
                                notRequired
                                error={false}
                            ></SelectComponent>
                            <TextFieldComponent
                                required={pensionInstitution !== null && pensionInstitution.id !== ""}
                                id="conceptOfOrder"
                                label="Ordnungsbegriff"
                                type='text'
                                value={conceptOfOrder}
                                error={conceptOfOrder === "" && pensionInstitution !== null && pensionInstitution.id !== "" && savePushed}
                                helperText={
                                    conceptOfOrder === "" && pensionInstitution !== null && pensionInstitution.id !== "" && savePushed
                                        ? "Der Ordnungsbegriff darf nicht leer sein, wenn eine Versorgungseinrichtung gewählt wurde!"
                                        : ""
                                }
                                disabled={disabled || pensionInstitution === null}
                                setValueString={setConceptOfOrder}
                            />
                        </div>

                        <div className="flex flex-row single-view__form-row-width">
                            <SelectComponent
                                selectData={insuranceValues}
                                tooltip="Es wurden noch keine Versicherungen angelegt."
                                className="single-view__text-field"
                                label="Versicherung"
                                value={insurance?.name || ""}
                                onChange={(value: string) => setInsurance(insuranceValues.find(({ name }) => name === value) || null)}
                                disabled={disabled || user?.userRole === "USER_ROLE_INSURANCE_ADMIN" || user?.userRole === "USER_ROLE_INSURANCE_USER"}
                                error={savePushed && !insurance}
                            ></SelectComponent>
                        </div>
                    </div>
                </div>
            }
        />
    </div>
}
export default CaringPersonForm;