import {
    Drawer,
    Divider,
    ListItemButton,
    ListItemText,
    Toolbar,
    Box,
    List,
    Link,
    Collapse,
    ListItemIcon,
    SvgIcon
} from "@mui/material";
import {
    AccountCircle,
    AdminPanelSettings,
    AssistWalker,
    Build,
    Business,
    Calculate,
    ExpandLess,
    ExpandMore,
    Healing,
    HealthAndSafety,
    Home,
    Insights,
    Inventory,
    Person,
    SvgIconComponent,
    Update
} from "@mui/icons-material";

import React, { useEffect, useState } from "react";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { closeSideNav } from "../../redux/side-nav.reducer";
import { } from "../../redux/auth.reducer";
import { Link as RouterLink, useLocation } from "react-router-dom";
import "./side-nav.css";

interface Route {
    path: string;
    idx: number;
    name: string;
    icon: SvgIconComponent;
}

function SideNav() {
    const isOpen = useSelector(
        ({ sideNavReducer }: RootState) => sideNavReducer.isOpen
    );

    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const [selectedItem, setSelectedItem] = useState<number>();
    const [adminRoutes, setAdminRoutes] = useState<Route[]>([]);
    const [isAdministrationOpen, setIsAdministrationOpen] =
        useState<boolean>(true);
    const toggleOpenAdministration = () =>
        setIsAdministrationOpen(!isAdministrationOpen);
    const [isInstitutionOpen, setIsInstitutionOpen] =
        useState<boolean>(true);
    const toggleOpenInstitution = () =>
        setIsInstitutionOpen(!isInstitutionOpen);


    const { user } = useSelector(({ authReducer }: RootState) => authReducer);

    const homeRoute: Route = {
        path: "/home",
        idx: 1,
        name: "Home",
        icon: Home
    };

    const independentRoutes: Route[] = [
        {
            path: "/caring-person",
            idx: 2,
            name: "Pflegeperson suchen",
            icon: Person
        },
        {
            path: "/person-in-need-of-care",
            idx: 3,
            name: "Bedürftige/n suchen",
            icon: AssistWalker
        },
        {
            path: "/calculation-basis",
            idx: 4,
            name: "Berechnungsgrundlage suchen",
            icon: Insights
        },
    ];

    const institutionRoutes: Route[] = [
        {
            path: "/aids",
            idx: 11,
            name: "Beihilfestellen & Abrechnungsstellen anzeigen",
            icon: HealthAndSafety
        },
        {
            path: "/insurances",
            idx: 12,
            name: "Versicherungen anzeigen",
            icon: Healing
        },
        {
            path: "/pension-institutions",
            idx: 13,
            name: "Versorgungseinrichtungen anzeigen",
            icon: Inventory
        },

    ];

    const systemAdminRoutes: Route[] = [
        {
            path: "/user",
            idx: 21,
            name: "Benutzerverwaltung",
            icon: AccountCircle
        },
        {
            path: "/institution",
            idx: 22,
            name: "Institutionverwaltung",
            icon: Business
        }
    ];


    const insuranceAdminRoutes: Route[] = [
        {
            path: "/user",
            idx: 30,
            name: "Benutzerverwaltung",
            icon: AccountCircle
        },
        {
            path: "/institution/edit/" + user?.institution.id,
            idx: 31,
            name: "Versicherung Allgemein",
            icon: Healing
        }
    ];

    const aidAdminRoutes: Route[] = [
        {
            path: "/user",
            idx: 40,
            name: "Benutzerverwaltung",
            icon: AccountCircle
        },
        {
            path: "/institution/edit/" + user?.institution.id,
            idx: 41,
            name: "Beihilfe Allgemein",
            icon: HealthAndSafety
        },
        {
            path: "/aid/config/edit/" + user?.institution.id,
            idx: 42,
            name: "Beihilfe Konfiguration",
            icon: Build
        },
        {
            path: "/update-calculation-basis",
            idx: 43,
            name: "Berechnungsgrundlagen aktualisieren",
            icon: Update
        },
        {
            path: "/calculation",
            idx: 44,
            name: "Beitrags-berechnungsläufe",
            icon: Calculate
        }
    ];

    useEffect(() => {
        if (user?.userRole === "USER_ROLE_SYSTEM_ADMIN") {
            setAdminRoutes(systemAdminRoutes);
        } else if (user?.userRole === "USER_ROLE_AID_ADMIN") {
            setAdminRoutes(aidAdminRoutes);
        } else if (user?.userRole === "USER_ROLE_INSURANCE_ADMIN") {
            setAdminRoutes(insuranceAdminRoutes);
        }
        const idx = [...independentRoutes, ...systemAdminRoutes, ...insuranceAdminRoutes, ...aidAdminRoutes, ...institutionRoutes].find(
            (route) => pathname.includes(route.path)
        )?.idx;
        setSelectedItem(idx);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const list = () => (
        <Box sx={{ width: 250, height: "100%" }}>
            <List className="side-nav__flex-content">
                <List component="div" disablePadding>
                    <Link
                        component={RouterLink}
                        to={homeRoute.path}
                        sx={{ textDecoration: "none" }}
                        key={homeRoute.idx}
                    >
                        <ListItemButton
                            selected={selectedItem === homeRoute.idx}
                            sx={{ pl: 2, mb: 1 }}
                            onClick={() => setSelectedItem(homeRoute.idx)}
                        >
                            {selectedItem === homeRoute.idx && <ListItemIcon><SvgIcon component={homeRoute.icon} className="filter-white"></SvgIcon></ListItemIcon>}
                            {selectedItem !== homeRoute.idx && <ListItemIcon><SvgIcon component={homeRoute.icon}></SvgIcon></ListItemIcon>}
                            <ListItemText primary={homeRoute.name} />
                        </ListItemButton>
                    </Link>
                    <Divider className="mt-2"></Divider>
                    {independentRoutes.map(({ idx, path, name, icon }) => (
                        <Link
                            component={RouterLink}
                            to={path}
                            sx={{ textDecoration: "none" }}
                            key={idx}
                        >
                            <ListItemButton
                                selected={selectedItem === idx}
                                sx={{ pl: 2, mb: 1 }}
                                onClick={() => setSelectedItem(idx)}
                            >
                                {selectedItem === idx && <ListItemIcon><SvgIcon component={icon} className="filter-white"></SvgIcon></ListItemIcon>}
                                {selectedItem !== idx && <ListItemIcon><SvgIcon component={icon}></SvgIcon></ListItemIcon>}
                                <ListItemText primary={name} />
                            </ListItemButton>
                        </Link>
                    ))}
                    <Divider className="mt-2"></Divider>
                    <ListItemButton onClick={toggleOpenInstitution} sx={{ pl: 2, mt: 1 }}>
                        <ListItemIcon><Business /></ListItemIcon>
                        <ListItemText primary="Institutionen" />
                        {isInstitutionOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse
                        in={isInstitutionOpen}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding>
                            {
                                institutionRoutes.map(({ idx, path, name, icon }) => (
                                    <Link
                                        component={RouterLink}
                                        to={path}
                                        sx={{ textDecoration: "none" }}
                                        key={idx}
                                    >
                                        <ListItemButton
                                            selected={selectedItem === idx}
                                            sx={{ pl: 4 }}
                                            onClick={() => setSelectedItem(idx)}
                                        >
                                            {selectedItem === idx && <ListItemIcon><SvgIcon component={icon} className="filter-white"></SvgIcon></ListItemIcon>}
                                            {selectedItem !== idx && <ListItemIcon><SvgIcon component={icon}></SvgIcon></ListItemIcon>}
                                            <ListItemText primary={name} />
                                        </ListItemButton>
                                    </Link>
                                ))
                            }
                        </List>
                    </Collapse>
                    {(user?.userRole === "USER_ROLE_SYSTEM_ADMIN" || user?.userRole === "USER_ROLE_INSURANCE_ADMIN" || user?.userRole === "USER_ROLE_AID_ADMIN") &&
                        <Divider className="mt-2"></Divider>}
                    {(user?.userRole === "USER_ROLE_SYSTEM_ADMIN" || user?.userRole === "USER_ROLE_INSURANCE_ADMIN" || user?.userRole === "USER_ROLE_AID_ADMIN") &&
                        <ListItemButton onClick={toggleOpenAdministration} sx={{ pl: 2, mt: 1 }}>
                            <ListItemIcon><AdminPanelSettings /></ListItemIcon>
                            <ListItemText primary="Administration" />
                            {isAdministrationOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                    }
                    <Collapse
                        in={isAdministrationOpen}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding>
                            {
                                adminRoutes.map(({ idx, path, name, icon }) => (
                                    <Link
                                        component={RouterLink}
                                        to={path}
                                        sx={{ textDecoration: "none" }}
                                        key={idx}
                                    >
                                        <ListItemButton
                                            selected={selectedItem === idx}
                                            sx={{ pl: 4 }}
                                            onClick={() => setSelectedItem(idx)}
                                        >
                                            {selectedItem === idx && <ListItemIcon><SvgIcon component={icon} className="filter-white"></SvgIcon></ListItemIcon>}
                                            {selectedItem !== idx && <ListItemIcon><SvgIcon component={icon}></SvgIcon></ListItemIcon>}
                                            <ListItemText primary={name} />
                                        </ListItemButton>
                                    </Link>
                                ))
                            }
                        </List>
                    </Collapse>
                </List>
            </List>
        </Box>
    );
    return (
        <div>
            <React.Fragment key="left">
                <Drawer
                    variant="persistent"
                    anchor="left"
                    open={isOpen}
                    onClose={() => dispatch(closeSideNav())}
                >
                    <Toolbar />
                    {list()}
                    <p className="side-nav__version">
                        AidPlus Version: {process.env.REACT_APP_VERSION}
                    </p>
                </Drawer>
            </React.Fragment>
        </div>
    );
}

export default SideNav;
