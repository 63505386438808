import { useEffect } from 'react';
import { Divider, Button } from "@mui/material";
import PageLayout from "../../../../shared/components/page-layout";
import "../../details.css";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import { } from "../../../../redux/auth.reducer";
import { StatusSelect, getStatusValues, getValidityValues } from '../../../../models/historization';
import { InstitutionSelect } from '../../../../models/institution';
import { AddressAPIRequest } from '../../../../models/address';
import AddressForm from '../../../../shared/components/address-form/address-form';
import { GenderType, PersonAPIRequest, getGenderType } from '../../../../models/person';
import { fetchAids, fetchInsurances, fetchPensionInstitutions } from '../../../../shared/requests/institution.requests';
import PersonForm from '../../../../shared/components/person-form/person_form';
import SubmitButtonsHistory from '../../../../shared/components/standard-actions/submit-buttons/submit-button-history';
import { AssistWalker, Delete, Feed, Summarize } from '@mui/icons-material';
import CrudTable, { ColumnDefinitionType } from '../../../../shared/components/crud-table/crud-table';
import { Sort } from '../../../../hooks/useSort.hook';
import { SortingDirection } from '../../../../enums/sort-by';
import { TimeLineItemModelHistory } from '../../../../models/timeline';
import Timeline from '../../../../shared/components/time-line';
import { CalculationCaringPersonAPI, CaringPersonAPIRequest, CaringPersonHistoryAPI } from '../../../../models/caring-person';
import { createCaringPerson, deleteCaringPerson, fetchAllCalculationsForCaringPerson, fetchAllPersonConnectionForCaringPerson, fetchCaringPerson, fetchHistoryForCaringPerson, fetchSingleHistoryForCaringPerson, updateCaringPerson } from '../../../../shared/requests/caring-person.requests';
import CaringPersonForm from '../form/caring-person-form';
import ConfirmCancelDialog from '../../../../shared/components/standard-actions/dialogs/confirm-cancel-dialog';
import { isAidUser } from '../../../../models/user';
import AccordionComponent from '../../../../shared/components/accordion-component';
import { isValidPensionInsuranceNumber, isValidPostalcode } from '../../../../helper/validation';
import { CountryData, getCountries } from '../../../../shared/components/select-component-country';
import { PersonConnectionAPI } from '../../../../models/person-connection';
import { getFromUntilStringFromCalculationBasisList } from '../../../../helper/formatter';
import InputErrorDialog from '../../../../shared/components/standard-actions/dialogs/input-error-dialog';
import { cancelProcess, saveProcess } from '../../../../shared/requests/user.requests';
import HistoryLine from '../../../../shared/components/history_line';
import { addToHistory, HistoryTimelineItemModel } from '../../../../models/history';

function CaringPerson() {
    //Select Listen
    const [historyData, setHistoryData] = useState<CaringPersonHistoryAPI[]>([]);
    const [calculations, setCalculations] = useState<CalculationCaringPersonAPI[]>([]);
    const [personConnection, setPersonConnection] = useState<PersonConnectionAPI[]>([]);
    const [statusValues, setStatusValues] = useState<StatusSelect[]>([]);
    const [aidValues, setAidValues] = useState<InstitutionSelect[]>([]);
    const [insuranceValues, setInsuranceValues] = useState<InstitutionSelect[]>([]);
    const [pensionInstitutionValues, setPensionInstitutionValues] = useState<InstitutionSelect[]>([]);
    //Pflegeperson extra Daten
    const [effectiveDate, setEffectiveDate] = useState<string>("");
    const [caringPersonExternalIdentifier, setCaringPersonExternalIdentifier] = useState<string>("");
    const [pensionInstitution, setPensionInstitution] = useState<InstitutionSelect | null>({
        id: "",
        name: "Keine Versorgungseinrichtung gewählt!",
        realValue: -1,
        type: "NoType"
    });
    const [conceptOfOrder, setConceptOfOrder] = useState<string | null>(null);
    const [insurance, setInsurance] = useState<InstitutionSelect | null>(null);
    const [aid, setAid] = useState<InstitutionSelect | null>(null);
    //Personendaten
    const [lastname, setLastname] = useState<string>("");
    const [birthName, setBirthName] = useState<string | null>("");
    const [firstname, setFirstname] = useState<string>("");
    const [placeOfBirth, setPlaceOfBirth] = useState<string>("");
    const [countryOfBirth, setCountryOfBirth] = useState<CountryData | null>(null);
    const [dateOfBirth, setDateOfBirth] = useState<string | null>("");
    const [gender, setGender] = useState<GenderType | null>(null);
    const [namePrefix, setNamePrefix] = useState<string | null>("");
    const [title, setTitle] = useState<string | null>("");
    const [nationality, setNationality] = useState<CountryData | null>(null);
    const [pensionInsuranceNumber, setPensionInsuranceNumber] = useState<string | null>("");
    //Addressdaten
    const [street, setStreet] = useState<string>("");
    const [houseNumber, setHouseNumber] = useState<number | null>(null);
    const [country, setCountry] = useState<CountryData | null>(null);
    const [postalCode, setPostalCode] = useState<string>("");
    const [location, setLocation] = useState<string>("");
    const [poBox, setPOBox] = useState<string>("");
    const [poBoxPostalCode, setPOBoxPostalCode] = useState<string>("");
    const [addressSupplement, setAddressSupplement] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [fax, setFax] = useState<string>("");
    const [email, setEmail] = useState<string>("");

    //Systemdaten
    const [savePushed, setSavePushed] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [showInputErrorDialog, setShowInputErrorDialog] = useState<boolean>(false);
    const [leavePath, setLeavePath] = useState<string>("/");
    const [chronoItems, setChronoItems] = useState<TimeLineItemModelHistory[]>([]);
    const [historyChronoItems, setHistoryChronoItems] = useState<HistoryTimelineItemModel[]>([]);
    const [defaultChronoIndex, setDefaultChronoIndex] = useState<number>(0);
    const [selectedItem, setSelectedItem] = useState<TimeLineItemModelHistory | null>(null);
    const { user } = useSelector(({ authReducer }: RootState) => authReducer);
    const [isCurrentRendered, setCurrentRendered] = useState<boolean>(false);
    const { id, history, processId } = useParams();
    const useAddress: boolean = street !== "" || (houseNumber !== null && houseNumber > 0) || (country !== null && country.value !== "") || postalCode !== "" || location !== "" || poBox !== "" || addressSupplement !== "" || phoneNumber !== "" || fax !== "" || email !== ""
    const errorCaringPerson: boolean = effectiveDate === "" || caringPersonExternalIdentifier === ""
        || insurance === null || aid === null || ((pensionInstitution !== null && pensionInstitution.id !== "") && (conceptOfOrder === "" || conceptOfOrder === null));
    const errorPerson: boolean = lastname === "" || firstname === "" || gender === null || pensionInsuranceNumber === "" || pensionInsuranceNumber === null || !isValidPensionInsuranceNumber(pensionInsuranceNumber);
    const errorAddress: boolean = useAddress && (country === null || postalCode === "" || !isValidPostalcode(postalCode, country));
    const errorInput: boolean = errorAddress || errorCaringPerson || errorPerson;
    const navigate = useNavigate();

    const goBackToOverview = () => {
        navigate(`/caring-person`);
    }

    const goBackToHome = () => {
        navigate(`/`);
    }

    useEffect(() => {
        var today = new Date();
        setStatusValues(getStatusValues())
        fetchAids().then((response) => {
            let institutions = response.data;
            let institutionsSelect: InstitutionSelect[] = [];
            institutions.forEach((institution) => {
                if (institution.id === user?.institution.id) {
                    setAid({
                        id: String(institution.id),
                        name: institution.name,
                        realValue: +institution.id,
                        type: institution.type
                    });
                }
                institutionsSelect.push({
                    id: String(institution.id),
                    name: institution.name,
                    realValue: +institution.id,
                    type: institution.type
                })
            })
            setAidValues(institutionsSelect);
        })
        fetchPensionInstitutions().then((response) => {
            let institutions = response.data;
            let institutionsSelect: InstitutionSelect[] = [];
            institutionsSelect.push({
                id: "",
                name: "Keine Versorgungseinrichtung gewählt!",
                realValue: -1,
                type: "NoType"
            })
            institutions.forEach((institution) => {
                if (institution.id === user?.id) {
                    setAid({
                        id: String(institution.id),
                        name: institution.name,
                        realValue: +institution.id,
                        type: institution.type
                    });
                }
                institutionsSelect.push({
                    id: String(institution.id),
                    name: institution.name,
                    realValue: +institution.id,
                    type: institution.type
                })
            })
            setPensionInstitutionValues(institutionsSelect);
        })
        fetchInsurances().then((response) => {
            let institutions = response.data;
            let institutionsSelect: InstitutionSelect[] = [];
            institutions.forEach((institution) => {
                if (institution.id === user?.institution.id) {
                    setInsurance({
                        id: String(institution.id),
                        name: institution.name,
                        realValue: +institution.id,
                        type: institution.type
                    });
                }
                institutionsSelect.push({
                    id: String(institution.id),
                    name: institution.name,
                    realValue: +institution.id,
                    type: institution.type
                })
            })
            setInsuranceValues(institutionsSelect);
        })
        if (id) {
            if (!history) {
                fetchSinglePersonAPI(id);
            } else {
                fetchSinglePersonHistoryAPI(id, history);
            }
        } else {
            const elementCountry = getCountries().find((element) => { return "" === element.value; });
            if (elementCountry) {
                setCountry(elementCountry)
                setCountryOfBirth(elementCountry)
            }
            setEffectiveDate(today.getFullYear() + '-' + ((today.getMonth() + 1 < 10) ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1)) + '-' + today.getDate())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, history, processId]);

    const getUserTitle = (): string => {
        const createUserTitle = "Pflegeperson erstellen";
        const editUserTitle = "Pflegeperson bearbeiten";
        if (id) {
            return editUserTitle;
        }
        return createUserTitle;
    };

    const getSavingButtonText = (): string => {
        const createEmployeeText = "Erstellen";
        const editEmployeeText = "Speichern";
        if (id) {
            return editEmployeeText;
        }
        return createEmployeeText;
    };

    const fetchSinglePersonAPI = (id: string) => {
        fetchCaringPerson(id).then((response) => {
            setEffectiveDate(response.data.effectiveDate);
            setCaringPersonExternalIdentifier(response.data.caringPersonExternalIdentifier)
            if (response.data.pensionInstitution !== null) {
                setPensionInstitution({
                    id: response.data.pensionInstitution.id,
                    name: response.data.pensionInstitution.name,
                    realValue: +response.data.pensionInstitution.id,
                    type: response.data.pensionInstitution.type
                });
            }
            setConceptOfOrder(response.data.conceptOfOrder);
            setInsurance({
                id: response.data.insurance.id,
                name: response.data.insurance.name,
                realValue: +response.data.insurance.id,
                type: response.data.insurance.type
            })
            setAid({
                id: response.data.client.id,
                name: response.data.client.name,
                realValue: +response.data.client.id,
                type: response.data.client.type
            })
            // Addressdaten setzen
            if (response.data.address != null) {
                setStreet(response.data.address.street);
                setHouseNumber(response.data.address.houseNumber);
                const elementCountry = getCountries().find((element) => { return response.data.address?.country === element.value; });
                if (elementCountry) {
                    setCountry(elementCountry)
                }
                setPostalCode(response.data.address.postalCode);
                setLocation(response.data.address.location);
                setPOBox(response.data.address.poBox);
                setPOBoxPostalCode(response.data.address.poBoxPostalCode);
                setAddressSupplement(response.data.address.addressSupplement);
                setPhoneNumber(response.data.address.phoneNumber);
                setFax(response.data.address.fax);
                setEmail(response.data.address.email);
            } else {
                const elementCountry = getCountries().find((element) => { return "" === element.value; });
                if (elementCountry) {
                    setCountry(elementCountry)
                }
            }

            //Personendaten setzen
            setFirstname(response.data.person.firstname)
            setLastname(response.data.person.lastname)
            setBirthName(response.data.person.birthName)
            setPlaceOfBirth(response.data.person.placeOfBirth)
            const elementCountryBirth = getCountries().find((element) => { return response.data.person.countryOfBirth === element.value; });
            if (elementCountryBirth) {
                setCountryOfBirth(elementCountryBirth)
            } else {
                const elementCountry = getCountries().find((element) => { return "" === element.value; });
                if (elementCountry) {
                    setCountryOfBirth(elementCountry)
                }
            }
            setDateOfBirth(response.data.person.dateOfBirth)
            setNamePrefix(response.data.person.namePrefix)
            setTitle(response.data.person.title)
            const elementNationality = getCountries().find((element) => { return response.data.person.nationality === element.value; });
            if (elementNationality) {
                setNationality(elementNationality)
            } else {
                const elementCountry = getCountries().find((element) => { return "" === element.value; });
                if (elementCountry) {
                    setNationality(elementCountry)
                }
            }
            setPensionInsuranceNumber(response.data.person.pensionInsuranceNumber)
            const elementGender = getGenderType().find((element) => { return response.data.person.gender === element.id; });
            if (elementGender) {
                setGender(elementGender)
            }
            fetchHistoryData(id, response.data.effectiveDate);
            fetchPersonConnectionForCaringPerson(response.data.id);
            if (!isCurrentRendered) {
                const historyData: HistoryTimelineItemModel[] = [];
                addToHistory({
                    type: "Pflegeperson",
                    name: `${response.data.person.lastname}, ${response.data.person.firstname}`,
                    url: `/caring-person/edit/${id}/${processId}/process`
                }).forEach((element => {
                    historyData.push({
                        type: element.type,
                        title: element.name,
                        url: element.url
                    })
                }))
                setHistoryChronoItems(historyData);
            }
        });
        setCurrentRendered(true);
    }

    const fetchSinglePersonHistoryAPI = (id: string, historyNumber: string) => {
        fetchSingleHistoryForCaringPerson(id, historyNumber).then((response) => {
            setEffectiveDate(response.data.effectiveDate);
            setCaringPersonExternalIdentifier(response.data.caringPersonExternalIdentifier)
            if (response.data.pensionInstitution !== null) {
                setPensionInstitution({
                    id: response.data.pensionInstitution.id,
                    name: response.data.pensionInstitution.name,
                    realValue: +response.data.pensionInstitution.id,
                    type: response.data.pensionInstitution.type
                });
            }
            setConceptOfOrder(response.data.conceptOfOrder);
            setInsurance({
                id: response.data.insurance.id,
                name: response.data.insurance.name,
                realValue: +response.data.insurance.id,
                type: response.data.insurance.type
            })
            setAid({
                id: response.data.client.id,
                name: response.data.client.name,
                realValue: +response.data.client.id,
                type: response.data.client.type
            })
            // Addressdaten setzen
            if (response.data.address != null) {
                setStreet(response.data.address.street);
                setHouseNumber(response.data.address.houseNumber);
                const elementCountry = getCountries().find((element) => { return response.data.address?.country === element.value; });
                if (elementCountry) {
                    setCountry(elementCountry)
                }
                setPostalCode(response.data.address.postalCode);
                setLocation(response.data.address.location);
                setPOBox(response.data.address.poBox);
                setPOBoxPostalCode(response.data.address.poBoxPostalCode);
                setAddressSupplement(response.data.address.addressSupplement);
                setPhoneNumber(response.data.address.phoneNumber);
                setFax(response.data.address.fax);
                setEmail(response.data.address.email);
            } else {
                const elementCountry = getCountries().find((element) => { return "" === element.value; });
                if (elementCountry) {
                    setCountry(elementCountry)
                }
            }

            //Personendaten setzen
            setFirstname(response.data.person.firstname)
            setLastname(response.data.person.lastname)
            setBirthName(response.data.person.birthName)
            setPlaceOfBirth(response.data.person.placeOfBirth)
            const elementCountryBirth = getCountries().find((element) => { return response.data.person.countryOfBirth === element.value; });
            if (elementCountryBirth) {
                setCountryOfBirth(elementCountryBirth)
            } else {
                const elementCountry = getCountries().find((element) => { return "" === element.value; });
                if (elementCountry) {
                    setCountryOfBirth(elementCountry)
                }
            }
            setDateOfBirth(response.data.person.dateOfBirth)
            setNamePrefix(response.data.person.namePrefix)
            setTitle(response.data.person.title)
            const elementNationality = getCountries().find((element) => { return response.data.person.nationality === element.value; });
            if (elementNationality) {
                setNationality(elementNationality)
            } else {
                const elementCountry = getCountries().find((element) => { return "" === element.value; });
                if (elementCountry) {
                    setNationality(elementCountry)
                }
            }
            setPensionInsuranceNumber(response.data.person.pensionInsuranceNumber)
            const elementGender = getGenderType().find((element) => { return response.data.person.gender === element.id; });
            if (elementGender) {
                setGender(elementGender)
            }
            fetchHistoryData(id, response.data.effectiveDate)
            fetchPersonConnectionForCaringPerson(response.data.id);
            if (!isCurrentRendered) {
                const historyData: HistoryTimelineItemModel[] = [];
                addToHistory({
                    type: "Pflegeperson",
                    name: `${response.data.person.lastname}, ${response.data.person.firstname}`,
                    url: `/caring-person/edit/${id}/${historyNumber}/${processId}/process`
                }).forEach((element => {
                    historyData.push({
                        type: element.type,
                        title: element.name,
                        url: element.url
                    })
                }))
                setHistoryChronoItems(historyData);
            }
        });
        setCurrentRendered(true);
    }

    const fetchHistoryData = (id: string, effDate: string) => {
        fetchHistoryForCaringPerson(id).then((response2) => {
            const data: CaringPersonHistoryAPI[] = []
            const chron: TimeLineItemModelHistory[] = [];
            let date: Date;
            let index: number = 0;
            response2.data.forEach(element => {
                if (element.deleteTransaction === null) {
                    date = new Date(Date.parse(element.effectiveDate))
                    const item = {
                        title: date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear(),
                        validity: element.validity,
                        identifier: element.id,
                        historyNumber: element.historyNumber,
                        status: element.status,
                    }
                    chron.push(item)
                    if (history && !isCurrentRendered && String(history) === String(element.historyNumber)) {
                        setDefaultChronoIndex(index)
                        setSelectedItem(item)
                    }
                    if (!history && !isCurrentRendered && element.effectiveDate === effDate) {
                        setDefaultChronoIndex(index)
                        setSelectedItem(item)
                    }
                    index++;
                }
                data.push(element);
                if (element.deleteTimestamp === null) {
                    element.deleteTimestamp = "";
                }
            })
            setHistoryData(data);
            setChronoItems(chron);
            if (chron.length < 1) {
                goBackToOverview();
            }
            fetchAllCalculationsForCaringPerson(id).then((response2) => {
                setCalculations(response2.data);
            });
        });
    }

    const fetchPersonConnectionForCaringPerson = (caringPersonId: string) => {
        fetchAllPersonConnectionForCaringPerson(caringPersonId).then((response2) => {
            setPersonConnection(response2.data)
        });
    }

    const onTimeLineItemSelected = (index: number) => {
        const valueCasted: TimeLineItemModelHistory = chronoItems[index]
        setSelectedItem(valueCasted)
        fetchSinglePersonHistoryAPI(valueCasted.identifier, valueCasted.historyNumber);
    }



    const saveCaringPerson = (status: StatusSelect | null, processSave: boolean, route?: string) => {
        let address: AddressAPIRequest | null = null;
        if (useAddress) {
            let coun = country ? country.value : "";
            address = {
                effectiveDate,
                houseNumber,
                country: coun,
                postalCode,
                poBox,
                poBoxPostalCode,
                addressSupplement,
                location,
                phoneNumber,
                fax,
                email,
                street,
            };
        } else {
            address = null;
        }

        const gen = gender ? gender.id : ""
        const nation = nationality && nationality.value !== "" ? nationality.value : null
        const count = countryOfBirth && countryOfBirth.value !== "" ? countryOfBirth.value : null;
        const dob = dateOfBirth !== "" ? dateOfBirth : null;
        const person: PersonAPIRequest = {
            lastname,
            birthName,
            firstname,
            placeOfBirth,
            countryOfBirth: count,
            dateOfBirth: dob,
            gender: gen,
            namePrefix,
            title,
            nationality: nation,
            pensionInsuranceNumber
        };

        const stat = status ? status.id : "";
        const insuranceIdentifier = insurance ? insurance.realValue : 0;
        const aidIdentifier = aid ? aid.realValue : 0;
        const penInstitutionId = pensionInstitution && pensionInstitution.id !== "" ? pensionInstitution.realValue : null;
        const processIdentifier = processId ? processId : "-1"

        const caringPerson: CaringPersonAPIRequest = {
            id: id ? id : "",
            effectiveDate,
            status: stat,
            caringPersonExternalIdentifier,
            pensionInstitutionId: penInstitutionId,
            conceptOfOrder,
            insuranceIdentifier,
            aidIdentifier,
            person,
            address,
            processIdentifier
        };
        setSavePushed(true);
        if (!errorInput && status !== null) {
            if (id) {
                updateCaringPerson(caringPerson).then(() => {
                    if (processSave === true && processId) {
                        saveProcess(processId).then(() => {
                            goBackToHome()
                        })
                    } else {
                        if (route) {
                            navigate(route);
                        }
                        else {
                            goBackToOverview()
                        }
                    }
                }).catch(console.error);
            } else {
                createCaringPerson(caringPerson).then(() => {
                    if (processSave === true && processId) {
                        saveProcess(processId).then(() => {
                            goBackToHome()
                        })
                    } else {
                        if (route) {
                            navigate(route);
                        }
                        else {
                            goBackToOverview()
                        }
                    }
                }).catch(console.error);
            }
        }
    };
    const onClickHistoryShow = (person: CaringPersonHistoryAPI) => {
        onClickSaveAndOpenPath(`/caring-person/edit/${id}/${processId}/process/show/${person.historyNumber}`)
    }

    const onClickPersonInNeedOfCareEdit = (personConnection: PersonConnectionAPI) => {
        if (processId) {
            onClickSaveAndOpenPath(`/person-in-need-of-care/edit/${personConnection.personInNeedOfCare.id}/${processId}`)
        } else {
            onClickSaveAndOpenPath(`/person-in-need-of-care/edit/${personConnection.personInNeedOfCare.id}`)
        }
    }

    const onClickCalculationBasisEdit = (personConnection: PersonConnectionAPI) => {
        if (processId) {
            onClickSaveAndOpenPath(`/calculation-basis/edit/${personConnection.id}/${processId}`)
        } else {
            onClickSaveAndOpenPath(`/calculation-basis/edit/${personConnection.id}`)
        }
    }

    const onClickSaveAndOpenPath = (path: string) => {
        if (!errorInput) {
            saveCaringPerson(statusValues.find(({ id }) => id === "STATUS_FLOATING") || null, false, path);
        } else {
            setLeavePath(path);
            setShowInputErrorDialog(true);
        }
    }

    const onSave = () => {
        saveCaringPerson(statusValues.find(({ id }) => id === "STATUS_EFFECTIVE") || null, true)
    }

    const onEditCancel = () => {
        if (processId) {
            cancelProcess(processId).then(() => { goBackToHome() });
        } else {
            goBackToHome()
        }
    }

    const actions = (
        <SubmitButtonsHistory
            submitText={getSavingButtonText()}
            cancelText="Verwerfen"
            pauseText="Unterbrechen"
            onClickSubmit={onSave}
            onClickCancel={onEditCancel}
            onClickPause={() => {
                saveCaringPerson(statusValues.find(({ id }) => id === "STATUS_FLOATING") || null, false);
            }}
        />
    );
    const deleteEntry = () => {
        setShowDeleteDialog(true);
    }

    const onDeleteCancel = () => {
        setShowDeleteDialog(false);
    };

    const onDeleteSubmit = () => {
        if (selectedItem != null) {
            setCurrentRendered(false);
            deleteCaringPerson(selectedItem?.identifier, selectedItem?.historyNumber).then(() => {
                fetchHistoryData(selectedItem?.identifier, effectiveDate);
                setShowDeleteDialog(false);
            });

        }
    };

    const onLeaveSiteCancel = () => {
        setShowInputErrorDialog(false);
    };

    const onLeaveSiteSubmit = () => {
        setShowInputErrorDialog(false);
        navigate(leavePath);
    };

    const columnsCaringPersonHistory: ColumnDefinitionType<CaringPersonHistoryAPI, keyof CaringPersonHistoryAPI>[] = [
        {
            key: "historyNumber",
            header: "Historiennummer",
        },
        {
            key: "conceptOfOrder",
            header: "Ord. Begriff",
        },
        {
            key: "effectiveDate",
            header: "Wirksamkeitsdatum",
        },
        {
            key: "createTimestamp",
            header: "Zeitstempel Erstellung",
        },
        {
            key: "deleteTimestamp",
            header: "Zeitstempel Löschung",
        },
    ];

    const columnsPersonConnection: ColumnDefinitionType<PersonConnectionAPI, keyof PersonConnectionAPI>[] = [
        {
            key: "personInNeedOfCareName",
            header: "Name, Vorname, Geburtsdatum",
        },
        {
            key: "fromUntilString",
            header: "Beziehung Von - Bis",
        },
    ];

    const columnsCalculationn: ColumnDefinitionType<CalculationCaringPersonAPI, keyof CalculationCaringPersonAPI>[] = [
        {
            key: "year",
            header: "Kalenderjahr",
        },
        {
            key: "fromUntilString",
            header: "Monat(e)",
        },
        {
            key: "personInNeedOfCareName",
            header: "Bedürftige(r)",
        },
        {
            key: "calculationBasis",
            header: "Berechnungsbasis",
        },
        {
            key: "rvSumString",
            header: "Summe (D)RV-Beitrag",
        },
        {
            key: "avSumString",
            header: "Summe AV-Beitrag",
        },
        {
            key: "abvSumString",
            header: "Summe ABV-Beitrag",
        },
    ];

    const [sortByCaringPersonHistory] = useState<Sort<CaringPersonHistoryAPI>>({
        key: "historyNumber",
        direction: SortingDirection.Descending,
    });

    const [sortByPersonConnection] = useState<Sort<PersonConnectionAPI>>({
        key: "personInNeedOfCareName",
        direction: SortingDirection.Descending,
    });

    return (
        <PageLayout title={getUserTitle()} actions={actions}>
            <ConfirmCancelDialog
                showDialog={showDeleteDialog}
                dialogTitle="Pflegeperson Eintrag löschen?"
                dialogInfo={`Möchten Sie den Eintrag mit Wirksamkeitsdatum
                    ${selectedItem?.title} wirklich löschen?`}
                onClickCancel={onDeleteCancel}
                onClickSubmit={() => onDeleteSubmit()}
            />
            <InputErrorDialog
                showDialog={showInputErrorDialog}
                dialogTitle="Berechnungsgrundlageneintrag löschen?"
                dialogInfo={`Die/Der Bedürftige/r kann nicht gespeichert werden, da das Formular nicht richtig ausgefüllt wurde. Soll die Seite trotzdem verlassen werden?`}
                onClickCancel={onLeaveSiteCancel}
                onClickSubmit={() => onLeaveSiteSubmit()}
            />
            <div className="single-view">
                {id && <HistoryLine
                    items={historyChronoItems}
                    setCurrentRendered={setCurrentRendered}
                />}
                {id && <Timeline
                    items={chronoItems}
                    defaultChronoIndex={defaultChronoIndex}
                    onItemSelected={onTimeLineItemSelected}
                />}
                {(id && user && isAidUser(user)) && <div className='single-view-delete-button-container'><Button variant="contained" color="primary" className="delete-entry-button" onClick={deleteEntry} sx={{ maxWidth: 1 / 6, }}>
                    <Delete sx={{ mr: 1 }}></Delete>
                    Eintrag löschen
                </Button>
                </div>}
                <PersonForm
                    savePushed={savePushed}
                    disabled={false}
                    lastname={lastname}
                    setLastname={setLastname}
                    birthName={birthName}
                    setBirthName={setBirthName}
                    firstname={firstname}
                    setFirstname={setFirstname}
                    placeOfBirth={placeOfBirth}
                    setPlaceOfBirth={setPlaceOfBirth}
                    countryOfBirth={countryOfBirth}
                    setCountryOfBirth={setCountryOfBirth}
                    dateOfBirth={dateOfBirth}
                    setDateOfBirth={setDateOfBirth}
                    gender={gender}
                    setGender={setGender}
                    namePrefix={namePrefix}
                    setNamePrefix={setNamePrefix}
                    title={title}
                    setTitle={setTitle}
                    nationality={nationality}
                    setNationality={setNationality}
                    pensionInsuranceNumber={pensionInsuranceNumber}
                    setPensionInsuranceNumber={setPensionInsuranceNumber}
                    formError={errorPerson}
                    pensionInsuranceNumberRequired
                    validation
                />
                <AddressForm
                    savePushed={savePushed}
                    disabled={false}
                    street={street}
                    setStreet={setStreet}
                    houseNumber={houseNumber}
                    setHouseNumber={setHouseNumber}
                    postalCode={postalCode}
                    setPostalCode={setPostalCode}
                    location={location}
                    setLocation={setLocation}
                    country={country}
                    setCountry={setCountry}
                    poBox={poBox}
                    setPOBox={setPOBox}
                    poBoxPostalCode={poBoxPostalCode}
                    setPOBoxPostalCode={setPOBoxPostalCode}
                    addressSupplement={addressSupplement}
                    setAddressSupplement={setAddressSupplement}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    fax={fax}
                    setFax={setFax}
                    email={email}
                    setEmail={setEmail}
                    useAddress={useAddress}
                    formError={errorAddress}
                    validation
                />
                <CaringPersonForm
                    aid={aid}
                    aidValues={aidValues}
                    pensionInstitutionValues={pensionInstitutionValues}
                    caringPersonExternalIdentifier={caringPersonExternalIdentifier}
                    conceptOfOrder={conceptOfOrder}
                    disabled={false}
                    effectiveDate={effectiveDate}
                    insurance={insurance}
                    insuranceValues={insuranceValues}
                    pensionInstitution={pensionInstitution}
                    savePushed={savePushed}
                    setAid={setAid}
                    setCaringPersonExternalIdentifier={setCaringPersonExternalIdentifier}
                    setConceptOfOrder={setConceptOfOrder}
                    setEffectiveDate={setEffectiveDate}
                    setInsurance={setInsurance}
                    setPensionInstitution={setPensionInstitution}
                    user={user}
                    formError={errorCaringPerson}
                    validation
                />
                <div className='single-view-accordion'>
                    {id && <Divider variant='fullWidth' sx={{ mt: 3, mb: 3 }} ></Divider>}
                    {id &&
                        <div className='single-view-accordion'>
                            <AccordionComponent
                                label="Bedürftige Beziehungen"
                                defaultExpanded={false}
                                startIcon={<AssistWalker></AssistWalker>}
                                content={
                                    <CrudTable
                                        tableData={personConnection}
                                        columns={columnsPersonConnection}
                                        sortBy={sortByPersonConnection}
                                        fetchElementsWithId={fetchAllPersonConnectionForCaringPerson}
                                        dataFormatter={(element: PersonConnectionAPI) => {
                                            var date;
                                            if (element.caringPerson.person.dateOfBirth != null) {
                                                date = new Date(element.caringPerson.person.dateOfBirth);
                                                element.caringPerson.person.dateOfBirth = (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
                                                    (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
                                                    date.getFullYear();
                                            }
                                            if (element.personInNeedOfCare.person.dateOfBirth != null) {
                                                date = new Date(element.personInNeedOfCare.person.dateOfBirth);
                                                element.personInNeedOfCare.person.dateOfBirth = (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
                                                    (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
                                                    date.getFullYear();
                                            }
                                            element.caringPersonName = element.caringPerson.person.lastname + ", " + element.caringPerson.person.firstname + (element.caringPerson.person.dateOfBirth !== null ? " *" + element.caringPerson.person.dateOfBirth : "")
                                            element.personInNeedOfCareName = element.personInNeedOfCare.person.lastname + ", " + element.personInNeedOfCare.person.firstname + (element.personInNeedOfCare.person.dateOfBirth != null ? " *" + element.personInNeedOfCare.person.dateOfBirth : "")
                                            const elementValidty = getValidityValues().find((ele) => { return element.validity === ele.id; });
                                            element.validityString = elementValidty ? elementValidty.name : "Kein Gültigkeitskennzeichen"
                                            element.fromUntilString = getFromUntilStringFromCalculationBasisList(element.calculationBasisList)
                                            return element;
                                        }}
                                        callOnPersonConnectionClick={onClickCalculationBasisEdit}
                                        callOnPersonInNeedOfCareClick={onClickPersonInNeedOfCareEdit}
                                        showGender
                                        searchingId={id}
                                    />
                                }
                            />
                        </div>
                    }
                    {id &&
                        <div className='single-view-accordion'>
                            <AccordionComponent
                                label="Beitragswerte"
                                defaultExpanded={false}
                                startIcon={<Summarize></Summarize>}
                                content={
                                    <CrudTable
                                        tableData={calculations}
                                        columns={columnsCalculationn}
                                        sortBy={sortByCaringPersonHistory}
                                        fetchElementsWithId={fetchAllCalculationsForCaringPerson}
                                        dataFormatter={(element: CalculationCaringPersonAPI) => {
                                            element.fromUntilString = (element.startMonth ? element.startMonth : 1) + "-" + (element.endMonth ? element.endMonth : 12)
                                            element.personInNeedOfCareName = element.personInNeedOfCare.person.lastname + ", " + element.personInNeedOfCare.person.firstname + (element.personInNeedOfCare.person.dateOfBirth != null ? " *" + element.personInNeedOfCare.person.dateOfBirth : "")
                                            element.rvSumString = element.rvSum + "€"
                                            element.avSumString = element.avSum + "€"
                                            element.abvSumString = element.abvSum + "€"
                                            return element;
                                        }}
                                        hideSearchbar
                                        searchingId={id}
                                    />
                                }
                            />
                        </div>
                    }
                    {id &&
                        <div className='single-view-accordion'>
                            <AccordionComponent
                                label="Historie"
                                defaultExpanded={false}
                                startIcon={<Feed></Feed>}
                                content={
                                    <CrudTable
                                        tableData={historyData}
                                        columns={columnsCaringPersonHistory}
                                        sortBy={sortByCaringPersonHistory}
                                        fetchElementsWithId={fetchHistoryForCaringPerson}
                                        callOnFindInPageClick={onClickHistoryShow}
                                        dataFormatter={(element: CaringPersonHistoryAPI) => {
                                            var date = new Date(element.createTimestamp);
                                            element.createTimestamp = (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
                                                (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
                                                date.getFullYear() + " " +
                                                (date.getHours() < 10 ? "0" : "") + date.getHours() + ":" +
                                                (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
                                            date = new Date(element.effectiveDate);
                                            element.effectiveDate = (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
                                                (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
                                                date.getFullYear();
                                            if (element.deleteTimestamp === null) {
                                                element.deleteTimestamp = "";
                                            } else {
                                                date = new Date(element.deleteTimestamp);
                                                element.deleteTimestamp = (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
                                                    (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
                                                    date.getFullYear() + " " +
                                                    (date.getHours() < 10 ? "0" : "") + date.getHours() + ":" +
                                                    (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
                                            }

                                            return element;
                                        }}
                                        hideSearchbar
                                        searchingId={id}
                                    />
                                }
                            />
                        </div>
                    }
                </div>
            </div>
        </PageLayout>
    );
}

export default CaringPerson;
