import { useEffect } from 'react';
import { Box, Button, Divider } from "@mui/material";
import PageLayout from "../../../../shared/components/page-layout";
import "../../details.css";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { StatusSelect, getStatusValues } from '../../../../models/historization';
import SubmitButtonsHistory from '../../../../shared/components/standard-actions/submit-buttons/submit-button-history';
import CrudTable, { ColumnDefinitionType } from '../../../../shared/components/crud-table/crud-table';
import { Sort } from '../../../../hooks/useSort.hook';
import { SortingDirection } from '../../../../enums/sort-by';
import { CaringPersonHistoryAPI, CaringPersonSelect } from '../../../../models/caring-person';
import { createPersonConnection, fetchPersonConnection, fetchHistoryForPersonConnection, fetchSingleHistoryForPersonConnection, updatePersonConnection } from '../../../../shared/requests/calculation-basis.requests';
import { PersonInNeedOfCareSelect } from '../../../../models/person-in-need-of-care';
import { fetchPersonsInNeedOfCare } from '../../../../shared/requests/person-in-need-of-care.requests';
import { fetchCaringPersons } from '../../../../shared/requests/caring-person.requests';
import { } from "../../../../redux/auth.reducer";
import AccordionComponent from '../../../../shared/components/accordion-component';
import { endingDateSameOrAfterEffectiveDate } from '../../../../helper/validation';
import { PersonConnectionAPIRequest, PersonConnectionHistoryAPI } from '../../../../models/person-connection';
import PersonConnectionForm from '../form/person-connection-form';
import { AssistWalker, Person, Summarize } from '@mui/icons-material';
import CalculationBasisForm from '../form/calculation-basis-form';
import { CalculationBasisAmountFlagSelect, CalculationBasisAPI } from '../../../../models/calculation-basis';
import InputErrorDialog from '../../../../shared/components/standard-actions/dialogs/input-error-dialog';
import { cancelProcess, saveProcess } from '../../../../shared/requests/user.requests';
import { addToHistory, getHistory, HistoryEntry, HistoryTimelineItemModel } from '../../../../models/history';
import HistoryLine from '../../../../shared/components/history_line';

function PersonConnection() {
    //Select Listen
    const [historyData, setHistoryData] = useState<PersonConnectionHistoryAPI[]>([]);
    const [statusValues, setStatusValues] = useState<StatusSelect[]>([]);
    const [caringPersonValues, setCaringPersonValues] = useState<CaringPersonSelect[]>([]);
    const [personInNeedOfCareValues, setPersonInNeedOfCareValues] = useState<PersonInNeedOfCareSelect[]>([]);
    //Berechnungsgrundlage extra Daten
    const [effectiveDate, setEffectiveDate] = useState<string>("");
    const [endingDate, setEndingDate] = useState<string | null>(null);
    const [amount, setAmount] = useState<number>(0.0);
    const [amountFlag, setAmountFlag] = useState<CalculationBasisAmountFlagSelect | null>(null);
    const [caringPerson, setCaringPerson] = useState<CaringPersonSelect | null>(null);
    const [personInNeedOfCare, setPersonInNeedOfCare] = useState<PersonInNeedOfCareSelect | null>(null);
    const [calculationBasisList, setCalculationBasisList] = useState<CalculationBasisAPI[]>([]);


    //Systemdaten
    const [savePushed, setSavePushed] = useState<boolean>(false);
    const [historyChronoItems, setHistoryChronoItems] = useState<HistoryTimelineItemModel[]>([]);
    const [isCurrentRendered, setCurrentRendered] = useState<boolean>(false);
    const { id, history, processId } = useParams();
    const [showInputErrorDialog, setShowInputErrorDialog] = useState<boolean>(false);
    const [leavePath, setLeavePath] = useState<string>("/");
    const errorConnection: boolean = caringPerson === null || personInNeedOfCare === null
    const errorCalculationBasis: boolean = effectiveDate === "" || endingDate === "" || !endingDateSameOrAfterEffectiveDate(endingDate, effectiveDate) || amount < 0 || amountFlag === null;
    const errorInput: boolean = errorConnection

    const navigate = useNavigate();

    const goBackToOverview = () =>
        navigate(`/calculation-basis`);

    const goBackToHome = () => {
        navigate(`/`);
    }

    useEffect(() => {
        var today = new Date();
        setStatusValues(getStatusValues())
        fetchPersonsInNeedOfCare().then((response) => {
            let personsInNeedOfCare = response.data;
            let personsSelect: PersonInNeedOfCareSelect[] = [];
            personsInNeedOfCare.forEach((person) => {
                personsSelect.push({
                    id: String(person.id),
                    name: person.person.lastname + ", " + person.person.firstname,
                    realValue: +person.id,
                    genderId: person.person.gender
                })
            })
            setPersonInNeedOfCareValues(personsSelect)
        });
        fetchCaringPersons().then((response) => {
            let caringPersons = response.data;
            let personsSelect: CaringPersonSelect[] = [];
            caringPersons.forEach((person) => {
                personsSelect.push({
                    id: String(person.id),
                    name: person.person.lastname + ", " + person.person.firstname,
                    realValue: +person.id,
                    genderId: person.person.gender
                })
            })
            setCaringPersonValues(personsSelect)
        });
        if (id) {
            if (!history) {
                fetchSinglePersonAPI(id);
            } else {
                fetchSinglePersonHistoryAPI(id, history);
            }
        }
        setEffectiveDate(today.getFullYear() + '-' + ((today.getMonth() + 1 < 10) ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1)) + '-' + today.getDate())
        setEndingDate(null)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, history, processId]);

    const getUserTitle = (): string => {
        const createUserTitle = "Berechnungsgrundlage erstellen";
        const editUserTitle = "Berechnungsgrundlage bearbeiten";
        if (id) {
            return editUserTitle;
        }
        return createUserTitle;
    };

    const getSavingButtonText = (): string => {
        const createEmployeeText = "Erstellen";
        const editEmployeeText = "Speichern";
        if (id) {
            return editEmployeeText;
        }
        return createEmployeeText;
    };

    const fetchSinglePersonAPI = (id: string) => {
        fetchPersonConnection(id).then((response) => {
            setCaringPerson({
                id: response.data.caringPerson.id,
                name: response.data.caringPerson.person.lastname + ", " + response.data.caringPerson.person.firstname,
                realValue: +response.data.caringPerson.id,
                genderId: response.data.caringPerson.person.gender
            });
            setPersonInNeedOfCare({
                id: response.data.personInNeedOfCare.id,
                name: response.data.personInNeedOfCare.person.lastname + ", " + response.data.personInNeedOfCare.person.firstname,
                realValue: +response.data.personInNeedOfCare.id,
                genderId: response.data.personInNeedOfCare.person.gender
            });
            setCalculationBasisList(response.data.calculationBasisList.sort((a, b) => a.from > b.from ? 1 : -1))
            fetchHistoryData(id)
            if (!isCurrentRendered) {
                const historyData: HistoryTimelineItemModel[] = [];
                addToHistory({
                    type: "Berechnungsgrundlage",
                    name: `${response.data.personInNeedOfCare.person.lastname}, ${response.data.personInNeedOfCare.person.firstname} | ${response.data.caringPerson.person.lastname}, ${response.data.caringPerson.person.firstname}`,
                    url: `/calculation-basis/edit/${id}/${processId}/process`
                }).forEach((element => {
                    historyData.push({
                        type: element.type,
                        title: element.name,
                        url: element.url
                    })
                }))
                setHistoryChronoItems(historyData);
            }
        });
        setCurrentRendered(true);
    }

    const fetchSinglePersonHistoryAPI = (id: string, historyNumber: string) => {
        fetchSingleHistoryForPersonConnection(id, historyNumber).then((response) => {
            setCaringPerson({
                id: response.data.caringPerson.id,
                name: response.data.caringPerson.person.lastname + ", " + response.data.caringPerson.person.firstname,
                realValue: +response.data.caringPerson.id,
                genderId: response.data.caringPerson.person.gender
            });
            setPersonInNeedOfCare({
                id: response.data.personInNeedOfCare.id,
                name: response.data.personInNeedOfCare.person.lastname + ", " + response.data.personInNeedOfCare.person.firstname,
                realValue: +response.data.personInNeedOfCare.id,
                genderId: response.data.personInNeedOfCare.person.gender
            });
            setCalculationBasisList(response.data.calculationBasisList.sort((a, b) => a.from > b.from ? 1 : -1))
            fetchHistoryData(id)
            if (!isCurrentRendered) {
                const historyData: HistoryTimelineItemModel[] = [];
                addToHistory({
                    type: "Berechnungsgrundlage",
                    name: `${response.data.personInNeedOfCare.person.lastname}, ${response.data.personInNeedOfCare.person.firstname} | ${response.data.caringPerson.person.lastname}, ${response.data.caringPerson.person.firstname}`,
                    url: `/calculation-basis/edit/${id}/${historyNumber}/${processId}/process`
                }).forEach((element => {
                    historyData.push({
                        type: element.type,
                        title: element.name,
                        url: element.url
                    })
                }))
                setHistoryChronoItems(historyData);
            }
        });
        setCurrentRendered(true);
    }

    const fetchHistoryData = (id: string) => {
        fetchHistoryForPersonConnection(id).then((response2) => {
            const data: PersonConnectionHistoryAPI[] = []
            response2.data.forEach(element => {
                if (element.status === "STATUS_FLOATING" && !history) {
                    const newHistory: HistoryEntry[] = getHistory();
                    newHistory.pop();
                    localStorage.history = JSON.stringify(newHistory);
                    navigate(`/calculation-basis/edit/${id}/${element.historyNumber}/${processId}`)
                }
                data.push(element);
                if (element.deleteTimestamp === null) {
                    element.deleteTimestamp = "";
                }
            })
            setHistoryData(data);
        });
    }



    const savePersonConnection = (status: StatusSelect | null, processSave: boolean, route?: string) => {
        const caringPersonId = caringPerson ? caringPerson.id : "-1";
        const personInNeedOfCareId = personInNeedOfCare ? personInNeedOfCare.id : "-1";
        const stat = status ? status.id : "";
        const processIdentifier = processId ? processId : "-1"
        const personConnection: PersonConnectionAPIRequest = {
            id: id ? id : "-1",
            status: stat,
            caringPersonId,
            personInNeedOfCareId,
            calculationBasisList: calculationBasisList,
            processIdentifier

        };
        console.log(personConnection)
        setSavePushed(true);
        if (!errorInput && status !== null) {
            if (id) {
                updatePersonConnection(personConnection).then(() => {
                    if (processSave === true && processId) {
                        saveProcess(processId).then(() => {
                            goBackToHome()
                        })
                    } else {
                        if (route) {
                            navigate(route);
                        }
                        else {
                            goBackToOverview()
                        }
                    }
                }).catch(console.error);
            } else {
                createPersonConnection(personConnection).then(() => {
                    if (processSave === true && processId) {
                        saveProcess(processId).then(() => {
                            goBackToHome()
                        })
                    } else {
                        if (route) {
                            navigate(route);
                        }
                        else {
                            goBackToOverview()
                        }
                    }
                }).catch(console.error);
            }
        }
    };

    const onClickHistoryShow = (person: PersonConnectionHistoryAPI) => {
        onClickSaveAndOpenPath(`/calculation-basis/edit/${id}/${processId}/process/show/${person.historyNumber}`)
    }

    const onClickCaringPersonEdit = () => {
        if (caringPerson) {
            if (processId) {
                onClickSaveAndOpenPath(`/caring-person/edit/${caringPerson.id}/${processId}`)
            } else {
                onClickSaveAndOpenPath(`/caring-person/edit/${caringPerson.id}`)
            }
        }
    }

    const onClickPersonInNeedOfCareEdit = () => {
        if (personInNeedOfCare) {
            if (processId) {
                onClickSaveAndOpenPath(`/person-in-need-of-care/edit/${personInNeedOfCare.id}/${processId}`)
            } else {
                onClickSaveAndOpenPath(`/person-in-need-of-care/edit/${personInNeedOfCare.id}`)
            }
        }
    }

    const onClickSaveAndOpenPath = (path: string) => {
        if (!errorInput) {
            savePersonConnection(statusValues.find(({ id }) => id === "STATUS_FLOATING") || null, false, path);
        } else {
            setLeavePath(path);
            setShowInputErrorDialog(true);
        }
    }

    const onSave = () => {
        savePersonConnection(statusValues.find(({ id }) => id === "STATUS_EFFECTIVE") || null, true)
    }

    const onEditCancel = () => {
        if (processId) {
            cancelProcess(processId).then(() => { goBackToHome() });
        } else {
            goBackToHome()
        }
    }

    const actions = (
        <SubmitButtonsHistory
            submitText={getSavingButtonText()}
            cancelText="Verwerfen"
            pauseText="Unterbrechen"
            onClickSubmit={onSave}
            onClickCancel={onEditCancel}
            onClickPause={() => {
                savePersonConnection(statusValues.find(({ id }) => id === "STATUS_FLOATING") || null, false);
            }}
        />
    );

    const onLeaveSiteCancel = () => {
        setShowInputErrorDialog(false);
    };

    const onLeaveSiteSubmit = () => {
        setShowInputErrorDialog(false);
        navigate(leavePath);
    };

    const columns: ColumnDefinitionType<PersonConnectionHistoryAPI, keyof PersonConnectionHistoryAPI>[] = [
        {
            key: "historyNumber",
            header: "Historiennummer",
        },
        {
            key: "createTimestamp",
            header: "Zeitstempel Erstellung",
        },
        {
            key: "deleteTimestamp",
            header: "Zeitstempel Löschung",
        },
    ];

    const [sortBy] = useState<Sort<CaringPersonHistoryAPI>>({
        key: "historyNumber",
        direction: SortingDirection.Descending,
    });

    return (
        <PageLayout title={getUserTitle()} actions={actions}>
            <InputErrorDialog
                showDialog={showInputErrorDialog}
                dialogTitle="Berechnungsgrundlageneintrag löschen?"
                dialogInfo={`Die/Der Bedürftige/r kann nicht gespeichert werden, da das Formular nicht richtig ausgefüllt wurde. Soll die Seite trotzdem verlassen werden?`}
                onClickCancel={onLeaveSiteCancel}
                onClickSubmit={() => onLeaveSiteSubmit()}
            />
            <div className="single-view">
                {id && <HistoryLine
                    items={historyChronoItems}
                    setCurrentRendered={setCurrentRendered}
                />}

                <PersonConnectionForm
                    caringPerson={caringPerson}
                    caringPersonValues={caringPersonValues}
                    disabled={id ? true : false}
                    personInNeedOfCare={personInNeedOfCare}
                    personInNeedOfCareValues={personInNeedOfCareValues}
                    savePushed={savePushed}
                    setCaringPerson={setCaringPerson}
                    setPersonInNeedOfCare={setPersonInNeedOfCare}
                    formError={errorConnection}
                    validation
                />

                <CalculationBasisForm
                    disabled={false}
                    savePushed={savePushed}
                    formError={errorCalculationBasis}
                    dateFrom={effectiveDate}
                    dateUntil={endingDate}
                    amount={amount}
                    amountFlag={amountFlag}
                    setDateFrom={setEffectiveDate}
                    setDateUntil={setEndingDate}
                    setAmount={setAmount}
                    setAmountFlag={setAmountFlag}
                    calculationBasisList={calculationBasisList}
                    setCalculationBasisList={setCalculationBasisList}
                />
                {id && <Divider variant='fullWidth' sx={{ mt: 3, mb: 3 }} ></Divider>}
                {id &&
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        margin="auto"
                        sx={{ '& button': { mr: 10, mt: 2 } }}>
                        <Button startIcon={<Person />} variant="contained" size="large" onClick={onClickCaringPersonEdit}
                            sx={{
                                color: "#001D53",
                                background: "#D4E7FF",
                                ':hover': {
                                    background: 'primary.main',
                                    color: 'white',
                                },
                            }}>Pflegeperson bearbeiten</Button>
                        <Button startIcon={<AssistWalker />} variant="contained" size="large" onClick={onClickPersonInNeedOfCareEdit}
                            sx={{
                                color: "#001D53",
                                background: "#D4E7FF",
                                ':hover': {
                                    background: 'primary.main',
                                    color: 'white',
                                },
                            }}>Bedürftige/r bearbeiten</Button>
                    </Box>
                }
                {id &&
                    <div className='single-view-accordion'>
                        <AccordionComponent
                            label="Berechnungen"
                            defaultExpanded={false}
                            startIcon={<Summarize></Summarize>}
                            content={
                                <div>
                                    TODO ausfüllen!
                                </div>
                            }
                        />
                    </div>
                }
                {id &&
                    <div className='single-view-accordion'>
                        <AccordionComponent
                            label="Historie der Berechnungsgrundlage"
                            defaultExpanded={false}
                            content={
                                <CrudTable
                                    tableData={historyData}
                                    columns={columns}
                                    sortBy={sortBy}
                                    fetchElementsWithId={fetchHistoryForPersonConnection}
                                    dataFormatter={(element: PersonConnectionHistoryAPI) => {
                                        var date = new Date(element.createTimestamp);
                                        element.createTimestamp = (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
                                            (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
                                            date.getFullYear() + " " +
                                            (date.getHours() < 10 ? "0" : "") + date.getHours() + ":" +
                                            (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
                                        if (element.deleteTimestamp === null) {
                                            element.deleteTimestamp = "";
                                        } else {
                                            date = new Date(element.deleteTimestamp);
                                            element.deleteTimestamp = (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
                                                (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
                                                date.getFullYear() + " " +
                                                (date.getHours() < 10 ? "0" : "") + date.getHours() + ":" +
                                                (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
                                        }


                                        return element;
                                    }}
                                    callOnFindInPageClick={onClickHistoryShow}
                                    hideSearchbar
                                    searchingId={id}
                                />
                            }
                        />
                    </div>
                }
            </div>
        </PageLayout>
    );
}

export default PersonConnection;
