import { useEffect } from 'react';
import { Accordion, AccordionDetails, Divider } from "@mui/material";
import PageLayout from "../../../../shared/components/page-layout";
import "../../details.css";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    UserAPIRequest,
    UserRoles,
    UserActive,
    getUserActiveTypes,
    getAllUserRoles,
    getAidUserRoles,
    getInsuranceUserRoles,
    getSystemUserRoles
} from "../../../../models/user";
import { AppDispatch, RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { userInformationRequest } from "../../../../redux/auth.reducer";
import { } from "../../../../redux/auth.reducer";
import SubmitButtons from "../../../../shared/components/standard-actions/submit-buttons/submit-button";
import {
    createUser,
    fetchUser,
    updateUser,
} from "../../../../shared/requests/user.requests";
import {
    fetchInstitutionsWithSystemWithoutPensionInstitutions,
} from "../../../../shared/requests/institution.requests";
import SelectComponent from "../../../../shared/components/select-component";
import { InstitutionSelect } from '../../../../models/institution';
import TextFieldComponent from '../../../../shared/components/text-field-component';
import { isValidEmail } from '../../../../helper/validation';
import { clearHistory } from '../../../../models/history';

function User() {
    const dispatch = useDispatch<AppDispatch>();
    const [userRoles, setUserRoles] = useState<UserRoles[]>([]);
    const [institutions, setInstitutions] = useState<InstitutionSelect[]>([]);
    const [activeValues, setActiveValues] = useState<UserActive[]>([]);
    const [firstname, setFirstName] = useState<string>("");
    const [lastname, setLastname] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [userRole, setUserRole] = useState<UserRoles | null>(null);
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [active, setActive] = useState<UserActive | null>({
        id: "true",
        name: "Benutzerkonto aktiviert",
        realValue: true
    });
    const [institution, setInstitution] = useState<InstitutionSelect | null>(null);

    const [savePushed, setSavePushed] = useState<boolean>(false);
    const { user } = useSelector(({ authReducer }: RootState) => authReducer);
    const { id } = useParams();
    const errorInput: boolean =
        firstname === "" || lastname === "" || email === "" || userRole?.name === "" || !institution?.id || userRole === null
        || (password === "" && !id) || (confirmPassword === "" && !id) || password !== confirmPassword
        || !isValidEmail(email);

    const navigate = useNavigate();

    const goBackToOverview = () => {
        dispatch(userInformationRequest())
        if (user && id && String(user.id) === String(id)) {
            navigate(`/home`);
        } else {
            navigate(`/user`);
        }
    }

    useEffect(() => {
        clearHistory();
        fetchInstitutionsWithSystemWithoutPensionInstitutions().then((response) => {
            let institutions = response.data;
            let institutionsSelect: InstitutionSelect[] = [];
            institutions.forEach((institution) => {
                institutionsSelect.push({
                    id: String(institution.id),
                    name: institution.name,
                    realValue: +institution.id,
                    type: institution.type
                })
            })
            setInstitutions(institutionsSelect);
        })
        setActiveValues(getUserActiveTypes());

        if (id) {
            fetchUser(id).then((response) => {
                setFirstName(response.data.firstname);
                setLastname(response.data.lastname);
                setEmail(response.data.email);
                const element = getAllUserRoles().find((element) => { return response.data.userRole === element.id; });
                if (element) {
                    setUserRole(element);
                }
                const elementActive = getUserActiveTypes().find((element) => { return response.data.active === element.realValue; });
                if (elementActive) {
                    setActive(elementActive);
                }
                const institutionFound: InstitutionSelect | null = {
                    id: response.data.institution.id,
                    name: response.data.institution.name,
                    realValue: +response.data.institution.id,
                    type: response.data.institution.type
                }
                setInstitution(institutionFound)
                setPossibleUserRole(institutionFound);
            });
        } else if (user !== null && user?.userRole !== "USER_ROLE_SYSTEM_ADMIN") {
            const institutionOfUser: InstitutionSelect | null = {
                id: user.institution.id,
                name: user.institution.name,
                realValue: +user.institution.id,
                type: user.institution.type
            }
            setInstitution(institutionOfUser)
            setPossibleUserRole(institutionOfUser);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUserTitle = (): string => {
        const createUserTitle = "Benutzer erstellen";
        const editUserTitle = "Benutzer bearbeiten";
        if (id) {
            return editUserTitle;
        }
        return createUserTitle;
    };

    const getSavingButtonText = (): string => {
        const createUserText = "Erstellen";
        const editUserText = "Speichern";
        if (id) {
            return editUserText;
        }
        return createUserText;
    };

    const handleInputChangeUserRole = (value: string) => {
        setUserRole(userRoles.find(({ name }) => name === value) || null);
    };

    const handleInputChangeInstitution = (value: string) => {
        const institutionToChangeTo: InstitutionSelect | null = institutions.find(({ name }) => name === value) || null
        setInstitution(institutionToChangeTo);
        setPossibleUserRole(institutionToChangeTo);
    };

    const handleInputChangeActive = (value: string) => {
        setActive(activeValues.find(({ name }) => name === value) || null);
    };

    const setPossibleUserRole = (institutionToChangeTo: InstitutionSelect | null) => {
        let roles: UserRoles[] = []
        if (user?.userRole === "USER_ROLE_SYSTEM_ADMIN") {
            if (institutionToChangeTo !== null && institutionToChangeTo.type === "INSTITUTION_TYPE_AID") {
                roles = getAidUserRoles();
            } else if (institutionToChangeTo !== null && (institutionToChangeTo.type === "INSTITUTION_TYPE_PRIVATE_HEALTH_INSURANCE" || institutionToChangeTo.type === "INSTITUTION_TYPE_STATUTORY_HEALTH_INSURANCE")) {
                roles = getInsuranceUserRoles();
            } else if (institutionToChangeTo !== null && institutionToChangeTo.type === "INSTITUTION_TYPE_SYSTEM") {
                roles = getSystemUserRoles();
            }

        }
        if (user?.userRole === "USER_ROLE_AID_ADMIN" || user?.userRole === "USER_ROLE_AID_USER") {
            roles = getAidUserRoles();
        }
        if (user?.userRole === "USER_ROLE_INSURANCE_ADMIN" || user?.userRole === "USER_ROLE_INSURANCE_USER") {
            roles = getInsuranceUserRoles();
        }
        setUserRoles(roles);
    }



    const saveUser = () => {
        const role = userRole ? userRole.id : ""
        const ins = institution ? institution.realValue : -1
        const realActive = active ? active.realValue : false;
        const mail = email.toLowerCase();
        const user: UserAPIRequest = {
            id: id ? id : "",
            firstname,
            lastname,
            password,
            email: mail,
            userRole: role,
            institutionID: ins,
            active: realActive

        };

        setSavePushed(true);
        if (!errorInput) {
            if (id) {
                updateUser(user).then(goBackToOverview).catch(console.error);
            } else {
                createUser(user).then(goBackToOverview).catch(console.error);
            }
        }
    };

    const actions = (
        <SubmitButtons
            submitText={getSavingButtonText()}
            cancelText="Abbrechen"
            onClickSubmit={saveUser
            }
            onClickCancel={goBackToOverview}
        />
    );

    return (
        <PageLayout title={getUserTitle()} actions={actions}>
            <Accordion defaultExpanded={true}>
                <AccordionDetails sx={{ paddingTop: 2 }}>
                    <div className="single-view">
                        <div className="flex flex-row single-view__form-row-width">
                            <TextFieldComponent
                                required={true}
                                id="firstname"
                                label="Vorname"
                                value={firstname}
                                type='text'
                                error={firstname === "" && savePushed}
                                helperText={
                                    firstname === "" && savePushed
                                        ? "Der Vorname darf nicht leer sein!"
                                        : ""
                                }
                                disabled={false}
                                setValueString={setFirstName}
                            />
                            <TextFieldComponent
                                required={true}
                                id="name"
                                label="Nachname"
                                value={lastname}
                                type='text'
                                error={lastname === "" && savePushed}
                                helperText={
                                    lastname === "" && savePushed
                                        ? "Der Nachname darf nicht leer sein!"
                                        : ""
                                }
                                disabled={false}
                                setValueString={setLastname}
                            />
                        </div>
                        <div className="flex flex-row single-view__form-row-width">
                            <TextFieldComponent
                                required={true}
                                id="email"
                                label="Email-Adresse"
                                value={email}
                                type='email'
                                error={(email === "" && savePushed) || (!isValidEmail(email) && savePushed)}
                                helperText={
                                    email === "" && savePushed
                                        ? "Die Email darf nicht leer sein!"
                                        : !isValidEmail(email) && savePushed
                                            ? "Der eingegebene Wert ist keine Email Adresse!"
                                            : ""
                                }
                                disabled={false}
                                setValueString={setEmail}
                            />
                            <SelectComponent
                                selectData={activeValues}
                                tooltip="Es wurden noch keine Aktivitätswert angelegt."
                                className="single-view__text-field"
                                label="Kontostatus"
                                value={active?.name || ""}
                                onChange={handleInputChangeActive}
                                disabled={String(user?.id) === String(id) || user?.userRole === "USER_ROLE_AID_USER" || user?.userRole === "USER_ROLE_INSURANCE_USER"}
                                error={false}
                            ></SelectComponent>

                        </div>

                        <div className="flex flex-row single-view__form-row-width">
                            <SelectComponent
                                selectData={institutions}
                                tooltip="Es wurden noch keine Institution angelegt."
                                className="single-view__text-field"
                                label="Institution"
                                value={institution?.name || ""}
                                onChange={handleInputChangeInstitution}
                                disabled={user?.userRole !== "USER_ROLE_SYSTEM_ADMIN" || String(user?.id) === String(id)}
                                error={savePushed && !institution}
                            ></SelectComponent>

                            <SelectComponent
                                selectData={userRoles}
                                tooltip="Bitte zuerst eine Institution wählen!"
                                className="single-view__text-field"
                                label="Benutzerrolle"
                                value={userRole?.name || ""}
                                onChange={handleInputChangeUserRole}
                                disabled={String(user?.id) === String(id) || user?.userRole === "USER_ROLE_AID_USER" || user?.userRole === "USER_ROLE_INSURANCE_USER"}
                                error={savePushed && !userRole}
                            ></SelectComponent>
                        </div>
                        <div className="flex flex-column single-view__form-row-width">
                            {id &&
                                <Divider className='info-divider' />
                            }
                            {id &&
                                <label className='info-label'>Neues Passwort wählen?</label>
                            }
                            <div className="flex flex-row single-view__form-row-width">
                                <TextFieldComponent
                                    required={true}
                                    id="password"
                                    label="Passwort"
                                    value={password}
                                    type='password'
                                    error={(password === "" && savePushed && !id) || (confirmPassword !== password && savePushed)}
                                    helperText={
                                        password === "" && savePushed && !id
                                            ? "Das Passwort darf nicht leer sein!"
                                            : confirmPassword !== password && savePushed ?
                                                "Die Passwörter müssen übereinstimmen!" : ""
                                    }
                                    disabled={false}
                                    setValueString={setPassword}
                                />
                                <TextFieldComponent
                                    required={true}
                                    id="confirmPassword"
                                    label="Passwort bestätigen"
                                    value={confirmPassword}
                                    type='password'
                                    error={(confirmPassword === "" && savePushed && !id) || (confirmPassword !== password && savePushed)}
                                    helperText={
                                        confirmPassword === "" && savePushed && !id
                                            ? "Das Passwort zum bestätigen darf nicht leer sein!"
                                            : confirmPassword !== password && savePushed ?
                                                "Die Passwörter müssen übereinstimmen!" : ""
                                    }
                                    disabled={false}
                                    setValueString={setConfirmPassword}
                                />
                            </div>
                        </div>

                    </div>
                </AccordionDetails>
            </Accordion>
        </PageLayout>
    );
}

export default User;
