import {
    Tooltip,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from "@mui/material";
import countryList from "react-select-country-list";

type SelectProps = {
    className: string;
    label: string;
    value: CountryData | null;
    setCountry: (value: CountryData | null) => void;
    disabled: boolean;
    error: boolean;
    helperText?: string;
    notRequired?: boolean;
    setValueChanged?: (val: boolean) => void;
};

const SelectComponentCountry = ({
    className,
    label,
    value,
    setCountry,
    disabled,
    error,
    helperText,
    notRequired,
    setValueChanged
}: SelectProps) => {
    const countries: CountryData[] = getCountries();
    return (
        <FormControl className={className}>
            <InputLabel id="dropdown" sx={{
                color: "#001D53",
                "& .MuiFormLabel-asterisk": { color: "red" },
            }} required={!notRequired}>{label}</InputLabel>
            {(!countries.length && (
                <Tooltip title="Es existieren keine Länder!">
                    <Select labelId="dropdown" label={label} value="" disabled />
                </Tooltip>
            )) || (
                    <Select
                        sx={{
                            input: { color: '#2D3335' },
                            "& .MuiInputBase-input": { backgroundColor: "#EEEEEE" },
                            "& .MuiFormLabel-asterisk": { color: "red" },
                        }}
                        labelId="dropdown"
                        label={label}
                        id="dropdown"
                        value={value ? value.label ? value.label : "" : ""}
                        variant='filled'
                        disabled={disabled}
                        error={error}
                        inputProps={{ 'aria-label': helperText ? helperText : "" }}
                        onChange={(event) => {
                            if (setValueChanged) {
                                setValueChanged(true)
                            }
                            setCountry(countries.find(({ label }) => label === event.target.value) || null);
                        }
                        }
                    >
                        {countries.map((type) => (
                            <MenuItem key={type.value} value={type.label}>
                                {type.label}
                            </MenuItem>
                        ))}
                    </Select>
                )}
        </FormControl>
    );
};

export interface CountryData {
    label: string;
    value: string;
}

export const getCountries = () => {
    const list = [];
    list.push({
        label: "Kein Land gewählt!",
        value: ""
    });
    countryList().getData().forEach((element) => list.push(element))

    return list;
}

export default SelectComponentCountry;
