
import { Button } from "@mui/material";
import { FreeBreakfast, Restore, Save } from "@mui/icons-material";

export interface SubmitProps {
    submitText: string;
    cancelText: string;
    pauseText: string;
    submitLink?: string;
    cancelLink?: string;
    pauseLink?: string;
    isValid?: boolean;
    onClickSubmit?: () => void;
    onClickPause?: () => void;
    onClickCancel?: () => void;
}

function SubmitButtonsHistory({
    submitText,
    cancelText,
    pauseText,
    onClickCancel,
    onClickSubmit,
    onClickPause
}: SubmitProps) {
    return (
        <>
            <Button variant="contained" onClick={onClickSubmit}>
                <Save sx={{ mr: 1 }}></Save>
                {submitText}
            </Button>
            <Button variant="contained" onClick={onClickPause}>
                <FreeBreakfast sx={{ mr: 1 }}></FreeBreakfast>
                {pauseText}
            </Button>
            <Button variant="contained" onClick={onClickCancel}>
                <Restore sx={{ mr: 1 }}></Restore>
                {cancelText}
            </Button>
        </>
    );
}

export default SubmitButtonsHistory;
