import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
} from "@mui/material";
import "./dialog.css";
import { Theme, useTheme } from "@mui/material";
import ErrorDisplay from "../../error-display";
import { useState } from "react";
import SubmitButtonsDialogHistory from "../submit-buttons/submit-button-dialog-history";

export interface DeleteProps {
    showDialog: boolean;
    dialogTitle: string;
    deleteInfo: string | undefined;
    onClickSubmit: (date: string) => void;
    onClickCancel: () => void;
}

const InvalidDialog = ({
    showDialog,
    onClickSubmit,
    dialogTitle,
    deleteInfo,
    onClickCancel,
}: DeleteProps) => {
    const today = new Date();
    const theme: Theme = useTheme();
    const [effectiveDate, setEffectiveDate] = useState<string>(today.getFullYear() + '-' + ((today.getMonth() + 1 < 10) ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1)) + '-' + today.getDate());

    return (
        <Dialog open={showDialog}>
            <DialogTitle
                sx={{ backgroundColor: theme.palette.primary.main, color: "white" }}
            >
                {dialogTitle}
            </DialogTitle>
            <DialogContent sx={{ marginTop: "1rem" }} className="DialogContent">
                <ErrorDisplay>
                    <DialogContentText>
                        <div className="dialog-content-text">
                            {deleteInfo &&
                                deleteInfo !== undefined &&
                                !deleteInfo.includes("undefined")
                                ? deleteInfo
                                : "Entity konnte nicht ungültig gesetzt werden! Klicken Sie SPEICHERN, wenn Sie es erneut versuchen wollen."}
                            <TextField
                                id="effectiveDate"
                                label="Wirksamkeitsdatum"
                                type='date'
                                value={effectiveDate}
                                className="single-view-employee__text-field"
                                variant="outlined"
                                error={effectiveDate === ""}
                                helperText={
                                    effectiveDate === ""
                                        ? "Das Wirksamkeitsdatum darf nicht leer sein!"
                                        : ""
                                }
                                onChange={(event) => {
                                    setEffectiveDate(event.target.value);
                                }}
                            />
                        </div>
                    </DialogContentText>
                </ErrorDisplay>
            </DialogContent>
            <DialogActions>
                <SubmitButtonsDialogHistory
                    submitText="SPEICHERN"
                    cancelText="ABBRECHEN"
                    onClickSubmit={onClickSubmit}
                    onClickCancel={onClickCancel}
                    value={effectiveDate}
                />
            </DialogActions>
        </Dialog>
    );
};

export default InvalidDialog;
