import { resourceAxiosInstance } from "../../config/axios.config";
import { InstitutionAPI, InstitutionAPIRequest } from "../../models/institution";

export const fetchInstitutions = () =>
    resourceAxiosInstance.get<InstitutionAPI[]>(`/institution`, {
        cache: false,
    });

export const fetchInstitutionsWithSystem = () =>
    resourceAxiosInstance.get<InstitutionAPI[]>(`/institution?system=true`, {
        cache: false,
    });

export const fetchInstitutionsWithSystemWithoutPensionInstitutions = () =>
    resourceAxiosInstance.get<InstitutionAPI[]>(`/institution?system=true&pensionInstitutions=false`, {
        cache: false,
    });

export const fetchInsurances = () =>
    resourceAxiosInstance.get<InstitutionAPI[]>(`/institution?aids=false&insurances=true&pensionInstitutions=false`, {
        cache: false,
    });

export const fetchAids = () =>
    resourceAxiosInstance.get<InstitutionAPI[]>(`/institution?aids=true&insurances=false&pensionInstitutions=false`, {
        cache: false,
    });

export const fetchPensionInstitutions = () =>
    resourceAxiosInstance.get<InstitutionAPI[]>(`/institution?aids=false&insurances=false&pensionInstitutions=true`, {
        cache: false,
    });

export const fetchAidsAndInsurances = () =>
    resourceAxiosInstance.get<InstitutionAPI[]>(`/institution?aids=true&insurances=true&pensionInstitutions=false`, {
        cache: false,
    });

export const updateInstitution = (institution: Partial<InstitutionAPIRequest>) =>
    resourceAxiosInstance.patch<InstitutionAPIRequest>(
        `/institution/${institution.id}`,
        institution,
        {
            cache: false,
        }
    );

export const changeActiveOfInstitution = (institutionId: string | undefined) =>
    resourceAxiosInstance.put<InstitutionAPIRequest>(
        `/institution/${institutionId}/active`,
        {
            cache: false,
        }
    );

export const deleteInstitution = (institutionId: string | undefined) =>
    resourceAxiosInstance.delete(`/institution/${institutionId}`, {
        cache: false,
    });

export const fetchInstitution = (institutionId: string) =>
    resourceAxiosInstance.get<InstitutionAPI>(`/institution/${institutionId}`, {
        cache: false,
    });

export const createInstitution = (institution: Partial<InstitutionAPIRequest>) =>
    resourceAxiosInstance.post<InstitutionAPIRequest>(`/institution`, institution, {
        cache: false,
    });
